import React, { useState, useRef, useEffect, useCallback } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useNavigate } from "react-router-dom";
import chevBack from "../../assets/ion_chevron-back.svg";

import Toast, { handleToast } from "../../Components/Toast";
import { storeCompliancePath } from "../../utils/Localstorage";

function CreateDao() {
  const navigate = useNavigate();
  const [DAOPurpose, setDAOPurpose] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [submitHook, setSubmitHook] = useState(false);

  const [pValue1, setPValue1] = useState("");
  const inputRef1 = useRef<HTMLInputElement>(null);

  const [pValue2, setPValue2] = useState("");
  const inputRef2 = useRef<HTMLInputElement>(null);

  const [pValue3, setPValue3] = useState("");
  const inputRef3 = useRef<HTMLInputElement>(null);

  const [pValue4, setPValue4] = useState("");
  const inputRef4 = useRef<HTMLInputElement>(null);

  const [pValue5, setPValue5] = useState("");
  const inputRef5 = useRef<HTMLInputElement>(null);

  const [pValue6, setPValue6] = useState("");
  const inputRef6 = useRef<HTMLInputElement>(null);

  const CheckError = (value: any) => {
    if (value == "" || value == " " || value == null || value == undefined) {
      return false;
    }
    console.log(value);
    return true;
  };

  const submit = () => {
    // navigate("/CreateDao2");
    setSubmitHook(true);
    if (
      CheckError(pValue1) &&
      CheckError(pValue2) &&
      CheckError(pValue3) &&
      CheckError(pValue4) &&
      CheckError(pValue5)
    )
      if (pValue1 == pValue2 || pValue2 == pValue3 || pValue1 == pValue3) {
        handleToast("Preferred Names cannot be the same");
      } else {
        navigate("/CreateDao2", {
          state: {
            data: {
              preferred_name_1: pValue1,
              preferred_name_2: pValue2,
              preferred_name_3: pValue3,
              industry: pValue4,
              jurisdiction: pValue4,
              dao_purpose: pValue5,
            },
          },
        });
      }
  };


  const PageForm = ({
    title,
    dropdown = false,
    list = [],
    value,
    setValue,
    inputRef,
    error = false,
  }: any) => {
    const handleInputChange = (e: any) => {
      setValue(e.target.value); // Don't trim here to prevent losing focus
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    };

    return (
      <div className="flex items-start gap-6 mb-[3%]">
        <h3 className="font-[OpenSauceSans] text-sm font-medium text-white w-[150px]">
          {title}
        </h3>
        {dropdown ? (
          <div className="flex flex-col">
            <div className="relative w-[300px]">
              <select
                name=""
                id=""
                ref={inputRef}
                value={value}
                // onChange={handleInputChange}

                // value={selectedValue}
                onChange={(e) => {
                  // setSelectedValue(e.target.value);
                  handleInputChange(e);
                  {
                    e.target.value !== "Wyoming - USA" &&
                      setDAOPurpose(
                        `Tokenization of a ${e.target.value} fund under a compliant structure for the...`
                      );
                  }
                }}
                // className="w-[300px] p-2 rounded-lg bg-[#222] text-white focus:outline-none pr-[2%]"
                className="w-full p-2 pr-8 rounded-lg bg-[#222] text-white focus:outline-none focus:ring-2 focus:ring-gray-400 appearance-none"
              >
                <option value={""} disabled selected></option>
                {list.map((item: string) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-5 flex items-center pointer-events-none text-[#fff] text-xs">
                ▼
              </div>
            </div>
            {error && (
              <p className="text-[#BA292B] text-sm">{title} can not be empty</p>
            )}
          </div>
        ) : (
          <div className="flex flex-col">
            <input
              ref={inputRef}
              value={value}
              onChange={handleInputChange}
              type="text"
              className="w-[300px] p-2 rounded-lg bg-[#222] text-white outline-none focus:ring-2 focus:ring-gray-400"
            />
            {error && (
              <p className="text-[#BA292B] text-sm">{title} can not be empty</p>
            )}
          </div>
        )}
      </div>
    );
  };


  useEffect(()=>{
    storeCompliancePath("CreateDao")
  },[])
  return (
    <>
      <div className="dash-body">
        <SideNav activeTab="Compliance" />
        <div className="content-body">
          <ProfileNav />
          <Toast />
          <div
            className="c-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={chevBack} alt="" />
            <p>Back</p>
          </div>
          <div className="sec-header" style={{ marginBottom: 3 }}>
            <p style={{ fontWeight: 400 }}>DAO Incorporation Onboarding</p>
          </div>
          <p
            style={{
              fontWeight: 400,
              color: "#999",
              fontSize: 10,
              marginBottom: 20,
            }}
          >
            Company Details
          </p>

          <PageForm
            title="Preferred Name #1"
            value={pValue1}
            setValue={setPValue1}
            inputRef={inputRef1}
            error={submitHook == true && CheckError(pValue1) == false}
          />

          <PageForm
            title="Preferred Name #2"
            value={pValue2}
            setValue={setPValue2}
            inputRef={inputRef2}
            error={submitHook == true && CheckError(pValue2) == false}
          />
          <PageForm
            title="Preferred Name #3"
            value={pValue3}
            setValue={setPValue3}
            inputRef={inputRef3}
            error={submitHook == true && CheckError(pValue3) == false}
          />
          <PageForm
            title="Industry"
            dropdown={true}
            list={[
              "Private Equity",
              "Venture Capital",
              "Real Estate",
              "Entertainment",
              "Infrastructure",
              "Sports",
              "R&D",
              "Startup",
            ]}
            value={pValue4}
            setValue={setPValue4}
            inputRef={inputRef4}
            error={submitHook == true && CheckError(pValue4) == false}
            // error={submitHook == true && CheckError(setPValue4) == false}
          />
          <PageForm
            title="Jurisdiction"
            dropdown={true}
            list={["Wyoming - USA"]}
            value={pValue5}
            setValue={setPValue5}
            inputRef={inputRef5}
            error={submitHook == true && CheckError(pValue5) == false}
          />

          <div className="flex items-start gap-6 mb-[3%]">
            <h3 className="font-[OpenSauceSans] text-sm font-medium text-white w-[150px]">
              DAO Purpose
            </h3>

            <textarea
            onChange={(event) => setDAOPurpose(event.target.value)}
              value={DAOPurpose}
              className="w-[600px] p-2 rounded-lg bg-[#222] text-white focus:outline-none focus:ring-2 focus:ring-gray-400"
            />
          </div>

          <div className="w-full flex justify-end px-[5%]">
            <button
              style={{ transition: "0.5s" }}
              className=" ml-[2%] w-[150px] bg-[#fff] h-[50px] flex items-center justify-center rounded-[20px] mt-5 cursor-pointer hover:bg-[#ddd]"
              onClick={() => {
                submit();
              }}
            >
              Next {">"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateDao;
