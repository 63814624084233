import "../../../css/settings/style.css";
import Input from "../../Input";
import trashIcon from "../../../assets/trash.svg";
import plusIcon from "../../../assets/plus.svg";
import {
  SettingsFormValues,
  SupportingDocument,
} from "../../../Screens/Settings/self-listed/SelfListed";
import { FormikProps } from "formik";

interface Props<T> {
  formik: FormikProps<T>;
}

const SupportingDocuments: React.FC<Props<SettingsFormValues>> = ({
  formik,
}) => {
  const handleAddDocument = () => {
    const newDocument: SupportingDocument = {
      id: Date.now(),
      name: "",
      link: "",
    };
    formik.setFieldValue("supportingDocuments", [
      ...formik.values.supportingDocuments,
      newDocument,
    ]);
  };

  const handleDeleteDocument = (id: number) => {
    const updatedDocuments = formik.values.supportingDocuments.filter(
      (doc) => doc.id !== id
    );
    formik.setFieldValue("supportingDocuments", updatedDocuments);
  };

  const handleInputChange = (
    id: number,
    field: "name" | "link",
    value: string
  ) => {
    const updatedDocuments = formik.values.supportingDocuments.map((doc) =>
      doc.id === id ? { ...doc, [field]: value } : doc
    );
    formik.setFieldValue("supportingDocuments", updatedDocuments);
  };

  return (
    <div className="settings-form-container">
      <div className="title-container">
        <p>
          Supporting documents (business plan, Pitch Deck, Legal Documents,
          etc.)
        </p>
      </div>
      {formik.values.supportingDocuments.map((doc, index) => (
        <div
          key={doc.id}
          style={{
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <Input
            contStyle={{
              marginBottom: 16,
              height: 64,
              backgroundColor: "#0d0d0d",
            }}
            contClass="list-input-cont"
            extraClass="list-input"
            label={`Document Title ${index + 1}`}
            value={doc.name}
            onChange={(e) => handleInputChange(doc.id, "name", e.target.value)}
          />
          <Input
            label="Enter Document Link"
            contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
            contClass="list-input-cont"
            extraClass="list-input"
            value={doc.link}
            onChange={(e) => handleInputChange(doc.id, "link", e.target.value)}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 14,
              marginTop: 26,
              padding: "0px 36px",
            }}
          >
            <img
              style={{ width: 16, height: 16, cursor: "pointer" }}
              src={trashIcon}
              alt="Delete"
              onClick={() => handleDeleteDocument(doc.id)}
            />
            <p
              className="delete-text"
              onClick={() => handleDeleteDocument(doc.id)}
            >
              Delete
            </p>
          </div>
        </div>
      ))}
      <div
        className="add-company-portfolio-container"
        onClick={handleAddDocument}
      >
        <img src={plusIcon} alt="Add Document" />
        <p>Add Document</p>
      </div>
    </div>
  );
};

export default SupportingDocuments;
