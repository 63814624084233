import React, { useState, useRef, useEffect } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useLocation, useNavigate } from "react-router-dom";
import { GetUserDetails } from "../../RequestHandlers/User";
import chevBack from "../../assets/ion_chevron-back.svg";
import {
  getComId,
  storeComID,
  storeCompliancePath,
} from "../../utils/Localstorage";
import { useFileUpload } from "../../context/uploadFile";
import Toast, { handleToast } from "../../Components/Toast";

function DaoContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showDelMenu, setShowDelMenu] = useState(null);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [details, setdetails] = useState<any>({ contactEmail: "" });
  const [DAO, setDAO] = useState<any>({});
  const [AllDAO, setAllDAO] = useState<any>([]);
  const [DAOLoading, setDAOLoading] = useState(true);
      const { uploadFile, isUploading, uploadError } = useFileUpload();
  
  function capitalizeFirstLetter(word: string): string {
    if (!word) return ""; // Handle empty or null strings
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  const getDao = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      id: getComId(),
      //  id:data.id,
    });

    fetch("https://trendx.app/api/getDAObyid.php", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status == "success") {
          setDAO(result.data);
        }
      })
      .catch((error) => console.error(error));
  };

  const getAllDao = (email: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email,
    });

    fetch("https://trendx.app/api/getDAO.php", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setDAOLoading(false);
        if (result.status == "success") {
          setAllDAO(result.data);
        }
      })
      .catch((error) => console.error(error));
  };

  const getUserDetails = async () => {
    try {
      const res = await GetUserDetails();
      if (res.avatar) {
        setdetails(res);
        getAllDao(res.contactEmail);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const DetailRow = ({
    label,
    value,
    isButton = "",
    list = [],
    bt = false,
    loading=false
  }: any) => (
    // <div className="flex justify-between border-b border-gray-700 py-4">
    <div
      className={`flex justify-between border-b border-gray-700 py-4 ${
        bt && "border-t"
      }`}
    >
      <span className="text-[#bbb]">{label}</span>
      {isButton !== "" ? (
        <div className={`flex w-[40vw] ${value !== undefined && "gap-4"} `}>
          <span className="text-white text-sm">{value}</span>
          <button className="bg-white text-black px-9 py-1 " onClick={()=>{
            //@ts-ignore
            inputRef.current?.click()
          }}>
             {loading ? (
                <div className="dot-loader">
                  <span className="dot2"></span>
                  <span className="dot2"></span>
                  <span className="dot2"></span>
                </div>
              ) : (
                isButton
              )}
            
            
            
            </button>
        </div>
      ) : list.length > 0 ? (
        <div>
          {list.map((item: any, index: number) => (
            <div
              key={index}
              className={`flex flex-col w-[40vw] ${
                index !== list.length - 1 ? "mb-5" : ""
              }`}
            >
              <span className="text-[#555] text-xs ">{item.key}</span>
              <span className="text-white text-sm">{item.value}</span>
            </div>
          ))}
        </div>
      ) : (
        <div className={` w-[40vw]`}>
          <span className="text-white text-sm">{value}</span>
        </div>
      )}
    </div>
  );

  useEffect(() => {
    getDao();
    getUserDetails();
    storeCompliancePath("DaoContent");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="dash-body">
        <SideNav activeTab="Compliance" />
        <div className="content-body">
          <ProfileNav />
          <Toast/>
          <div className="section-ph">
            <div className="ph-text-sec">
              <div
                className="c-back"
                onClick={() => {
                  navigate("/DaoDahsboard");
                }}
              >
                <img src={chevBack} alt="" />
                <p>Back</p>
              </div>
            </div>
            <div className="relative">
              <div
                className="lnp-btn"
                onClick={() => {
                  setShowPopup(!showPopup);
                }}
              >
                Manage DAO
              </div>
              {showPopup && (
                <div className="absolute top-14 left-2 -translate-x-1/2 w-[250px] p-4 bg-[#222] text-white rounded-lg shadow-lg">
                  {DAOLoading ? (
                    <div className="dot-loader">
                      <span className="dot2"></span>
                      <span className="dot2"></span>
                      <span className="dot2"></span>
                    </div>
                  ) : AllDAO.length !== 0 ? (
                    <div>
                      {AllDAO.map((items: any) => (
                        <div
                          className="flex gap-1 hover:bg-[#444] w-full h-[30px] items-center cursor-pointer"
                          onClick={() => {
                            navigate("/DaoDahsboard");
                            storeComID(items.id);
                          }}
                        >
                          <p className="text-[#fff] text-xs ml-2">
                            {items.preferred_name_1}
                          </p>
                          <p className="text-[#fff] text-xs font-bold">DAO</p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-gray-400 italic">No DAO found</p>
                  )}
                  <button
                    className="mt-4 w-full bg-lime-400 text-black font-semibold px-4 py-2 rounded-md"
                    onClick={() => navigate("/CreateDao")}
                  >
                    Register a new DAO
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="bg-black text-white min-h-screen p-8">
            <div className="flex items-center mb-8">
              <img
                src={DAO.upload_id}
                alt="logo"
                className="w-10 h-10 mr-2 rounded-[100%] border border-[#BA292B] border-[3px]"
              />
              <h1 className="text-xl font-bold">
                {DAO.preferred_name_1}{" "}
                <span className="text-yellow-500">DAO</span>
              </h1>
            </div>

            <div className="bg-[#111] p-6 rounded-lg mx-auto">
              <h2 className="text-lg font-semibold mb-2">Details</h2>
              <p className="text-[#bbb] mb-6">Personal and company details.</p>

              <DetailRow
                label="Company Name"
                value={DAO.preferred_name_1}
                bt={true}
              />
              <DetailRow label="Company Logo" isButton="Upload" loading={loading} />
              <input type="file" hidden ref={inputRef} onChange={async(e)=>{
              // @ts-ignore
                  const file = e.target.files[0];
                  
                  if (file) {
                    setLoading(true)
                    const data = await uploadFile(file);
                   
                    const myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    console.log(DAO.id)

                    const raw = JSON.stringify({
                      "id": DAO.id,
                      "upload_id": data.secure_url
                    });
                    
                    
                    fetch("https://trendx.app/api/updateUploadId.php", {
                      method: "POST",
                      headers: myHeaders,
                      body: raw,
                      redirect: "follow"
                    })
                      .then((response) => response.json())
                      .then((result) => {
                        if (result.status == "success") {
                          handleToast("Successfully Uploaded a Logo")
                    setLoading(false)
                    
                  }
                  else{
                    handleToast("An error occurred try again later","error")
                    setLoading(false)

                        }
                      })
                      .catch((error) => console.error(error));
                  
                };
              }}/>
              <DetailRow label="State" value="Wyoming" />
              <DetailRow
                label="Smart Contract Address"
                value={DAO.wallet_address}
                isButton={"copy"}
              />
              <DetailRow label="Industry" value={DAO.industry} />

              <DetailRow
                label="Incorporator's Details"
                list={[
                  {
                    key: "Applicant's name",
                    value: DAO.first_name + " " + DAO.last_name,
                  },
                  {
                    key: "Country of Residence",
                    value: "Nigeria",
                  },
                ]}
              />
              <DetailRow
                label="Parent Company"
                list={[
                  {
                    key: "Company name",
                    value: details.name,
                  },
                  {
                    key: "Country of Residence",
                    value: details.location,
                  },
                ]}
              />

              <DetailRow label="Mailing Address" value="---------" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DaoContent;
