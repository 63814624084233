import React, { useEffect } from "react";
import envelop from "../assets/envelop.gif";
import SecBtn from "../Components/SecBtn";
import { useNavigate } from "react-router-dom";
import { storeCompliancePath } from "../utils/Localstorage";

export default function EmailDAOSentScreen() {
  const sendEmail = (email: string, name: string) => {
    const formdata = new FormData();
    formdata.append("email", email);
    formdata.append("name", name);

    fetch("https://trendx.app/api/DAOemail.php", {
      method: "POST",
      body: formdata,
      redirect: "follow",
    })
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        localStorage.removeItem("DAOData");
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem("DAOData");

    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      console.log(userData);
      
      sendEmail(userData.email.trim(), userData.name || userData.email.split("@")[0]);
      storeCompliancePath("Compliance")
    }
  }, []);
  
  const navigate = useNavigate();
  return (
    <section className="emailBody">
      <img src={envelop} alt="" className="envelop" />
      <div className="textWrap">
        <h4>Email sent</h4>
        <p>
          Thank you for contacting our tokenization team. We’ll get back
          <br />
          to you shortly to schedule a consultation call.
        </p>
      </div>
      <div className="btn-Wrap">
        <SecBtn
          text="Back to home"
          onPress={() => {
            navigate("/");
          }}
        />
      </div>
    </section>
  );
}
