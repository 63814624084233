export const cleanObject = (obj: any): any => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => {
        if (value === null || value === undefined || value === "") {
          return false; // Exclude null, undefined, and empty strings
        }
        if (typeof value === "object" && !Array.isArray(value)) {
          // If the value is an object, recursively clean it
          const cleanedValue = cleanObject(value);
          return Object.keys(cleanedValue).length > 0; // Keep if it has non-empty properties
        }
        if (Array.isArray(value)) {
          // Filter arrays to remove empty objects or invalid items
          const filteredArray = value.map(cleanObject).filter((item) => Object.keys(item).length > 0);
          return filteredArray.length > 0; // Keep if the array has valid items
        }
        return true; // Keep other valid values
      })
    );
  };
