import React, { useState, useEffect, useRef } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useLocation, useNavigate } from "react-router-dom";
import chevBack from "../../assets/ion_chevron-back.svg";
import Toast, { handleToast } from "../../Components/Toast";
import { NextArrowIcon, PrevArrowIcon } from "../../Components/iconComponents";
import FadeInAnimation from "../../Components/FadeIn";
import TextArea from "../../Components/Textera";
import Input from "../../Components/Input";
import chevDown from "../../assets/chev-down.svg";

const tabs = ["All", "Success", "Pending", "Failed"];

function Withdraw() {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectN, industry } = location.state || {};
  const [walletAddress, setwalletAddress] = useState("");
  const [amount, setamount] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [currency, setCurrency] = useState("");
  const [networkType, setNetworkType] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeInType, setActiveInType] = useState<any>(null);
  const [activeInType2, setActiveInType2] = useState<any>(null);
  const [showInvestmentType, setShowInvestmentType] = useState(false);
  const [showCurrencyType, setShowCurrencyType] = useState(false);
  const [reason, setReason] = useState(
    "I want to tokenize my business or a project"
  );
  const [errors, setErrors] = useState({
    walletAddress: "",
    amount: "",
    email: "",
    phone: "",
    reason: "",
    activeInType: "",
    activeInType2: "",
  });

  const investmentType = [
    { name: "Short", value: "Short" },
    { name: "Long", value: "Long" },
  ];

  const currencyType = [
    { name: "Naira", value: "Naira" },
    { name: "Dollar", value: "Dollar" },
  ];

  const validateField = (fieldName: string, value: string) => {
    switch (fieldName) {
      case "walletAddress":
        return value.trim() === "" ? "Wallet address is required" : "";
      case "amount":
        return value.trim() === "" ? "Amount is required" : "";
      case "email":
        if (value.trim() === "") {
          return "Email is required";
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return !emailRegex.test(value) ? "Invalid email address" : "";
      case "phone":
        if (value.trim() === "") {
          return "Phone number is required";
        }
        const phoneRegex = /^\d{10,15}$/;
        return !phoneRegex.test(value) ? "Invalid phone number" : "";
      case "reason":
        return value.trim() === "" ? "Reason is required" : "";
      default:
        return "";
    }
  };

  const handleInputChange = (fieldName: string, value: string) => {
    // Update the corresponding state
    switch (fieldName) {
      case "walletAddress":
        setwalletAddress(value);
        break;
      case "amount":
        setamount(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "reason":
        setReason(value);
        break;
    }

    // Validate the field and update errors
    const error = validateField(fieldName, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => error === "") &&
      walletAddress &&
      amount &&
      email &&
      phone &&
      reason
    );
  };

  const handleWithdrawClick = () => {
    if (isFormValid()) {
      console.log({
        walletAddress,
        amount,
        email,
        phone,
        currency,
        networkType,
      });
    } else {
      console.log("Form is not valid");
    }
  };

  return (
    <>
      <Toast />
      <div className="dash-body">
        <SideNav activeTab="Payout" />
        <div className="content-body">
          <ProfileNav />
          <div
            className="c-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={chevBack} alt="" />
            <p>Back</p>
          </div>
          <div className="text-[20px] text-white font-[500] w-full flex justify-center items-center mb-16">
            <p>Withdrawal</p>
          </div>

          {/* ============================ */}
          <FadeInAnimation delay={0.6} duration={1}>
            <div className="max-w-[578px] mx-auto w-full p-5 rounded-[12px] bg-[#181818]">
              <p className="text-lg text-white font-medium">
                Withdrawal details
              </p>
              <div className="inputCont1">
                <div className="">
                  <FadeInAnimation delay={0.9} duration={1}>
                    <Input
                      extraClass="!bg-black"
                      type="text"
                      label="Wallet address"
                      value={walletAddress}
                      onChange={(e) =>
                        handleInputChange("walletAddress", e.target.value)
                      }
                      error={errors.walletAddress ? true : false}
                      errorText={errors.walletAddress}
                    />
                  </FadeInAnimation>
                </div>
              </div>
              <div className="inputCont1">
                <FadeInAnimation delay={1.5} duration={1}>
                  <div className="">
                    <FadeInAnimation delay={1.2} duration={1}>
                      <Input
                        extraClass="!bg-black"
                        type="text"
                        label="Amount"
                        value={amount}
                        onChange={(e) =>
                          handleInputChange("amount", e.target.value)
                        }
                        error={errors.amount ? true : false}
                        errorText={errors.amount}
                      />
                    </FadeInAnimation>
                  </div>
                </FadeInAnimation>
              </div>
              <div className="!bg-black rounded-[16px] !w-full !mt-4 ">
                <div
                  className={`reg-file-cont drop-down !bg-black ${
                    activeInType !== null ? "focused" : ""
                  } ${errors.activeInType ? "img-cont-error" : "!bg-black"}`}
                  onClick={() => {
                    setShowInvestmentType(!showInvestmentType);
                  }}
                >
                  <label className="floating-label">Network type</label>
                  <div className="active-ind">{activeInType?.name}</div>
                  <div className="chev">
                    <img src={chevDown} alt="" />
                  </div>
                </div>
                {errors.activeInType && (
                  <p className="error-text">{errors.activeInType}</p>
                )}
                {showInvestmentType && (
                  <div className="in-drop-down">
                    {investmentType.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={`ind ${
                            item === activeInType ? "ind-active" : ""
                          }`}
                          onClick={() => {
                            setActiveInType(item);
                            setShowInvestmentType(false);
                            setNetworkType(item.value);
                          }}
                        >
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="!bg-black rounded-[16px] !w-full !mt-4 ">
                <div
                  className={`reg-file-cont drop-down !bg-black ${
                    activeInType2 !== null ? "focused" : ""
                  } ${errors.activeInType2 ? "img-cont-error" : "!bg-black"}`}
                  onClick={() => {
                    setShowCurrencyType(!showCurrencyType);
                  }}
                >
                  <label className="floating-label">Currency</label>
                  <div className="active-ind">{activeInType2?.name}</div>
                  <div className="chev">
                    <img src={chevDown} alt="" />
                  </div>
                </div>
                {errors.activeInType2 && (
                  <p className="error-text">{errors.activeInType2}</p>
                )}
                {showCurrencyType && (
                  <div className="in-drop-down">
                    {currencyType.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={`ind ${
                            item === activeInType2 ? "ind-active" : ""
                          }`}
                          onClick={() => {
                            setActiveInType2(item);
                            setShowCurrencyType(false);
                            setCurrency(item.value);
                          }}
                        >
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              <div
                className="flex items-center justify-between"
                style={{ marginTop: 20 }}
              >
                <div className="inputWrap">
                  <FadeInAnimation delay={0.9} duration={1}>
                    <Input
                      extraClass="!bg-black"
                      type="text"
                      label="Email"
                      value={email}
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                      error={errors.email ? true : false}
                      errorText={errors.email}
                    />
                  </FadeInAnimation>
                </div>
                <div className="inputWrap">
                  <FadeInAnimation delay={1.2} duration={1}>
                    <Input
                      extraClass="!bg-black"
                      type="text"
                      label="Phone number"
                      value={phone}
                      onChange={(e) =>
                        handleInputChange("phone", e.target.value)
                      }
                      error={errors.phone ? true : false}
                      errorText={errors.phone}
                    />
                  </FadeInAnimation>
                </div>
              </div>
            </div>

            <button
              className={`max-w-[578px] w-full h-[56px] mx-auto ${isFormValid() ? "bg-white text-black" : "bg-[#1d1d1d] cursor-not-allowed"} rounded-[12px] text-[#4A4A4A] flex justify-center items-center text-lg font-[500] mt-6`}
              onClick={handleWithdrawClick}
            >
              Withdraw
            </button>
          </FadeInAnimation>
        </div>
      </div>
    </>
  );
}

export default Withdraw;
