import "../../../css/settings/style.css";
import Input from "../../Input";
import trashIcon from "../../../assets/trash.svg";
import plusIcon from "../../../assets/plus.svg";
import TextArea from "../../Textera";
import { FormikProps } from "formik";
import {
  SettingsFormValues,
  Partnership,
} from "../../../Screens/Settings/self-listed/SelfListed";

interface Props<T> {
  formik: FormikProps<T>;
}

const Partnerships: React.FC<Props<SettingsFormValues>> = ({ formik }) => {
  const handleAddPartnership = () => {
    const newPartnership: Partnership = {
      id: Date.now(),
      partnerCompany: "",
      duration: 0,
      purpose: "",
    };
    formik.setFieldValue("partnerShip", [
      ...formik.values.partnerShip,
      newPartnership,
    ]);
  };

  const handleDeletePartnership = (id: number) => {
    const updatedPartnerships = formik.values.partnerShip.filter(
      (partnership) => partnership.id !== id
    );
    formik.setFieldValue("partnerShip", updatedPartnerships);
  };

  const handleInputChange = (
    id: number,
    field: keyof Partnership,
    value: string | number
  ) => {
    const updatedPartnerships = formik.values.partnerShip.map((partnership) =>
      partnership.id === id ? { ...partnership, [field]: value } : partnership
    );
    formik.setFieldValue("partnerShip", updatedPartnerships);
  };

  return (
    <div className="settings-form-container">
      <div className="title-container">
        <p>Partnerships</p>
      </div>
      {formik.values.partnerShip.map((partnership, index) => (
        <div
          key={partnership.id}
          style={{
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <div className="settings-form-row">
            <div className="settings-input">
              <Input
                label={`Partner Company ${index + 1}`}
                contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                contClass="list-input-cont"
                extraClass="list-input"
                value={partnership.partnerCompany}
                onChange={(e) =>
                  handleInputChange(
                    partnership.id,
                    "partnerCompany",
                    e.target.value
                  )
                }
              />
            </div>
            <div className="settings-input">
              <Input
                label="Contact Duration (in months)"
                type="number"
                value={partnership.duration.toString()}
                contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                contClass="list-input-cont"
                extraClass="list-input"
                onChange={(e) =>
                  handleInputChange(
                    partnership.id,
                    "duration",
                    parseInt(e.target.value, 10)
                  )
                }
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              marginBottom: "16px",
            }}
          >
            <TextArea
              label="Purpose of Partnership"
              height={72}
              extraClass="textarea textarea1"
              value={partnership.purpose}
              onChange={(e) =>
                handleInputChange(partnership.id, "purpose", e.target.value)
              }
            />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 14,
              marginTop: 26,
              padding: "0px 36px",
            }}
          >
            <img
              style={{
                width: 16,
                height: 16,
                cursor: "pointer",
              }}
              src={trashIcon}
              alt="Delete"
              onClick={() => handleDeletePartnership(partnership.id)}
            />
            <p
              className="delete-text"
              onClick={() => handleDeletePartnership(partnership.id)}
            >
              Delete
            </p>
          </div>
        </div>
      ))}
      <div
        className="add-company-portfolio-container"
        onClick={handleAddPartnership}
      >
        <img src={plusIcon} alt="Add More" />
        <p>Add more</p>
      </div>
    </div>
  );
};

export default Partnerships;
