import React, { useState, useRef, useEffect } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useLocation, useNavigate } from "react-router-dom";
import { GetUserDetails } from "../../RequestHandlers/User";
import chevBack from "../../assets/ion_chevron-back.svg";
import {
  getComId,
  storeComID,
  storeCompliancePath,
} from "../../utils/Localstorage";

function DaoSmartContract() {
  const navigate = useNavigate();
  const [M, setM] = useState(1);
  const location = useLocation();

  const [vName, setVName] = useState("");
  const [description, setDescription] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [details, setdetails] = useState({ contactEmail: "" });
  const [DAO, setDAO] = useState<any>([]);
  const [onContinue, setOnContinue] = useState<boolean | null>(false);

  const [AllDAO, setAllDAO] = useState<any>([]);
  const [DAOLoading, setDAOLoading] = useState(true);

  function capitalizeFirstLetter(word: string): string {
    if (!word) return ""; // Handle empty or null strings
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  const getDao = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      id: getComId(),
      // id: data.id,
    });

    fetch("https://trendx.app/api/getDAObyid.php", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status == "success") {
          setDAO(result.data);
        }
      })
      .catch((error) => console.error(error));
  };

  const getAllDao = (email: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email,
    });

    fetch("https://trendx.app/api/getDAO.php", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setDAOLoading(false);
        if (result.status == "success") {
          setAllDAO(result.data);
        }
      })
      .catch((error) => console.error(error));
  };

  const getUserDetails = async () => {
    try {
      const res = await GetUserDetails();
      if (res.avatar) {
        setdetails(res);
        getAllDao(res.contactEmail);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Governace = () => {
    return (
      <div className=" p-6 rounded-lg mx-auto w-[90%]">
        <h2 className="text-lg font-semibold mb-10 text-[#A7FC00]">
          Governance Structure
        </h2>

        <DetailRow
          label="Token-Based Voting"
          value="1 token = 1 vote"
          bt={true}
        />
        <DetailRow
          label="Proposal Creation Rules"
          value="Tokenization of real-world assets under a compliant structure"
        />
        <DetailRow label="Voting Periods" value="7 days per proposal" />
        <DetailRow
          label="Minimum Quorum"
          value="10% of total token supply must participate"
        />
        <DetailRow
          label="Majority Requirement"
          value="50%+1 of votes needed to pass a proposal"
        />
      </div>
    );
  };

  const Tokenomics = () => {
    return (
      <div className=" p-6 rounded-lg mx-auto w-[90%]">
        <h2 className="text-lg font-semibold mb-10 text-[#A7FC00]">
          Tokenomics
        </h2>

        <DetailRow
          label="Symbol"
          value={
            DAO.preferred_name_1.split("")[0] +
            DAO.preferred_name_1[DAO.preferred_name_1.length - 1] +
            "DAO"
          }
          bt={true}
        />
        <DetailRow
          label="Total Supply"
          value="Dynamic (mint tokens for new members)."
        />
        <DetailRow
          label="Token Standard"
          value={`ERC-20 (fungible voting/governance tokens). <br/> <br/>
          ERC-721 / ERC-1155 (NFTs representing tokenized assets or memberships).`}
        />

        <DetailRow
          label="Voting Periods"
          value={`7 days per proposal <br/> <br/>
10% of total token supply must participate`}
        />
      </div>
    );
  };

  const Treasury = () => {
    return (
      <div className=" p-6 rounded-lg mx-auto w-[90%]">
        <h2 className="text-lg font-semibold mb-10 text-[#A7FC00]">
          Treasury & Fund Management
        </h2>

        <DetailRow label="Symbol" value="BRDAO" bt={true} />
        <DetailRow
          label="Treasury Wallet"
          value="Multi-sig wallet for DAO-controlled funds.<br/> <br/>
100% of profits to be distributed to DAO members"
        />
        <DetailRow
          label="Fundraising Mechanism"
          value={`DAO Token Sales (private rounds and STO).<br/> <br/>
          Reg D Securities Exemption filed.`}
        />
      </div>
    );
  };

  const Modal = ({ m = 1 }) => {
    return (
      <div
        className={`w-full h-full fixed bg-[#00000000] flex items-center justify-end  z-50 
        ${!onContinue && "hidden"}
      `}
        onClick={() => setOnContinue(false)}
      >
        <div
          className="w-[95%] md:w-[77%] h-[85%] bg-[#000000dd] border border-[#333] flex items-center justify-center  flex-col mr-[3%] mt-[5%] relative"
          onClick={(e) => e.stopPropagation()}
        >
          <p
            className="text-white text-xl absolute right-5 top-2 cursor-pointer"
            onClick={() => setOnContinue(false)}
          >
            x
          </p>

          {m == 1 ? <Governace /> : m == 2 ? <Tokenomics /> : <Treasury />}
        </div>
      </div>
    );
  };

  const DetailRow = ({
    label,
    value,
    isButton = "",
    list = [],
    link = "",
    bt = false,
    onClick,
  }: any) => (
    <div
      className={`flex justify-between border-b border-gray-700 py-4 ${
        bt && "border-t"
      }`}
    >
      <span className="text-[#bbb]">{label}</span>
      {isButton !== "" ? (
        <div className={`flex w-[40vw] ${value !== undefined && "gap-4"} `}>
          <span className="text-white text-sm">{value}</span>
          <button className="bg-white text-black px-9 py-1 ">{isButton}</button>
        </div>
      ) : list.length > 0 ? (
        <div>
          {list.map((item: any, index: number) => (
            <div
              key={index}
              className={`flex flex-col w-[40vw] ${
                index !== list.length - 1 ? "mb-5" : ""
              }`}
            >
              <span className="text-[#555] text-xs ">{item.key}</span>
              <span className="text-white text-sm">{item.value}</span>
            </div>
          ))}
        </div>
      ) : link !== "" ? (
        <div className={` w-[40vw]`}>
          <span
            className="text-[#A7FC00] text-sm cursor-pointer"
            onClick={() => {
              onClick();
              setOnContinue(true);
            }}
          >
            {"Explore >"}
          </span>
        </div>
      ) : (
        <div className="w-[40vw]">
          {/* Render value as HTML */}
          <span
            className="text-white text-sm"
            dangerouslySetInnerHTML={{ __html: value }}
          />
        </div>
      )}
    </div>
  );

  useEffect(() => {
    getDao();
    getUserDetails();
    storeCompliancePath("DaoSmartContract");
  }, []);

  return (
    <>
      <div className="dash-body">
        <Modal m={M} />
        <SideNav activeTab="Compliance" />
        <div className="content-body">
          <ProfileNav />
          <div className="section-ph">
            <div className="ph-text-sec">
              <div
                className="c-back"
                onClick={() => {
                  navigate("/DaoDahsboard");
                  // navigate(-1);
                }}
              >
                <img src={chevBack} alt="" />
                <p>Back</p>
              </div>
            </div>
            <div className="relative">
              <div
                className="lnp-btn"
                onClick={() => {
                  setShowPopup(!showPopup);
                }}
              >
                Manage DAO
              </div>
              {showPopup && (
                <div className="absolute top-14 left-2 -translate-x-1/2 w-[250px] p-4 bg-[#222] text-white rounded-lg shadow-lg">
                  {DAOLoading ? (
                    <div className="dot-loader">
                      <span className="dot2"></span>
                      <span className="dot2"></span>
                      <span className="dot2"></span>
                    </div>
                  ) : AllDAO.length !== 0 ? (
                    <div>
                      {AllDAO.map((items: any) => (
                        <div
                          className="flex gap-1 hover:bg-[#444] w-full h-[30px] items-center cursor-pointer"
                          onClick={() => {
                            navigate("/DaoDahsboard");
                            storeComID(items.id);
                          }}
                        >
                          <p className="text-[#fff] text-xs ml-2">
                            {items.preferred_name_1}
                          </p>
                          <p className="text-[#fff] text-xs font-bold">DAO</p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-gray-400 italic">No DAO found</p>
                  )}
                  <button
                    className="mt-4 w-full bg-lime-400 text-black font-semibold px-4 py-2 rounded-md"
                    onClick={() => navigate("/CreateDao")}
                  >
                    Register a new DAO
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="bg-black text-white min-h-screen p-8">
            <div className="flex items-center mb-8">
              <img
                src={DAO.upload_id}
                alt="logo"
                className="w-10 h-10 mr-2 rounded-[100%] border border-[#BA292B] border-[3px]"
              />
              <h1 className="text-xl font-bold">
                {DAO.preferred_name_1}{" "}
                <span className="text-yellow-500">DAO</span>
              </h1>
            </div>

            <div className="bg-[#111] p-6 rounded-lg mx-auto">
              <h2 className="text-lg font-semibold mb-2">Smart Contract</h2>
              <p className="text-[#bbb] mb-6">Personal and company details.</p>

              <DetailRow
                label="Legal Entity Identifier"
                value={DAO.preferred_name_1}
                bt={true}
              />
              <DetailRow
                label="Purpose"
                value="Tokenization of real-world assets under a compliant structure"
              />
              <DetailRow label="State" value="Wyoming" />
              <DetailRow
                label="Founding Members Wallet Address"
                value={DAO.wallet_address}
                isButton={"copy"}
              />
              <DetailRow label="Registered Agent" value="First Base" />

              <DetailRow
                label="Governance Structure"
                link="Explore >"
                onClick={() => setM(1)}
              />
              <DetailRow
                label="Tokenomics"
                link="Explore >"
                onClick={() => setM(2)}
              />
              <DetailRow
                label="Treasury & Fund Management"
                link="Explore >"
                onClick={() => setM(3)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DaoSmartContract;
