import React, { useState, useEffect } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useNavigate } from "react-router-dom";
import chevronB from "../../assets/ion_chevron-back.svg";
import FadeInTop from "../../Components/FadeInTop";
import { ReactComponent as Filter } from "../../assets/filter.svg";
import search from "../../assets/search.svg";
import verse from "../../assets/verse.png";
import vImg from "../../assets/vImg.png";
import emptyList from "../../assets/empty-list.svg";
import pMenu from "../../assets/pMenu.svg";
import DeleteVoucher from "../../Components/DeleteVoucher";
import VoucherDetail from "../../Components/VoucherDetail";
import RepIssues from "../../Components/RepIssues";
import { GetVouchers } from "../../RequestHandlers/Voucher";
import Pagination from "../../Components/Pagination";
import useDebounce from "../../Components/DebounceSearch";
import UnpublishVoucher from "./UnPublishVoucher";
function PublishedVoucher() {
  const navigate = useNavigate();
  const [searchword, setSearchword] = useState("");
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [sExpanded, setSExpanded] = useState(false);
  const [showDelMenu, setShowDelMenu] = useState(null);
  const [showVoucherDetail, setShowVoucherDetails] = useState(false);
  const [showDelpromt, setShowDelPromt] = useState(false);
  const [cload, setCLoad] = useState(false);
  const [showReportIssue, setShowReportIssue] = useState(false);
  const [pHload, setPHLoad] = useState(false);
  const hLoadArr = [1, 2, 3, 4, 5, 6];
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [activeId, setActiveId] = useState("");
  const [code, setCode] = useState("");
  const [meta, setMeta] = useState<any>([]);
  const debouncedWord = useDebounce(searchword, 500);
  const [showUnPubPromt, setShowUnPubPromt] = useState(false);

  const [data, setData] = useState<any>([]);
  const toggleShowVhoucherDetails = (index: number) => {
    // @ts-ignore
    setShowVoucherDetails(true);
  };
  const toggleMenu = (index: number) => {
    // @ts-ignore
    setShowDelMenu(showDelMenu === index ? null : index);
  };
  useEffect(() => {
    const expandTimeout = setTimeout(() => {
      setSExpanded(true);
    }, 500);

    // Return a cleanup function
    return () => clearTimeout(expandTimeout);
  }, []);

  const getVouchers = async () => {
    setPHLoad(true);
    try {
      const res = await GetVouchers(page, limit, "published", debouncedWord);
      setData(res.items);
      setMeta(res.meta);
      console.log(res);
      // setCashBacks(res.items);
    } catch (error) {
      console.log(error);
    } finally {
      setPHLoad(false);
    }
  };

  useEffect(() => {
    getVouchers();
  }, []);

  useEffect(() => {
    getVouchers();
  }, [page, debouncedWord]);

  const handleNext = () => {
    if (page < meta.totalPages) setPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    if (page > 1) setPage((prev) => prev - 1);
  };

  const handlePageClick = (pageNumber: number) => {
    if (pageNumber !== page) setPage(pageNumber);
  };

  return (
    <>
      {showVoucherDetail && (
        <div className="voucher-detail">
          {showDelpromt ? (
            <DeleteVoucher
              id={activeId}
              code={code}
              onDelete={() => {
                navigate("/VoucherDeleted");
                setShowVoucherDetails(false);
                setShowDelPromt(false);
              }}
              onClose={() => {
                setShowVoucherDetails(false);
                setShowDelPromt(false);
              }}
              cancel={() => {
                setShowVoucherDetails(false);
                setShowDelPromt(false);
              }}
            />
          ) : showReportIssue ? (
            <RepIssues
              onClose={() => {
                setShowVoucherDetails(false);
                setShowReportIssue(false);
              }}
            />
          ) : showUnPubPromt ? (
            <UnpublishVoucher
              id={activeId}
              code={code}
              onDelete={() => {
                navigate("/VoucherDeleted");
                setShowVoucherDetails(false);
                setShowDelPromt(false);
              }}
              onClose={() => {
                setShowVoucherDetails(false);
                setShowDelPromt(false);
              }}
              cancel={() => {
                setShowVoucherDetails(false);
                setShowDelPromt(false);
              }}
            />
          ) : (
            <VoucherDetail
              cload={cload}
              id={activeId}
              onClose={() => {
                setShowVoucherDetails(false);
              }}
              onDelete={() => {
                setShowDelPromt(true);
              }}
              showReportIssue={() => setShowReportIssue(true)}
            />
          )}
        </div>
      )}
      <div className="dash-body">
        <SideNav activeTab="Vouchers" />
        <div className="content-body">
          <ProfileNav />
          <div className="section-ph">
            <div style={{ width: "100%" }}>
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="back"
                style={{
                  all: "unset",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  cursor: "pointer",
                }}
              >
                <img src={chevronB} alt="" />
                Back
              </button>
              <p className="ovo ovh">Published vouchers</p>
            </div>
          </div>
          {data.length > 0 && (
            <div className={`mega-search ${sExpanded ? "sExpanded" : ""}`}>
              <FadeInTop delay={1} duration={1} className="search-nim">
                <div className="s-wrap">
                  <img src={search} alt="" />
                  <input
                    type="text"
                    value={searchword}
                    onChange={(e) => {
                      setSearchword(e.target.value);
                    }}
                    placeholder="Search voucher by name"
                  />
                </div>
              </FadeInTop>
              <div className="actions-wrap">
                <FadeInTop delay={1.5} duration={1}>
                  <div
                    className={`filter ${
                      isFilterActive ? "action-active" : ""
                    }`}
                    style={{ margin: 0 }}
                  >
                    <Filter style={{ stroke: "currentColor" }} />
                    Filter
                  </div>
                </FadeInTop>
              </div>
            </div>
          )}
          <div className="v-holder new-hold-height">
            {pHload ? (
              <>
                <div className="v-cards-wrap">
                  {hLoadArr.map(() => (
                    <div
                      className="voucher-card-load"
                      style={{ marginBottom: 16 }}
                    ></div>
                  ))}
                </div>
              </>
            ) : (
              <>
                {data.length === 0 ? (
                  <>
                    <div className="empty-wrap empty-wrap-height">
                      <img src={emptyList} alt="" />
                      <p>No voucher available</p>
                      <span>List of vouchers will appear here</span>
                    </div>
                  </>
                ) : (
                  <div
                    className="v-cards-wrap v-cards-wrap2"
                    style={{ marginTop: 0 }}
                  >
                    {data.map((item: any, index: any) => {
                      return (
                        <div className="voucher-card v-card1">
                          <div className="cb-wrap1">
                            <img src={item.image} alt="" />
                            <div className="cb-wrap1-b">
                              <h4>{item.addedValue}% added value</h4>
                              <div className="cb-wrap1-alt">
                                <p className="exp1 pbl">Published</p>
                                <h4>{item.code}</h4>
                              </div>
                            </div>
                          </div>
                          <div className="cb-item">
                            <h4>Voucher price:</h4>
                            <h4>${item?.value?.toLocaleString()}/Unit</h4>
                          </div>
                          <div className="cb-item">
                            <h4>Niche:</h4>
                            <h4>{item?.industry}</h4>
                          </div>
                          <div className="cb-item">
                            <h4>Listed units:</h4>
                            <h4>{item.availableUnits?.toLocaleString()}</h4>
                          </div>
                          <div className="cb-item">
                            <h4>Remaining units:</h4>
                            <h4>
                              {(
                                item?.availableUnits - item?.unitsSold
                              )?.toLocaleString()}
                            </h4>
                          </div>
                          <div className="vd-wrap">
                            <button
                              className="vr-btn vr-btn2"
                              onClick={() => {
                                toggleShowVhoucherDetails(index);
                                setActiveId(item.id);
                                setCode(item.code);
                              }}
                            >
                              View details
                            </button>
                            <div>
                              <button
                                style={{ all: "unset", cursor: "pointer" }}
                                onClick={() => {
                                  toggleMenu(index);
                                }}
                              >
                                <img src={pMenu} alt="" />
                              </button>
                              {showDelMenu === index && (
                                <div className="delMenu">
                                  <button
                                    onClick={() => {
                                      setShowDelMenu(null);
                                      setShowVoucherDetails(true);
                                      setShowUnPubPromt(true);
                                      setActiveId(item.id);
                                      setCode(item.code);
                                    }}
                                  >
                                    Unpublish
                                  </button>
                                  <button
                                    onClick={() => {
                                      setShowDelMenu(null);
                                      setShowVoucherDetails(true);
                                      setShowDelPromt(true);
                                      setActiveId(item.id);
                                      setCode(item.code);
                                    }}
                                  >
                                    Delete
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
            {meta.totalPages > 1 && (
              <Pagination
                style={{ marginTop: 16 }}
                meta={meta}
                page={page}
                handleNext={handleNext}
                handlePageClick={handlePageClick}
                handlePrevious={handlePrevious}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PublishedVoucher;
