import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import Input from "../../../Components/Input";
import TextArea from "../../../Components/Textera";
import SecBtn from "../../../Components/SecBtn";
import { useState } from "react";
import { SendBatchEmails } from "../../../RequestHandlers/BatchEmails";
import Toast, { handleToast } from "../../../Components/Toast";
import { useNavigate } from "react-router-dom";

const BulkEmails = () => {
  // Validation schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name must be at least 2 characters long"),
    message: Yup.string()
      .required("Message is required")
      .min(10, "Message must be at least 10 characters long"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
  });

  // Initial form values
  const initialValues = {
    name: "",
    message: "",
    email: "",
  };

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const sendBatchEmails = async (values: any) => {
    try {
      setLoading(true);
      const data = {
        name: values?.name,
        message: values?.message,
        emails: [values?.email],
      };
      const response: any = await SendBatchEmails(data);
      console.log("Batch email response", response);
      if (response) {
        navigate("/BulkEmailSuccess");
      }
    } catch (error: any) {
      console.log("Error occured while sending bulk emails", error?.message);
      handleToast(error?.message?.[0] || "Network error", "error");
    } finally {
      setLoading(false);
    }
  };
  // Form submission handler
  const handleSubmit = (values: any) => {
    sendBatchEmails(values);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <Form>
            <div
              style={{
                width: "100%",
              }}
            >
              <div
                className="settings-form-container"
                style={{
                  marginBottom: 24,
                }}
              >
                <div className="title-container">
                  <p>Send bulk email</p>
                </div>
                <div
                  style={{
                    width: "100%",
                    marginBottom: 16,
                  }}
                >
                  <Input
                    label="Enter your name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    error={touched.name && errors.name}
                    errorText={errors.name}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginBottom: 16,
                  }}
                >
                  <TextArea
                    height={127}
                    label="Message"
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    error={touched.message && errors.message}
                    errorText={errors.message}
                  />
                </div>
              </div>

              <div className="settings-form-container">
                <div className="title-container">
                  <p>Select emails</p>
                </div>
                <div
                  style={{
                    width: "100%",
                    marginBottom: 16,
                  }}
                >
                  <Input
                    label="Type email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    error={touched.email && errors.email}
                    errorText={errors.email}
                  />
                </div>
              </div>

              <div className="action-btn-container">
                <div
                  className="save-view"
                  style={{
                    width: "100%",
                  }}
                >
                  <SecBtn
                    // disabled={!isValid}
                    type="submit"
                    style={{
                      color: "#030500",
                      width: "115px",
                      padding: "8px 0px",
                      borderRadius: "12px",
                      fontSize: "14px",
                      fontWeight: "500",
                      backgroundColor: "#A7FC00",
                      height: 42,
                    }}
                    loading={loading}
                    text={"Save"}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Toast />
    </>
  );
};

export default BulkEmails;
