import React, { useEffect, useState } from "react";
import check from "../../assets/check.png";
import { ChevronDown } from "lucide-react";
import HeaderBar from "../../Components/HeaderBar";
import { useNavigate } from "react-router-dom";
import { CreateUser } from "../../RequestHandlers/Auth";
import Toast, { handleToast } from "../../Components/Toast";

const currencies = [
  { code: "USD", name: "United States Dollar" },
  { code: "EUR", name: "Euro" },
  { code: "GBP", name: "British Pound" },
  { code: "NGN", name: "Nigerian Naira" },
];

export default function OnboardingJurisdiction() {
  const [category, setCategory] = useState<string | null>("");
  const [contry, setContry] = useState<string | null>("");
  const [onContinue, setOnContinue] = useState<boolean | null>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(currencies[0]);
  const navigate = useNavigate();

  const getSelectedInfo = () => {
    const url = window.location.href;
    const dataCollected = url.split("?")[1];
    const contry = dataCollected.split("&")[0];
    const category = dataCollected.split("&")[1];

    setContry(contry);
    setCategory(category);
  };

  useEffect(() => {
    getSelectedInfo();
  }, []);

 

  const sendTkRequest = async () => {
    try {
      const storedUserData = localStorage.getItem("userData");

      if (storedUserData) {
        const userData = JSON.parse(storedUserData);

        userData.companyIndustry =
          category == "film"
            ? "39771721-de8c-11ef-9452-e66440139fe4"
            : category == "football"
            ? "3efa3689-cc6b-4cf7-83ce-5c06901130f3"
            : category == "infrastructure"
            ? "2921b752-8e50-43ce-ae38-63ad3c84967a"
            : "b2a3aad7-dd5a-11ef-9452-e66440139fe4"
            
        console.log(userData); // Access user data here
        const res = await CreateUser(userData);
        console.log(res);
        if (res.owner.id) {
          
          (window.location.href =
                  `https://trendx.app/stripe/index.php?email=${userData.email.trim()}`)
        //   navigate("/emailsent");
        } else {
          handleToast(res.message, "error");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="min-h-screen bg-[#020210] text-white">
      <Toast />

      <div
        className={`w-full h-full fixed bg-[#020210dd] flex items-center justify-center z-50 ${
          !onContinue && "hidden"
        }`}
      >
        <div className="w-[95%] md:w-[40%] h-[60%] bg-[#0C121A] border border-[#14141C] flex items-center justify-center flex-col gap-5">
          <h4 className="text-2xl font-semibold text-center">
            Select the currency you <br /> would like to pay
          </h4>
          <p className="text-gray-500  text-center text-xs">
            we need this information to setup your payment
          </p>

          <div className="space-y-2 w-[80%]">
            <label className="text-lg text-gray-300">Payment currency</label>
            <div className="relative">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="w-full bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-lg p-4 flex items-center justify-between text-left hover:border-gray-600 transition-colors"
              >
                <span>{`${selectedCurrency.code} (${selectedCurrency.name})`}</span>
                <ChevronDown
                  className={`w-5 h-5 transition-transform ${
                    isOpen ? "rotate-180" : ""
                  }`}
                />
              </button>

              {isOpen && (
                <div className="absolute w-full mt-2 bg-gray-800 border border-gray-700 rounded-lg shadow-xl z-10">
                  {currencies.map((currency) => (
                    <button
                      key={currency.code}
                      onClick={() => {
                        setSelectedCurrency(currency);
                        setIsOpen(false);
                      }}
                      className="w-full p-4 text-left hover:bg-gray-700/50 transition-colors first:rounded-t-lg last:rounded-b-lg"
                    >
                      {`${currency.code} (${currency.name})`}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div
            className={`w-[80%] bg-[#ff0000] rounded flex justify-center items-center mt-6 md:mt-8  bg-gradient-to-r from-sky-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% py-[1px] px-[1px] `}
          >
            <button
              className={`w-full py-3 px-4 rounded font-medium transition-all bg-[#1D1D26] hover:bg-[#00E5BE]/90 text-white `}
              disabled={false}
              //   onClick={() => setOnContinue(false)}
              onClick={() =>
                
                sendTkRequest()
              }
            >
              Proceed to payment
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row min-h-screen">
        {/* Left side */}
        <div className="w-full lg:w-1/2 p-6 md:p-12 lg:p-36">
          <HeaderBar
            onclick={() => {
              navigate(-1);
            }}
          />
          <h1 className="text-2xl md:text-3xl font-regular mb-2">
            Get Started
          </h1>
          <p className="text-gray-500 mb-12 md:mb-16">
            Answer a few questions to help us get started.
          </p>

          <div className="relative">
            {/* Vertical line connecting the numbers */}
            <div className="absolute left-4 top-10 bottom-10 w-[1px] bg-gray-800"></div>

            <div className="space-y-12">
              <div className="flex items-start relative z-10">
                <div className="w-[2.1rem] h-[2.1rem] rounded-full flex items-center justify-center text-sm shrink-0 bg-gradient-to-r from-sky-500 from-10% via-sky-500 via-30% to-emerald-500 to-90%">
                  <div className="w-[2rem] h-[2rem] rounded-full bg-[#020210] flex items-center justify-center text-sm shrink-0">
                    1
                  </div>
                </div>
                <div className="ml-6">
                  <h2 className="text-xl font-semibold">Category</h2>
                  <p className="text-gray-500 text-xs">
                    Choose the type of entity that you intend to <br />
                    tokenise.
                  </p>
                </div>
              </div>

              <div className="flex items-start relative z-10">
                {/* <div className="w-8 h-8 rounded-full border border-gray-600 flex items-center justify-center text-sm shrink-0">
                  2
                </div> */}
                <div className="w-[2.1rem] h-[2.1rem] rounded-full flex items-center justify-center text-sm shrink-0 bg-gradient-to-r from-sky-500 from-10% via-sky-500 via-30% to-emerald-500 to-90%">
                  <div className="w-[2rem] h-[2rem] rounded-full bg-[#020210] flex items-center justify-center text-sm shrink-0">
                    2
                  </div>
                </div>
                <div className="ml-6">
                  <h2 className="text-lg font-medium">Jurisdiction</h2>
                </div>
              </div>

              <div className="flex items-start  relative z-10">
                {/* <div className="w-8 h-8 rounded-full border border-gray-600 flex items-center justify-center text-sm shrink-0">
                  3
                </div> */}
                <div className="w-[2.1rem] h-[2.1rem] rounded-full flex items-center justify-center text-sm shrink-0 bg-gradient-to-r from-sky-500 from-10% via-sky-500 via-30% to-emerald-500 to-90%">
                  <div className="w-[2rem] h-[2rem] rounded-full bg-[#020210] flex items-center justify-center text-sm shrink-0">
                    3
                  </div>
                </div>
                <div className="ml-6">
                  <h2 className="text-lg font-medium">Review and pay</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right side */}
        <div className="w-full lg:w-1/2 p-6 md:p-12 lg:p-36 lg:pl-0 ">
          <div className="bg-[#13131A] p-6 md:p-8 lg:p-12 ">
            <h2 className="text-2xl md:text-3xl font-medium mb-2 text-center">
              Review and pay
            </h2>
            <p className="text-gray-500 mb-6 md:mb-8 text-center">
              Please review your information and proceed to payment
            </p>

            <div className="space-y-3 flex items-center justify-center flex-col">
              <button className="p-4 py-3 rounded text-left transition-all w-[70%] bg-[#1D1D26] hover:bg-[#1D1D26]/80 border border-[#666]"  onClick={() => {
              navigate(-1);
            }}>
                <div className="flex items-center justify-between">
                  <div className="ml-3 flex items-center gap-x-4">
                    <div>
                      <p className="text-sm text-gray-500">category</p>
                      <h3 className="font-medium">
                        {category
                          ?.toLowerCase()
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </h3>
                    </div>
                  </div>
                  <img src={check} alt="" className="h-[50px]" />
                </div>
              </button>

              <div
                className="h-[1px] bg-[#333] w-[65%]"
                style={{ marginTop: 20, marginBottom: 10 }}
              ></div>

              <button className="p-4 py-3 rounded text-left transition-all w-[70%] bg-[#1D1D26] hover:bg-[#1D1D26]/80 border border-[#666]"  onClick={() => {
              navigate(-1);
            }}>
                <div className="flex items-center justify-between">
                  <div className="ml-3 flex items-center gap-x-4">
                    <div>
                      <p className="text-sm text-gray-500">jurisdiction</p>
                      <h3 className="font-medium">
                        {contry == "ng" ? "Nigeria" : "United States"}
                      </h3>
                    </div>
                  </div>
                  <img src={check} alt="" className="h-[50px]" />
                </div>
              </button>
            </div>

            <div
              className={`w-full bg-[#ff0000] rounded flex justify-center items-center mt-6 md:mt-8  bg-gradient-to-r from-sky-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% py-[1px] px-[1px] `}
            >
              <button
                className={`w-full py-3 px-4 rounded font-medium transition-all bg-[#1D1D26] hover:bg-[#00E5BE]/90 text-white `}
                disabled={false}
                onClick={() => setOnContinue(true)}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
