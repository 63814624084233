import React, { useState, useRef, useEffect } from "react";
import SideNav from "../../../Components/SideNav";
import ProfileNav from "../../../Components/ProfileNav";
import chevBack from "../../../assets/ion_chevron-back.svg";
import { useNavigate } from "react-router-dom";
import ProgressRing from "../../../Components/ProgressRing";
import Input from "../../../Components/Input";
import uploadImg from "../../../assets/upload.svg";
import chevDown from "../../../assets/chev-down.svg";
import { GetIndustries } from "../../../RequestHandlers/Auth";
import colon from "../../../assets/colon.svg";
import NumberMonthDropdown from "../../../Components/NumberMonthDropdown";
import plus from "../../../assets/plus.svg";
import { useLocation } from "react-router-dom";
import { useFileUpload } from "../../../context/uploadFile";
import roundedCheck from "../../../assets/rc.svg";
import uploadedImg from "../../../assets/uploadedImg.svg";
import gallary from "../../../assets/gallery.svg";
import trash from "../../../assets/trash.svg";
import ActivityIndicator from "../../../Components/ActivityIndicator";
import Cookies from "js-cookie";
import Toast, { handleToast } from "../../../Components/Toast";

interface Row {
  months: string;
  roi: string;
}
interface fwork {
  title: string;
  document: string;
  link: string;
}

function ListProject() {
  const navigate = useNavigate();
  const [tName, setTName] = useState("");
  const [uploading, setUpLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [uploadedFile, setUpLoadedFile] = useState<any>(null);
  const fileInputRef = useRef(null);
  const [aboutTalent, setAboutTalent] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [activeIndustry, setActiveIndustry] = useState<any>(null);
  const [dropActive, setDropActive] = useState(false);
  const [industries, setIndustries] = useState<any>(null);
  const [aUnit, setAUnit] = useState("");
  const [unit, setUnit] = useState("");
  const [intrest, setIntrest] = useState("");
  const [showFd, setShowFD] = useState(false);
  const [activeFd, setActiveFd] = useState<any>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const [fg, setFg] = useState("");
  const [usef, setUseF] = useState("");
  const { uploadFile, isUploading, uploadError } = useFileUpload();
  const [errors, setErrors] = useState<any>({});
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>(null);
  const [activeFr, setActiveFr] = useState<any>(null);
  const [fr, setFr] = useState<any>([]);
  const [hangedFile, setHangedFile] = useState<any>(null);
  const investmentType = [
    { name: "Equity", value: "EquityTokens" },
    { name: "Debt", value: "SecurityTokens" },
  ];
  const period = [
    { name: "Monthly", value: "Monthly" },
    { name: "Quarterly", value: "Quarterly" },
    { name: "Semi-Annual", value: "Semi-Annual" },
  ];
  const [showInvestmentType, setShowInvestmentType] = useState(false);
  const [showInvestmentType1, setShowInvestmentType1] = useState(false);
  const [activeInType, setActiveInType] = useState<any>(null);
  const [activeInType1, setActiveInType1] = useState<any>(null);
  const [rows, setRows] = useState<Row[]>([
    { months: "", roi: "" },
    { months: "", roi: "" },
    { months: "", roi: "" },
    { months: "", roi: "" },
  ]);
  const numberofmonths = [
    { name: "3 Month", value: 3 },
    { name: "6 Months", value: 6 },
    { name: "9 Months", value: 9 },
    { name: "12 Months", value: 12 },
    { name: "15 Months", value: 15 },
  ];

  const saveToDraft = (credentials: any) => {
    try {
      const existingDataString = localStorage.getItem("prCook");
      const existingData = existingDataString
        ? JSON.parse(existingDataString)
        : {};
      const updatedData = {
        ...existingData,
        ...credentials,
      };
      localStorage.setItem("prCook", JSON.stringify(updatedData));
      handleToast("Saved to draft", "success");
      console.log(updatedData);
      console.log(credentials);
    } catch (err) {
      console.error("Error storing credentials:", err);
      handleToast("Error saving to draft", "error");
    }
  };

  const getDraftDetails = () => {
    try {
      const savedData = Cookies.get("prCook");
      if (savedData) {
        const credentials = JSON.parse(savedData);
        console.log(credentials);
        if (credentials) {
          setTName(credentials.name);
          setAboutTalent(credentials.aboutTalent);
          setSelectedFile(credentials.img);
          setUploadedFileUrl(credentials.img.secure_url);
        }
        return credentials; // Return the parsed credentials
      } else {
        console.log("No draft found.");
        return null;
      }
    } catch (err) {
      console.error("Error retrieving draft details:", err);
      return null;
    }
  };

  const handleInputChange = (
    index: number,
    field: keyof Row,
    value: string
  ) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };
  const handleDropdownChange = (
    index: number,
    selectedOption: { name: string; value: any }
  ) => {
    const updatedRows = [...rows];
    updatedRows[index].months = selectedOption.value;
    setRows(updatedRows);
  };
  const handleAddRow = () => {
    setRows([...rows, { months: "", roi: "" }]);
  };
  const handleRemoveRow = () => {
    if (rows.length > 4) {
      setRows((prevRows) => prevRows.slice(0, -1)); // Remove the last row immutably
    } else {
      alert("At least 4 periods are required.");
    }
  };
  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto"; // Reset height to calculate new scroll height
    const scrollHeight = textarea.scrollHeight;
    textarea.style.height = `${Math.min(scrollHeight, 130)}px`;
  };
  const formatWithComma = (value: string | number): string => {
    if (!value) return "";
    return new Intl.NumberFormat("en-US").format(Number(value));
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const file = e.target.files[0];
        console.log(file);

        const data = await uploadFile(file);
        setUploadedFileUrl(data.secure_url);
        console.log("Uploaded file:", data);
        if (data.secure_url) {
          setSelectedFile(data);
          handleFieldChange("coverImage", e.target.value);
        } else {
          setSelectedFile(null);
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };

  const tryFileUploadAgain = async () => {
    try {
      const data = await uploadFile(hangedFile);
      setUploadedFileUrl(data.secure_url);
      if (data.secure_url) {
        setSelectedFile(data);
      } else {
        setSelectedFile(null);
      }
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };
  const handleUploadClick = () => {
    // @ts-ignore
    fileInputRef.current.click();
  };

  const getIndustries = async () => {
    try {
      const res = await GetIndustries();
      setIndustries(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getIndustries();
    window.scrollTo(0, 0);
  }, []);
  const handleFieldChange = (field: string, value: string) => {
    setErrors((prevErrors: any) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[field]) {
        delete updatedErrors[field];
      }
      return updatedErrors;
    });
  };
  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form submission attempted");
    const newErrors: Record<string, string> = {};
    // Global field validations
    if (tName.trim() === "") {
      newErrors.tName = "Field is required";
    }
    if (uploadedFileUrl === null) {
      newErrors.coverImage = "Field is required";
    }
    if (aboutTalent.trim() === "") {
      newErrors.aboutTalent = "Field is required";
    }
    if (activeIndustry === null) {
      newErrors.activeIndustry = "Field is required";
    }
    if (activeFr === null) {
      newErrors.activeFr = "Field is required";
    }
    if (activeInType === null) {
      newErrors.activeInType = "Field is required";
    }
    if (aUnit.trim() === "") {
      newErrors.aUnit = "Field is required";
    } else if (isNaN(Number(aUnit))) {
      newErrors.aUnit = "Units must be a number";
    }
    if (fg.trim() === "") {
      newErrors.fg = "Field is required";
    }
    if (unit.trim() === "") {
      newErrors.unit = "Field is required";
    } else if (isNaN(Number(unit))) {
      newErrors.unit = "Unit price must be a number";
    }
    if (usef.trim() === "") {
      newErrors.usef = "Field is required";
    }
    // Row-specific validations
    // rows.slice(0, 4).forEach((item, index) => {
    //   if (item.months === "") {
    //     newErrors[`m${index}`] = "Field is required";
    //   }
    //   if (item.roi === "") {
    //     newErrors[`roi${index}`] = "Field is required";
    //   }
    // });

    // If errors exist, set them and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // If no errors, proceed with form submission
    console.log("Form submitted successfully:", {
      tName,
      uploadedFileUrl,
      aboutTalent,
      activeIndustry,
      activeFr,
      aUnit,
      fg,
      unit,
      usef,
      rows,
    });

    // Clear errors and navigate

    navigate("/ListProject2", {
      state: {
        data: {
          name: tName,
          img: uploadedFileUrl,
          aboutTalent: aboutTalent,
          activeIndustry: activeIndustry,
          fundingRound: activeFr,
          aUnit: aUnit,
          fg: fg,
          unit: unit,
          use: usef,
          rows: rows,
          investmentType: activeInType,
          intrest:intrest
        },
      },
    });
  };

  useEffect(() => {
    fetch("https://usestableblocks.com/funding_round/")
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setFr(data);
      })
      .catch((error) => console.error("Error:", error));
  }, []);
  const getFilteredOptions = (index: number) => {
    const selectedValues = rows
      .map((row, rowIndex) => (rowIndex !== index ? row.months : null)) // Exclude the current row's selected value
      .filter((value) => value !== null); // Filter out nulls
    // console.log(selectedValues);

    return numberofmonths.filter(
      // @ts-ignore
      (month) => !selectedValues.includes(month.value)
    );
  };

  return (
    <div className="dash-body">
      <SideNav activeTab="Projects" />
      <div className="content-body">
        <ProfileNav />
        <div
          className="c-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={chevBack} alt="" />
          <p>Back</p>
        </div>
        <div className="sec-header">
          <p>List a Project</p>
          <ProgressRing
            radius={20}
            stroke={4}
            progress={25}
            text={"1/4"}
            backgroundColor="#111900"
          />
        </div>
        <div className="list-content-holder">
          <div className="content-cont">
            <Input
              contClass="list-input-cont"
              extraClass="list-input"
              label="Project Name"
              contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
              value={tName}
              onChange={(e) => {
                setTName(e.target.value);
                handleFieldChange("tName", e.target.value);
              }}
              error={errors.tName}
              errorText={errors.tName}
            />
            <div
              className={`img-upload-cont ${
                selectedFile ? "img-upload-cont1" : ""
              } ${errors.coverImage ? "img-cont-error" : ""}`}
              onClick={() => {
                handleUploadClick();
              }}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelect}
              />
              {selectedFile || isUploading ? (
                <div className="upp-nav">
                  {selectedFile ? <p>Cover Image</p> : <div></div>}
                  {isUploading ? (
                    <ActivityIndicator size="24px" color="#a7fc00" />
                  ) : (
                    <img src={roundedCheck} />
                  )}
                </div>
              ) : (
                <></>
              )}
              <img src={selectedFile ? uploadedImg : uploadImg} alt="" />
              <p>
                {" "}
                {selectedFile ? "Image Uploaded Successfully" : "Upload Image"}
              </p>
              <span
                onClick={() => {
                  if (uploadError) {
                    tryFileUploadAgain();
                  }
                }}
              >
                {selectedFile
                  ? `${selectedFile?.original_filename}.${selectedFile?.format}`
                  : uploadError
                  ? "Try again"
                  : "(375*150 recommended)"}
              </span>
              {selectedFile && (
                <div className="bbb-nav">
                  <div className="nn-wrap">
                    <img src={gallary} alt="" />
                    <p>
                      {selectedFile?.original_filename}.{selectedFile?.format}
                    </p>
                  </div>
                  <img
                    src={trash}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSelectedFile(null);
                    }}
                  />
                </div>
              )}
            </div>
            <div
              className={`abt-talent-cont ${
                errors.aboutTalent ? "img-cont-error" : ""
              }`}
            >
              <div className={`abt-label ${isFocused ? "abt-label1" : ""}`}>
                About Project
              </div>
              <textarea
                ref={textareaRef}
                className="abt-talent-text"
                value={aboutTalent}
                onChange={(e) => {
                  setAboutTalent(e.target.value);
                  adjustHeight(e.target);
                  handleFieldChange("aboutTalent", e.target.value);
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={(e) => setIsFocused(aboutTalent != "" ? true : false)}
              />
            </div>
            {errors.aboutTalent && (
              <p className="error-text">{errors.aboutTalent}</p>
            )}
          </div>
          <div className="content-cont">
            <p className="ov">Overview</p>
            <div className="option-holders">
              <div className="dropdown-holder">
                <div
                  className={`reg-file-cont drop-down ${
                    activeIndustry !== null ? "focused" : ""
                  } ${errors.activeIndustry ? "img-cont-error" : ""}`}
                  onClick={() => {
                    setDropActive(!dropActive);
                  }}
                >
                  <label className="floating-label">Industry</label>
                  <div className="active-ind">{activeIndustry?.name}</div>
                  <div className="chev">
                    <img src={chevDown} alt="" />
                  </div>
                </div>
                {errors.activeIndustry && (
                  <p className="error-text">{errors.activeIndustry}</p>
                )}
                {dropActive && (
                  <div className="in-drop-down">
                    {industries.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={`ind ${
                            item === activeIndustry ? "ind-active" : ""
                          }`}
                          onClick={() => {
                            setActiveIndustry(item);
                            setDropActive(false);
                            handleFieldChange("activeIndustry", item.name);
                          }}
                        >
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Available Units"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={formatWithComma(aUnit)}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/[^0-9]/g, "");
                    setAUnit(inputValue);
                    handleFieldChange("aUnit", e.target.value);

                    const trueValue = (
                      Number(fg) / Number(inputValue)
                    ).toString(); // Remove any leading '$'

                    setUnit(trueValue); // Remove any leading '$'
                    handleFieldChange("unit", trueValue);
                  }}
                  error={errors.aUnit}
                  errorText={errors.aUnit}
                />
              </div>
            </div>

            <div className="option-holders">
              {activeInType?.value == "EquityTokens" ? (
                <div className="dropdown-holder">
                  <div
                    className={`reg-file-cont drop-down ${
                      activeFr !== null ? "focused" : ""
                    } ${errors.activeFr ? "img-cont-error" : ""}`}
                    onClick={() => {
                      setShowFD(!showFd);
                    }}
                  >
                    <label className="floating-label">Funding Round</label>
                    <div className="active-ind">{activeFr}</div>
                    <div className="chev">
                      <img src={chevDown} alt="" />
                    </div>
                  </div>
                  {errors.activeFr && (
                    <p className="error-text">{errors.activeFr}</p>
                  )}
                  {showFd && (
                    <div className="in-drop-down">
                      {fr.map((item: any, index: number) => {
                        return (
                          <div
                            key={index}
                            className={`ind ${
                              item === activeFr ? "ind-active" : ""
                            }`}
                            onClick={() => {
                              // setActiveIndustry(item);
                              setActiveFr(item);
                              setShowFD(false);
                              handleFieldChange("activeFr", item);
                            }}
                          >
                            {item}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              ) : activeInType?.value == "SecurityTokens" ? (
                <div className="dropdown-holder">
                  <div
                    className={`reg-file-cont drop-down focused ${
                      activeFr !== null ? "focused" : ""
                    } ${errors.activeFr ? "img-cont-error" : ""}`}
                    onClick={() => {
                      setShowFD(!showFd);
                    }}
                  >
                    <label className="floating-label">Funding Round</label>
                    <div className="active-ind">No Funding Round</div>
                  </div>
                </div>
              ) : (
                <div className="dropdown-holder">
                  <div
                    className={`reg-file-cont drop-down  ${
                      activeFr !== null ? "focused" : ""
                    } ${errors.activeFr ? "img-cont-error" : ""}`}
                    onClick={() => {
                      setShowFD(!showFd);
                    }}
                  >
                    <label className="floating-label">Funding Round</label>
                    <div className="active-ind"></div>
                  </div>
                </div>
              )}
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Unit Price"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={unit ? `$${formatWithComma(unit)}` : ""}
                  disabled={true}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/[^0-9]/g, "");
                    const trueValue = (Number(fg) / Number(aUnit)).toString(); // Remove any leading '$'

                    // setUnit(inputValue); // Update state without the dollar sign
                    // handleFieldChange("unit", inputValue); // Handle errors
                  }}
                  error={errors.unit}
                  errorText={errors.unit}
                />
              </div>
            </div>
            <div className="option-holders">
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Funding Goal"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={fg ? `$${formatWithComma(fg)}` : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/[^0-9]/g, "");
                    setFg(inputValue);
                    handleFieldChange("fg", e.target.value);

                    const trueValue = (
                      Number(inputValue) / Number(aUnit)
                    ).toString(); // Remove any leading '$'

                    setUnit(trueValue); // Remove any leading '$'
                    handleFieldChange("unit", trueValue);
                  }}
                  error={errors.fg}
                  errorText={errors.fg}
                />
              </div>
              <div className="dropdown-holder">
                <div
                  className={`reg-file-cont drop-down ${
                    activeInType !== null ? "focused" : ""
                  } ${errors.activeInType ? "img-cont-error" : ""}`}
                  onClick={() => {
                    setShowInvestmentType(!showInvestmentType);
                  }}
                >
                  <label className="floating-label">Investment type</label>
                  <div className="active-ind">{activeInType?.name}</div>
                  <div className="chev">
                    <img src={chevDown} alt="" />
                  </div>
                </div>
                {errors.activeInType && (
                  <p className="error-text">{errors.activeInType}</p>
                )}
                {showInvestmentType && (
                  <div className="in-drop-down">
                    {investmentType.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={`ind ${
                            item === activeInType ? "ind-active" : ""
                          }`}
                          onClick={() => {
                            // setActiveIndustry(item);
                            console.log(item);

                            if (item.name == "Debt") {
                              setActiveFr("No Funding Round")
                            }
                            setActiveInType(item);
                            setShowInvestmentType(false);
                            handleFieldChange("activeInType", item.value);
                          }}
                        >
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="content-cont">
            {/* <p className="ov">ROI (over at least 4 periods)</p> */}
            <p className="ov">
              {activeInType?.value == "EquityTokens" ? "Equity" : "Debt"}{" "}
              Investment Details
            </p>
            {activeInType?.value == "EquityTokens" ? <Input
              contClass="list-input-cont"
              extraClass="list-input"
              label="Minimum Investment Return"
              contStyle={{
                height: 64,
                backgroundColor: "#0d0d0d",
                marginTop: 16,
              }}
              value={intrest ? `${formatWithComma(intrest)}%` : ""}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/[^0-9]/g, "");
                setIntrest(inputValue);
                handleFieldChange("intrest", e.target.value);
              }}
              error={errors.intrest}
              errorText={errors.intrest}
            />
          
          :
          <>
            <Input
              contClass="list-input-cont"
              extraClass="list-input"
              label="Intrest rate"
              contStyle={{
                height: 64,
                backgroundColor: "#0d0d0d",
                marginTop: 16,
              }}
              value={intrest ? `${formatWithComma(intrest)}%` : ""}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/[^0-9]/g, "");
                setIntrest(inputValue);
                handleFieldChange("intrest", e.target.value);
              }}
              error={errors.intrest}
              errorText={errors.intrest}
            />

            <div className="dropdown-holder-100 mt-[16px]">
              <div
                className={`reg-file-cont drop-down ${
                  activeInType1 !== null ? "focused" : ""
                } ${errors.activeInType1 ? "img-cont-error" : ""}`}
                onClick={() => {
                  setShowInvestmentType1(!showInvestmentType1);
                }}
              >
                <label className="floating-label">Investment type</label>
                <div className="active-ind">{activeInType1?.name}</div>
                <div className="chev">
                  <img src={chevDown} alt="" />
                </div>
              </div>
              {errors.activeInType1 && (
                <p className="error-text">{errors.activeInType1}</p>
              )}
              {showInvestmentType1 && (
                <div className="in-drop-down">
                  {period.map((item: any, index: number) => {
                    return (
                      <div
                        key={index}
                        className={`ind ${
                          item === activeInType1 ? "ind-active" : ""
                        }`}
                        onClick={() => {
                          // setActiveIndustry(item);
                          console.log(item);

                          setActiveInType1(item);
                          setShowInvestmentType1(false);
                          handleFieldChange("activeInType1", item.value);
                        }}
                      >
                        {item.name}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
          }
            

            {/* {rows.map((item, index) => {
              return (
                <div className="option-holders">
                  <NumberMonthDropdown
                    label="Number of Months"
                    options={getFilteredOptions(index)}
                    selected={
                      numberofmonths.find(
                        (i: any) => i.value === item.months
                      ) || null
                    }
                    onSelect={(selectedOption) => {
                      console.log(selectedOption);

                      handleDropdownChange(index, selectedOption);
                      handleFieldChange(`m${index}`, item.months);
                    }}
                    errorText={errors[`m${index}`]}
                  />

                  <img
                    src={colon}
                    alt=""
                    style={{
                      marginBottom:
                        errors[`m${index}`] || errors[`roi${index}`] ? 16 : 0,
                    }}
                  />
                  <div className="input-holder">
                    <Input
                      contClass="list-input-cont"
                      extraClass="list-input"
                      label="Projected ROI"
                      contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                      value={item.roi}
                      onChange={(e) => {
                        handleInputChange(index, "roi", e.target.value);
                        handleFieldChange(`roi${index}`, item.roi);
                      }}
                      error={!!errors[`roi${index}`]} // Highlight the input in error
                      errorText={errors[`roi${index}`]}
                    />
                  </div>
                </div>
              );
            })} */}
            <div className="dash"></div>
            {/* <div className="inc-btn-cont">
              <div
                className="add-btn"
                onClick={() => {
                  handleAddRow();
                }}
              >
                <img src={plus} alt="" /> Add more
              </div>
            </div> */}
          </div>

          <div className="content-cont">
            <div
              className={`abt-talent-cont ${
                errors.usef ? "img-cont-error" : ""
              }`}
            >
              <div className={`abt-label ${isFocused1 ? "abt-label1" : ""}`}>
                Use of funds
              </div>
              <textarea
                ref={textareaRef}
                className="abt-talent-text"
                value={usef}
                onChange={(e) => {
                  setUseF(e.target.value);
                  adjustHeight(e.target);
                  handleFieldChange("usef", e.target.value);
                }}
                onFocus={() => setIsFocused1(true)}
                onBlur={(e) => setIsFocused1(usef != "" ? true : false)}
              />
            </div>
            {errors.usef && <p className="error-text">{errors.usef}</p>}
          </div>
          <div className="std-cont">
            <div className="std-btn">Save to draft</div>
            <div className="c-btn" onClick={handleFormSubmit}>
              Continue
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListProject;
