import React from "react";
import { useNavigate } from "react-router-dom";
import tick from "../../../assets/tick.gif";
import SecBtn from "../../../Components/SecBtn";

export default function BulkEmailSuccess() {
    const navigate = useNavigate();
    return (
        <section className="emailBody">
            <img src={tick} alt="" className="envelop" />
            <div className="textWrap">
                <h4>Bulk email sent</h4>
                <p>
                    Your broadcast email has been
                    <br />sent to the selected email
                    <br />addresses.
                </p>
            </div>
            <div className="btn-Wrap">
                <SecBtn
                    text="Back to settings"
                    onPress={() => {
                        navigate("/SettingsScreen");
                    }}
                />
            </div>
        </section>
    );
}
