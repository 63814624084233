import React, { useEffect, useState } from "react";
import vImgSmall from "../assets/vImgSmall.png";
import close from "../assets/close-circle.svg";
import ticket from "../assets/ticket.svg";
import calender from "../assets/date.svg";
import notCalender from "../assets/notCalender.svg";
import chevDown from "../assets/chev-down.svg";
import Input from "./Input";
import {
  AddUpdate,
  GetHolderById,
  GetVoucherTransaction,
} from "../RequestHandlers/Voucher";
import { formatDate, formatDate3 } from "./formatDate";
import { calculateEndDate } from "./CalculateEndDate";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import emptyList from "../assets/empty-list.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateInput from "./DateInput";
import TimeInput from "./TImeInput";
import { useNavigate } from "react-router-dom";
import Toast, { handleToast } from "./Toast";

interface PProps {
  onClose?: any;
  id?: any;
  holderDetails?: any;
  onReportClick?: any;
  selectedDate?: any;
  onChange?: any;
}
const CustomDatePicker = ({ selectedDate, onChange }: PProps) => {
  return (
    <DatePicker
      selected={selectedDate}
      onChange={onChange}
      dateFormat="yyyy-MM-dd"
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      className="custom-date-picker"
    />
  );
};

function VHistory({ onClose, id, holderDetails, onReportClick }: PProps) {
  // const [activeService, setActiveService] = useState(null);
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const [service, setService] = useState<any>("");
  const [product, setProduct] = useState<any>("");
  const [date, setDate] = useState(null);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  // const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [errors, setErrors] = useState({
    product: "",
    service: "",
    value: "",
    date: "",
    time: "",
  });
  const [history, setHistory] = useState<any>([]);
  const validateField = (fieldName: string, value: any) => {
    switch (fieldName) {
      case "product":
      case "service":
        // Ensure that at least one of product or service is filled
        if (!product.trim() && !service.trim()) {
          return "Either Product or Service is required";
        }
        return "";
      case "value":
        return value.trim() === "" ? "Value is required" : "";
      case "date":
        if (!value) return "Date is required";
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        const formattedDate =
          value instanceof Date ? value.toISOString().split("T")[0] : value;
        return !dateRegex.test(formattedDate)
          ? "Invalid date format (YYYY-MM-DD)"
          : "";
      case "time":
        if (!value) return "Time is required";
        const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        const formattedTime =
          value instanceof Date
            ? value.toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
              })
            : value;
        return !timeRegex.test(formattedTime)
          ? "Invalid time format (HH:mm)"
          : "";
      default:
        return "";
    }
  };

  const handleInputChange = (fieldName: string, value: any) => {
    switch (fieldName) {
      case "product":
        setProduct(value);
        break;
      case "service":
        setService(value);
        break;
      case "value":
        setValue(value);
        break;
      case "date":
        setSelectedDate(value);
        setDate(
          value instanceof Date ? value.toISOString().split("T")[0] : value
        );
        break;
      case "time":
        if (!value) {
          setSelectedTime(null); // Reset if no value
          setTime("");
          break;
        }
        // Check if value is a valid Date before formatting
        let formattedTime: any;
        if (value && value instanceof Date && !isNaN(value.getTime())) {
          // Format time as HH:mm
          const hours = value.getHours().toString().padStart(2, "0"); // Ensure two digits
          const minutes = value.getMinutes().toString().padStart(2, "0"); // Ensure two digits
          formattedTime = `${hours}:${minutes}`; // Combine hours and minutes
          setSelectedTime(formattedTime); // Store the formatted time as a string
        }
        setSelectedTime(value);
        setTime(formattedTime);
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      product: validateField("product", product),
      service: validateField("service", service),
      [fieldName]: validateField(fieldName, value),
    }));
  };

  const isFormValid = () => {
    return (
      (Object.values(errors).every((error) => error === "") && product) ||
      (service && value && selectedDate && selectedTime)
    );
  };

  const handleSubmit = async () => {
    console.log("form");
    console.log(errors);
    if (isFormValid()) {
      const body = {
        voucherId: id,
        amount: value,
        service: service,
        description: product,
        date: selectedDate,
        time: time,
      };
      const filteredBody = Object.fromEntries(
        Object.entries(body).filter(([key, value]) => value !== "")
      );
      console.log(filteredBody);
      try {
        setLoading(true);
        const res = await AddUpdate(body);
        console.log(res);
        if (res.active) {
          navigate("/VoucherDeleted", {
            state: { type: "update", code: holderDetails?.code },
          });
        } else {
          handleToast(res.message, "error");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
      // navigate("/VerifyEmailScreen", { state: { data1 } });
    }
  };

  const getHolderById = async () => {
    try {
      const res = await GetHolderById(1, 10, id);
      console.log("holder", res);
    } catch (error) {
      console.log(error);
    }
  };
  const getVoucherTransaction = async () => {
    try {
      const res = await GetVoucherTransaction(id, 1, 20);
      console.log("transaction", res.items);
      setHistory(res.items);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(holderDetails);
    getHolderById();
    getVoucherTransaction();
  }, []);

  return (
    <div className="v-history">
      <Toast />
      <div className="d-card-nav">
        <div className="d-card-wrap1">
          <img
            src={holderDetails?.brandVoucher?.image}
            alt=""
            style={{ objectFit: "cover" }}
          />
          <div>
            <h4 className="h-n">
              {holderDetails?.user?.firstname} {holderDetails?.user?.lastname}
            </h4>
            <p
              style={{
                color: "#A7FC00",
                fontSize: 16,
                fontWeight: 400,
                marginLeft: 0,
              }}
            >
              {holderDetails?.brandVoucher?.addedValue}% added value
            </p>
          </div>
          <div className="pr-num" style={{ backgroundColor: "#343434" }}>
            {holderDetails?.code}
          </div>
        </div>
        <img
          src={close}
          alt=""
          onClick={onClose}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="v-history-body">
        <div className="vhis-body1">
          <div className="dd-details-cont">
            <h4>Voucher details</h4>
            <div
              className="dd-details-item"
              style={{ justifyContent: "space-between" }}
            >
              <div className="dd-item" style={{ alignItems: "center" }}>
                <img src={ticket} alt="" width={36} height={36} />
                <div className="dd-item-wrap">
                  <h4>Voucher Price</h4>
                  <p>${holderDetails?.value?.toLocaleString()} / Unit</p>
                </div>
              </div>
              <div className="dd-item" style={{ alignItems: "center" }}>
                <img src={ticket} alt="" width={36} height={36} />
                <div className="dd-item-wrap">
                  <h4>Total value spent</h4>
                  <p>${holderDetails?.totalSpent?.toLocaleString()}</p>
                </div>
              </div>
            </div>
            <div
              className="dd-details-item"
              style={{ justifyContent: "space-between" }}
            >
              <div className="dd-item" style={{ alignItems: "center" }}>
                <img src={calender} alt="" width={36} height={36} />
                <div className="dd-item-wrap">
                  <h4>Start Date</h4>
                  <p style={{ color: "#C3B2F7" }}>
                    {formatDate3(holderDetails?.created_at)}
                  </p>
                </div>
              </div>
              <div className="dd-item" style={{ alignItems: "center" }}>
                <img src={notCalender} alt="" width={36} height={36} />
                <div className="dd-item-wrap">
                  <h4>End Date</h4>
                  <p style={{ color: "#BA2543" }}>
                    {calculateEndDate(
                      holderDetails?.brandVoucher?.created_at,
                      holderDetails.brandVoucher.duration
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="update-form">
            <div className="w-full mb-[16px]">
              <Input
                type="text"
                label="Service"
                value={service}
                contClass="inp-input"
                extraClass="inp-input"
                onChange={(e) => handleInputChange("service", e.target.value)}
                error={errors.service ? true : false}
                contStyle={{ height: 64 }}
              />
            </div>
            <div className="w-full mb-[16px]">
              <Input
                type="text"
                label="Product"
                value={product}
                contClass="inp-input"
                extraClass="inp-input"
                onChange={(e) => handleInputChange("product", e.target.value)}
                error={errors.product ? true : false}
                contStyle={{ height: 64 }}
              />
            </div>
            <div className="in-hold" style={{ marginBottom: 0 }}>
              <div className="inp">
                <Input
                  type="text"
                  label="Value"
                  value={value}
                  contClass="inp-input"
                  extraClass="inp-input"
                  onChange={(e) => handleInputChange("value", e.target.value)}
                  error={errors.value ? true : false}
                  contStyle={{ height: 64 }}
                />
              </div>
              <div className="inp">
                {/* <CustomDatePicker selectedDate={date} onChange={setDate} /> */}
                <DateInput
                  label="Date"
                  selectedDate={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date);
                    handleInputChange(
                      "date",
                      date ? date.toISOString().split("T")[0] : ""
                    );
                  }}
                  contStyle={{ height: 64 }}
                  error={errors.date ? true : false}
                />
              </div>
              <div className="inp">
                <TimeInput
                  label="Time"
                  selectedTime={selectedTime}
                  onChange={(time) => {
                    handleInputChange("time", time);
                  }}
                  contStyle={{ height: 64 }}
                  error={errors.time ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="std-cont" style={{ marginTop: 24 }}>
            <div
              className="std-btn"
              onClick={() => {
                onReportClick();
              }}
            >
              Report issue
            </div>
            <button
              className={`c-btn ${
                !isFormValid() || loading ? "btn-disabled" : ""
              }`}
              onClick={() => {
                handleSubmit();
              }}
              disabled={!isFormValid() || loading}
            >
              {loading ? (
                <div className="dot-loader">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                </div>
              ) : (
                " Update"
              )}
            </button>
          </div>
        </div>
        <div className="vhis-body1 vhis-body2">
          <p className="vhis-hText">Voucher history</p>
          {history.length === 0 ? (
            <div className="empty-wrap" style={{ height: 400 }}>
              <img src={emptyList} alt="" />
              <p>{"No voucher history"} </p>
              <span>The voucher history will appear here</span>
            </div>
          ) : (
            <>
              <div className="custom-th-wrap">
                <div className="custom-th">Service/Product</div>
                <div className="custom-th">Value spent</div>
                <div className="custom-th">Date</div>
              </div>
              <div className="vHistries-holder">
                {history.map((item: any, index: number) => {
                  return (
                    <div
                      className="custom-td-wrap"
                      style={{ marginTop: index === 0 ? 0 : 8 }}
                    >
                      <div className="custom-td" style={{ paddingRight: 10 }}>
                        {item?.description}
                      </div>
                      <div className="custom-td">
                        ${item?.amount?.toLocaleString()}
                      </div>
                      <div className="custom-td">
                        {formatDate(item?.created_at)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default VHistory;
