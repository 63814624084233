import React, { useEffect, useState } from "react";
import ng from "../../assets/ng.png";
import us from "../../assets/us.png";
import { useNavigate } from "react-router-dom";
import HeaderBar from "../../Components/HeaderBar";

interface CategoryOption {
  id: string;
  title: string;
  description: string;
}

const categories: CategoryOption[] = [
  {
    id: "film",
    title: "Film",
    description: "For studios looking to fund amazing stories.",
  },
  {
    id: "football",
    title: "Football",
    description: "Ideal for football teams looking to build with fans.",
  },
  {
    id: "infrastructure",
    title: "Infrastructure",
    description: "For real estate and infrastructure companies.",
  },
];

export default function OnboardingCategory() {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [onContinue, setOnContinue] = useState<boolean | null>(false);
  const navigate = useNavigate();

  const showCategory = () => {
    console.log(selectedCategory, "selectedCategory");
  };

  return (
    <div className="min-h-screen bg-[#020210] text-white">
      <div
        className={`w-full h-full fixed bg-[#020210dd] flex items-center justify-center z-50 ${
          !onContinue && "hidden"
        }`}
      >
        <div className="w-[95%] md:w-[50%] h-[80%] bg-[#020210] border border-[#14141C] flex items-center justify-center flex-col gap-5">
          <h4 className="text-2xl font-semibold">
            Choose a Primary Jurisdiction
          </h4>

          <div className="w-[70%] h-[30%] bg-[#14141C] flex items-center justify-around rounded">
            <img
              src={ng}
              alt=""
              className="w-[20%] cursor-pointer"
              onClick={() => {
                navigate(`/OnboardingJurisdiction?ng&${selectedCategory}`);
              }}
            />
            <img
              src={us}
              alt=""
              className="w-[20%] cursor-pointer"
              onClick={() => {
                navigate(`/OnboardingJurisdiction?us&${selectedCategory}`);
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row min-h-screen">
        {/* Left side */}
        <div className="w-full lg:w-1/2 p-6 md:p-12 lg:p-36">
          <HeaderBar
            onclick={() => {
              navigate(-1);
            }}
          />
          <h1 className="text-2xl md:text-3xl font-regular mb-2">
            Get Started
          </h1>
          <p className="text-gray-500 mb-12 md:mb-16">
            Answer a few questions to help us get started.
          </p>

          <div className="relative">
            {/* Vertical line connecting the numbers */}
            <div className="absolute left-4 top-10 bottom-10 w-[1px] bg-gray-800"></div>

            <div className="space-y-12">
              <div className="flex items-start relative z-10">
                <div className="w-[2.1rem] h-[2.1rem] rounded-full flex items-center justify-center text-sm shrink-0 bg-gradient-to-r from-sky-500 from-10% via-sky-500 via-30% to-emerald-500 to-90%">
                  <div className="w-[2rem] h-[2rem] rounded-full bg-[#020210] flex items-center justify-center text-sm shrink-0">
                    1
                  </div>
                </div>
                <div className="ml-6">
                  <h2 className="text-xl font-semibold">Category</h2>
                  <p className="text-gray-500 text-xs">
                    Choose the type of entity that you intend to <br />
                    tokenise.
                  </p>
                </div>
              </div>
              {/*  */}
              <div
                className={`flex items-start ${
                  !onContinue && "opacity-40"
                }  relative z-10`}
              >
                {onContinue ? (
                  <div className="w-[2.1rem] h-[2.1rem] rounded-full flex items-center justify-center text-sm shrink-0 bg-gradient-to-r from-sky-500 from-10% via-sky-500 via-30% to-emerald-500 to-90%">
                    <div className="w-[2rem] h-[2rem] rounded-full bg-[#020210] flex items-center justify-center text-sm shrink-0">
                      2
                    </div>
                  </div>
                ) : (
                  <div className="w-8 h-8 rounded-full border border-gray-600 flex items-center justify-center text-sm shrink-0">
                    2
                  </div>
                )}

                <div className="ml-6">
                  <h2 className="text-lg font-medium">Jurisdiction</h2>
                </div>
              </div>

              <div className="flex items-start opacity-40 relative z-10">
                <div className="w-8 h-8 rounded-full border border-gray-600 flex items-center justify-center text-sm shrink-0">
                  3
                </div>
                <div className="ml-6">
                  <h2 className="text-lg font-medium">Review and pay</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right side */}
        <div className="w-full lg:w-1/2 p-6 md:p-12 lg:p-36 lg:pl-0 ">
          <div className="bg-[#13131A] p-6 md:p-8 lg:p-12 ">
            <h2 className="text-2xl md:text-3xl font-medium mb-2">Category</h2>
            <p className="text-gray-500 mb-6 md:mb-8">
              Choose the type of entity that you intend to tokenise.
            </p>

            <div className="space-y-3">
              {categories.map((category) => {
                return (
                  <div className="w-full h-[96px] bg-[#ff0000] rounded flex justify-center items-center bg-gradient-to-r from-sky-500 from-10% via-sky-500 via-30% to-emerald-500 to-90%">
                    <button
                      key={category.id}
                      onClick={() => setSelectedCategory(category.id)}
                      className={` p-4 rounded text-left transition-all ${
                        selectedCategory === category.id
                          ? "w-[99.5%] h-[98%]  bg-[#1D1D26] "
                          : "w-[100%] h-[100%] bg-[#1D1D26] hover:bg-[#1D1D26]/80"
                        //   selectedCategory === category.id
                        //     ? 'bg-[#1D1D26] ring-1 ring-[#00E5BE]'
                        //     : 'bg-[#1D1D26] hover:bg-[#1D1D26]/80'
                      }`}
                    >
                      <div className="flex items-center">
                        <div className="ml-3">
                          <h3 className="font-medium">{category.title}</h3>
                          <p className="text-sm text-gray-500">
                            {category.description}
                          </p>
                        </div>
                      </div>
                    </button>
                  </div>
                );
              })}
            </div>

            <div
              className={`w-full bg-[#ff0000] rounded flex justify-center items-center mt-6 md:mt-8  bg-gradient-to-r from-sky-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% 
            ${selectedCategory && "py-[1px] px-[1px] "}`}
            >
              <button
                className={`w-full py-3 px-4 rounded font-medium transition-all ${
                  selectedCategory
                    ? "bg-[#1D1D26] hover:bg-[#00E5BE]/90 text-white"
                    : "bg-[#1D1D26] text-gray-500 cursor-not-allowed"
                }`}
                disabled={!selectedCategory}
                onClick={() => {
                  setOnContinue(true);
                  showCategory();
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
