import "../../../css/settings/style.css";
import Input from "../../Input";
import trashIcon from "../../../assets/trash.svg";
import plusIcon from "../../../assets/plus.svg";
import { FormikProps } from "formik";
import {
  SettingsFormValues,
  Achievement,
} from "../../../Screens/Settings/self-listed/SelfListed";

interface Props<T> {
  formik: FormikProps<T>;
}

const CompanyAchievements: React.FC<Props<SettingsFormValues>> = ({
  formik,
}) => {
  const handleAddAchievement = () => {
    const newAchievement: Achievement = { id: Date.now(), name: "", link: "" };
    formik.setFieldValue("achievements", [
      ...formik.values.achievements,
      newAchievement,
    ]);
  };

  const handleDeleteAchievement = (id: number) => {
    const updatedAchievements = formik.values.achievements.filter(
      (item) => item.id !== id
    );
    formik.setFieldValue("achievements", updatedAchievements);
  };

  const handleInputChange = (
    id: number,
    field: "name" | "link",
    value: string
  ) => {
    const updatedAchievements = formik.values.achievements.map((item) =>
      item.id === id ? { ...item, [field]: value } : item
    );
    formik.setFieldValue("achievements", updatedAchievements);
  };

  return (
    <div className="settings-form-container">
      <div className="title-container">
        <p>Company Achievements</p>
      </div>
      {formik.values.achievements.map((achievement, index) => (
        <div
          key={achievement.id}
          style={{
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <Input
            label={`Title ${index + 1}`}
            contStyle={{
              marginBottom: 16,
              height: 64,
              backgroundColor: "#0d0d0d",
            }}
            contClass="list-input-cont"
            extraClass="list-input"
            value={achievement.name}
            onChange={(e) =>
              handleInputChange(achievement.id, "name", e.target.value)
            }
          />
          <Input
            label="Enter Document Link"
            contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
            contClass="list-input-cont"
            extraClass="list-input"
            value={achievement.link}
            onChange={(e) =>
              handleInputChange(achievement.id, "link", e.target.value)
            }
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 14,
              marginTop: 26,
              padding: "0px 36px",
            }}
          >
            <img
              style={{ width: 16, height: 16, cursor: "pointer" }}
              src={trashIcon}
              alt="Delete"
              onClick={() => handleDeleteAchievement(achievement.id)}
            />
            <p
              className="delete-text"
              onClick={() => handleDeleteAchievement(achievement.id)}
            >
              Delete
            </p>
          </div>
        </div>
      ))}
      <div
        className="add-company-portfolio-container"
        onClick={handleAddAchievement}
      >
        <img src={plusIcon} alt="Add Achievement" />
        <p>Add Company Achievement</p>
      </div>
    </div>
  );
};

export default CompanyAchievements;
