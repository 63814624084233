import React, { useState, useRef, useEffect } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useLocation, useNavigate } from "react-router-dom";
import { GetUserDetails } from "../../RequestHandlers/User";
import {
  getComId,
  storeComID,
  storeCompliancePath,
} from "../../utils/Localstorage";
import chevBack from "../../assets/ion_chevron-back.svg";

function DaoDahsboard() {
  const navigate = useNavigate();
  const location = useLocation();

  const [showDelMenu, setShowDelMenu] = useState(null);
  const [vName, setVName] = useState("");
  const [description, setDescription] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [details, setdetails] = useState({ contactEmail: "" });
  const [DAO, setDAO] = useState<any>({});
  const [AllDAO, setAllDAO] = useState<any>([]);
  const [DAOLoading, setDAOLoading] = useState(true);

  function capitalizeFirstLetter(word: string): string {
    if (!word) return ""; // Handle empty or null strings
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  const getDao = () => {
    console.log(getComId());

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      id: getComId(),
      // id: data.id,
    });

    fetch("https://trendx.app/api/getDAObyid.php", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status == "success") {
          setDAO(result.data);
        }
      })
      .catch((error) => console.error(error));
  };

  const getUserDetails = async () => {
    try {
      const res = await GetUserDetails();
      if (res.avatar) {
        setdetails(res);
        getAllDao(res.contactEmail);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Card = ({ title, description, link, explore = true }: any) => {
    return (
      <div className="bg-[#121212] w-full sm:w-[300px] p-6 rounded-md text-white">
        <h2 className="text-lg font-semibold">{title}</h2>
        <p className="text-gray-400 text-sm mt-2">{description}</p>
        {explore && (
          <button
            className="mt-4 text-[#A7FC00] text-sm flex items-center gap-1"
            onClick={() =>
              navigate(
                "/" + link

                // {
                //   state: {
                //     data: { id: data.id },
                //   },
                // }
              )
            }
          >
            Explore <span className="text-lg">&gt;</span>
          </button>
        )}
      </div>
    );
  };

  const getAllDao = (email: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email,
    });

    fetch("https://trendx.app/api/getDAO.php", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setDAOLoading(false);
        if (result.status == "success") {
          setAllDAO(result.data);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getDao();
    getUserDetails();
    storeCompliancePath("DaoDahsboard");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="dash-body">
        <SideNav activeTab="Compliance" />
        <div className="content-body">
          <ProfileNav />
          <div className="section-ph">
            <div className="ph-text-sec">
              {/* <h4>Compliance</h4>
              <p>Set-up and manage DAO</p> */}
              {/* <div
                className="c-back"
                onClick={() => {
                  navigate("/Compliance");
                }}
              >
                <img src={chevBack} alt="" />
                <p>Back</p>
              </div> */}
            </div>
            <div className="relative">
              <div
                className="lnp-btn"
                onClick={() => {
                  setShowPopup(!showPopup);
                }}
              >
                Manage DAO
              </div>
              {showPopup && (
                <div className="absolute top-14 left-2 -translate-x-1/2 w-[250px] p-4 bg-[#222] text-white rounded-lg shadow-lg">
                  {DAOLoading ? (
                    <div className="dot-loader">
                      <span className="dot2"></span>
                      <span className="dot2"></span>
                      <span className="dot2"></span>
                    </div>
                  ) : AllDAO.length !== 0 ? (
                    <div>
                      {AllDAO.map((items: any) => (
                        <div
                          className="flex gap-1 hover:bg-[#444] w-full h-[30px] items-center cursor-pointer"
                          onClick={() => {
                            storeComID(items.id);
                            window.location.reload();

                            // navigate("/DaoDahsboard");
                          }}
                        >
                          <p className="text-[#fff] text-xs ml-2">
                            {items.preferred_name_1}
                          </p>
                          <p className="text-[#fff] text-xs font-bold">DAO</p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-gray-400 italic">No DAO found</p>
                  )}
                  <button
                    className="mt-4 w-full bg-lime-400 text-black font-semibold px-4 py-2 rounded-md"
                    onClick={() => navigate("/CreateDao")}
                  >
                    Register a new DAO
                  </button>
                </div>
              )}
            </div>
            {/* <div className="relative">
              <div
                className="lnp-btn"
                onClick={() => {
                  setShowPopup(!showPopup);
                }}
              >
                Manage DAO
              </div> 
            </div> */}
          </div>

          <div className=" bg-black flex flex-col items-start justify-center p-6">
            <div className="text-left mb-8">
              <div className="flex items-center justify-start gap-2">
                <img
                  src={DAO.upload_id} // Replace with actual logo
                  alt="DAO Logo"
                  className="w-10 h-10 rounded-[100%] border border-[#BA292B] border-[3px]"
                />
                <h1 className="text-white text-xl font-semibold">
                  {DAO.preferred_name_1} DAO
                </h1>
              </div>
              <p className="text-gray-400 mt-2 max-w-lg">
                Tokenization of real-world assets under a compliant structure
                for the acquisition and development of the{" "}
                {DAO.preferred_name_1} by {DAO.first_name} {DAO.last_name}.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <Card
                title="Details"
                description="No action needed"
                link="DaoContent"
              />
              <Card
                title="Smart Contract"
                description="No action needed"
                link="DaoSmartContract"
              />
              <Card
                title="Accounting & Tax Filing"
                description="No action needed"
                explore={false}
              />
              <Card
                title="Documents"
                description="No action needed"
                link="DaoDocument"
                explore={false}
              />
            </div>
          </div>
          {/* <div className="v-holder ">
            <div className="v-head pl-[2%]">
              <h4 className="v-head-text">😎 Get Started</h4>
            </div>
            <p style={{ color: "#ddd" }} className="mt-5 pl-[2%]">
              Incorporate DAO and tokenize asset to unlock <br />
              liquidity options.
            </p>
            <div
              style={{ transition: "0.5s" }}
              className=" ml-[2%] w-[150px] bg-[#fff] h-[50px] flex items-center justify-center rounded-[20px] mt-5 cursor-pointer hover:bg-[#ddd]"
              onClick={() => {
                navigate("/CreateDao");
              }}
            >
              Start DAO
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default DaoDahsboard;
