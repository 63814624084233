
export const ArrowDownIcon = () => (
  <>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9181 8.94922L13.3981 15.4692C12.6281 16.2392 11.3681 16.2392 10.5981 15.4692L4.07812 8.94922"
        stroke="#CDCDCC"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </>
);
export const DownloadIcon = () => (
  <>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4366 8.89844C20.0366 9.20844 21.5066 11.0584 21.5066 15.1084V15.2384C21.5066 19.7084 19.7166 21.4984 15.2466 21.4984H8.73656C4.26656 21.4984 2.47656 19.7084 2.47656 15.2384V15.1084C2.47656 11.0884 3.92656 9.23844 7.46656 8.90844"
        stroke="#292D32"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 2V14.88"
        stroke="#292D32"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.3484 12.6484L11.9984 15.9984L8.64844 12.6484"
        stroke="#292D32"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </>
);
export const PrevArrowIcon = () => (
  <>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9724 4.94141L2.91406 9.99974L7.9724 15.0581"
        stroke="#A5A5A5"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.0797 10H3.05469"
        stroke="#A5A5A5"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </>
);
export const NextArrowIcon = () => (
  <>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16406 10.0013H15.8307M15.8307 10.0013L9.9974 4.16797M15.8307 10.0013L9.9974 15.8346"
        stroke="#030500"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </>
);
export const CircleArrowIcon = () => (
  <>
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5" cy="5" r="5" fill="#29B358" />
      <path
        d="M5.70312 3.33203H7.498V5.1269"
        stroke="black"
        stroke-width="0.416667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.4974 3.33203L4.96214 5.86729C4.9202 5.9084 4.86381 5.93142 4.80509 5.93142C4.74636 5.93142 4.68998 5.9084 4.64804 5.86729L3.61599 4.83524C3.57405 4.79413 3.51766 4.7711 3.45893 4.7711C3.40021 4.7711 3.34382 4.79413 3.30188 4.83524L1.66406 6.47306"
        stroke="black"
        stroke-width="0.416667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </>
);
export const CloseIcon = () => (
  <>
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="15" height="15" rx="7.5" fill="white" />
      <path
        d="M5.07812 4.58594L11.0122 10.4367"
        stroke="#030500"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.0156 4.58594L5.08154 10.4367"
        stroke="#030500"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </>
);