import React, { CSSProperties } from "react";

interface PProps {
  text?: string;
  disabled?: boolean;
  loading?: boolean; // New loading prop
  onPress?: (e?: any) => void;
  style?: CSSProperties;
  extraClass?: string;
  icon?: any;
  type?: "submit" | "reset" | "button";
}

export default function SecBtn({
  text,
  disabled,
  loading,
  onPress,
  style,
  extraClass,
  icon,
  type,
}: PProps) {
  return (
    <button
      type={type}
      className={`sec-btn ${disabled || loading ? "btn-disabled" : ""
        } ${extraClass}`}
      disabled={disabled || loading} // Disable button when loading
      onClick={onPress}
      style={style}
    >
      {loading ? (
        <div className="dot-loader">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
      ) : (
        <>
          {text}  {icon}
        </>

      )}
    </button>
  );
}
