import React from "react";
import { Helmet } from "react-helmet-async";

interface JsonLdData {
  "@context"?: string;
  "@type"?: string;
  headline?: string;
  description?: string;
  author?: {
    "@type"?: string;
    name?: string;
  };
  publisher?: {
    "@type"?: string;
    name?: string;
    logo?: {
      "@type"?: string;
      url?: string;
    };
  };
  url?: string;
  datePublished?: string;
  dateModified?: string;
}

interface HeaderHelmetProps {
  title: string;
  description: string;
  keywords?: string;
  url: string;
  image?: string;
  jsonLdData?: JsonLdData; 
}


const HeaderHelmet: React.FC<HeaderHelmetProps> = ({
  title,
  description,
  keywords,
  url,
  image,
  jsonLdData,
}) => {
  const defaultImage = "https://trendx.app/logo192.png";

  // Default structured data
  const defaultJsonLdData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: title,
    description: description,
    author: {
      "@type": "Organization",
      name: "TrendX",
    },
    publisher: {
      "@type": "Organization",
      name: "TrendX",
      logo: {
        "@type": "ImageObject",
        url: "https://trendx.app/logo192.png",
      },
    },
    url: url,
    datePublished: "2024-02-10",
    dateModified: "2024-02-10",
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={url} />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image || defaultImage} />
      <meta property="og:url" content={url} />
      <meta
        property="og:type"
        content={jsonLdData?.["@type"] === "Article" ? "article" : "website"}
      />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image || defaultImage} />

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(jsonLdData || defaultJsonLdData)}
      </script>

      {/* Breadcrumb Schema */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          name: "TrendX",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "Home",
              item: "https://trendx.app/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Tokenize your Asset",
              item: "https://trendx.app/tokenizewithus",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: "Pricing",
              item: "https://trendx.app/pricing",
            },
            {
              "@type": "ListItem",
              position: 4,
              name: "What is Tokenization",
              item: "https://trendx.app/whatIsTokenization",
            },
            {
              "@type": "ListItem",
              position: 5,
              name: "Log in",
              item: "https://trendx.app/LoginScreen",
            },
          ],
        })}
      </script>
    </Helmet>
  );
};

export default HeaderHelmet;
