import * as Yup from "yup";

export const settingsSchema = Yup.object({
  name: Yup.string().max(100, "Name must be at most 100 characters"),
  description: Yup.string().max(
    500,
    "Description must be at most 500 characters"
  ),
  companyRegistration: Yup.string(),
  CEO: Yup.string().max(100, "CEO name must be at most 100 characters"),
  ceoLinkedin: Yup.string().url("Must be a valid LinkedIn URL"),
  companySize: Yup.string().matches(
    /^\d+-\d+ employees$/,
    "Format must be like '10-50 employees'"
  ),
  website: Yup.string().url("Must be a valid URL"),
  linkedIn: Yup.string().url("Must be a valid LinkedIn URL"),
  phone: Yup.string().matches(
    /^\+?[1-9]\d{1,14}$/,
    "Must be a valid international phone number"
  ),
  industry: Yup.string().max(100, "Industry must be at most 100 characters"),
  officeLocation: Yup.string().max(
    200,
    "Office location must be at most 200 characters"
  ),
  marketPresence: Yup.string().max(
    500,
    "Market presence must be at most 500 characters"
  ),
  mission: Yup.string().max(500, "Mission must be at most 500 characters"),
  vision: Yup.string().max(500, "Vision must be at most 500 characters"),
  strategies: Yup.string().max(
    1000,
    "Strategies must be at most 1000 characters"
  ),
  portifolio: Yup.array().of(
    Yup.object({
      id: Yup.number().required(),
      name: Yup.string().max(
        100,
        "Portfolio name must be at most 100 characters"
      ),
      link: Yup.string().url("Must be a valid URL"),
    })
  ),
  achievements: Yup.array().of(
    Yup.object({
      id: Yup.number().required(),
      name: Yup.string().max(
        100,
        "Achievement name must be at most 100 characters"
      ),
      link: Yup.string().url("Must be a valid URL"),
    })
  ),
  partnerShip: Yup.array().of(
    Yup.object({
      id: Yup.number().required(),
      partnerCompany: Yup.string().max(
        100,
        "Partner company name must be at most 100 characters"
      ),
      duration: Yup.number().min(0, "Duration must be a positive number"),
      purpose: Yup.string().max(500, "Purpose must be at most 500 characters"),
    })
  ),
  supportingDocuments: Yup.array().of(
    Yup.object({
      id: Yup.number().required(),
      name: Yup.string().max(
        100,
        "Document name must be at most 100 characters"
      ),
      link: Yup.string().url("Must be a valid URL"),
    })
  ),
  stakeholders: Yup.array().of(
    Yup.object({
      id: Yup.number().required(),
      name: Yup.string().max(
        100,
        "Stakeholder name must be at most 100 characters"
      ),
      link: Yup.string().url("Must be a valid URL"),
      role: Yup.string().max(100, "Role must be at most 100 characters"),
    })
  ),
  companyLinkedin: Yup.string().url("Must be a valid LinkedIn URL"),
});
