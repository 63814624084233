import React, { useState, useEffect, useRef } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useLocation, useNavigate } from "react-router-dom";
import chevBack from "../../assets/ion_chevron-back.svg";
import Toast, { handleToast } from "../../Components/Toast";
import FadeInAnimation from "../../Components/FadeIn";
import emptyList from "../../assets/empty-list.svg";
import { NextArrowIcon, PrevArrowIcon } from "../../Components/iconComponents";



const tabs = ["All", "Success", "Pending", "Failed"];

function TransactionHistory() {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectN, industry } = location.state || {};
  const loaderArr = [1, 2, 3, 4];
  const [isLoading1, setIsLoading1] = useState(false);
  const [activeTab, setActiveTab] = useState("All");

  const imgFileInputRef = useRef<HTMLInputElement[]>([]);

  // Static data
  const transactions = [
    {
      id: 1,
      amount: "$500",
      payoutDate: "02-12-2024",
      method: "Flick",
      status: "Pending",
      reference: "AD5673991872DE",
    },
    {
      id: 2,
      amount: "$200",
      payoutDate: "02-12-2024",
      method: "Trust",
      status: "Success",
      reference: "AD5673991872DE",
    },
    {
      id: 3,
      amount: "$100",
      payoutDate: "02-12-2024",
      method: "Coinbase",
      status: "Failed",
      reference: "AD5673991872DE",
    },
    {
      id: 4,
      amount: "$100",
      payoutDate: "02-12-2024",
      method: "Rainbow",
      status: "Success",
      reference: "AD5673991872DE",
    },
    {
      id: 5,
      amount: "$200",
      payoutDate: "02-12-2024",
      method: "Metamask",
      status: "Success",
      reference: "AD5673991872DE",
    },
    {
      id: 6,
      amount: "$200",
      payoutDate: "02-12-2024",
      method: "Metamask",
      status: "Success",
      reference: "AD5673991872DE",
    },
    {
      id: 7,
      amount: "$200",
      payoutDate: "02-12-2024",
      method: "Metamask",
      status: "Success",
      reference: "AD5673991872DE",
    },
    {
      id: 8,
      amount: "$200",
      payoutDate: "02-12-2024",
      method: "Metamask",
      status: "Success",
      reference: "AD5673991872DE",
    },
    {
      id: 9,
      amount: "$200",
      payoutDate: "02-12-2024",
      method: "Metamask",
      status: "Success",
      reference: "AD5673991872DE",
    },
    {
      id: 10,
      amount: "$200",
      payoutDate: "02-12-2024",
      method: "Metamask",
      status: "Success",
      reference: "AD5673991872DE",
    },
    {
      id: 11,
      amount: "$200",
      payoutDate: "02-12-2024",
      method: "Metamask",
      status: "Success",
      reference: "AD5673991872DE",
    },
    {
      id: 12,
      amount: "$200",
      payoutDate: "02-12-2024",
      method: "Metamask",
      status: "Success",
      reference: "AD5673991872DE",
    },
  ];

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7); // Number of transactions to show per page

  const filteredTransactions = transactions.filter(
    (transaction) => activeTab === "All" || transaction.status === activeTab
  );

  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = filteredTransactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const nextPage = () => {
    if (currentPage < Math.ceil(filteredTransactions.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <Toast />
      <div className="dash-body">
        <SideNav activeTab="Payout" />
        <div className="content-body ">
          <ProfileNav />
          <div
            className="c-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={chevBack} alt="" />
            <p>Back</p>
          </div>
          <div className="sec-header">
            <p>Transaction history</p>
          </div>

          <div className="notification-tab-container !h-[44px] p-1 -mb-6 bg-[#1d1d1d] rounded-[7px] justify-start items-start gap-3 flex">
            {tabs.map((tab) => (
              <div
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`px-6 py-2 rounded-[7px] justify-center items-center cursor-pointer gap-2.5 flex ${
                  activeTab === tab
                    ? "bg-white text-[#030500]"
                    : "text-[#a5a5a5]"
                }`}
              >
                <div className="text-center text-sm font-normal ">{tab}</div>
              </div>
            ))}
          </div>

          <div className="lp-cont">
            <div className="lp-content-holder">
              {isLoading1 ? (
                <table className="table">
                  <thead className="th1">
                    <tr>
                      <th>Amount</th>
                      <th>Payout date</th>
                      <th>Method</th>
                      <th style={{ paddingLeft: 10 }}>Status</th>
                      <th style={{ paddingLeft: 10 }}>Reference</th>
                    </tr>
                  </thead>
                  <tbody className="tBody">
                    {loaderArr.map((item: any, i: number) => (
                      <tr key={i}>
                        <td>
                          <div className="tr">
                            <div className="img-load"></div>
                            <div className="tr-text">
                              <div className="t-load"></div>
                              <div className="in-load"></div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            <div className={`f-item-load`}></div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            <div className="d-load"></div>
                          </div>
                        </td>
                        <td className="funding-goal">
                          <div className="tr1" style={{ paddingLeft: 10 }}>
                            <div className="d-load"></div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1" style={{ paddingLeft: 10 }}>
                            <div
                              className="prog-bar-cont"
                              style={{ color: "#616161" }}
                            >
                              <div className="prog-bar">
                                <div
                                  className="bar"
                                  style={{
                                    width: "25%",
                                    backgroundColor: "#838383",
                                  }}
                                ></div>
                              </div>
                              <div className="prog-load"></div>
                            </div>
                          </div>
                        </td>
                        <td className="ddLine">
                          <div className="tr-last">
                            <div className="d-load"></div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : filteredTransactions.length === 0 ? (
                <div className="empty-wrap">
                  <img src={emptyList} alt="" />
                  <p>No project available</p>
                  <span>List of projects will appear here</span>
                </div>
              ) : (
                <FadeInAnimation delay={0.5} duration={1}>
                  <table className="table">
                    <thead className="th1">
                      <tr>
                        <th>Amount</th>
                        <th style={{ paddingLeft: 70 }}>Payout date</th>
                        <th>Method</th>
                        <th style={{ paddingLeft: 30 }}>Status</th>
                        <th style={{ paddingLeft: 30 }}>Reference</th>
                      </tr>
                    </thead>
                    <tbody className="tBody">
                      {currentTransactions.map((item: any) => (
                        <tr key={item.id}>
                          <td>
                            <div className="tr">
                              <div className="tr-text">
                                <p>{item.amount}</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="tr1" style={{ paddingLeft: 70 }}>
                              {item.payoutDate}
                            </div>
                          </td>
                          <td>
                            <div className="tr1">{item.method}</div>
                          </td>
                          <td className="funding-goal">
                            <div className="tr1" style={{ paddingLeft: 30 }}>
                              <div
                                className={`f-item capitalize ${
                                  item.status.includes("Failed")
                                    ? "f-item-error"
                                    : item.status.includes("Pending")
                                    ? "f-item-prog"
                                    : item.status.includes("Success")
                                    ? "f-item-suc"
                                    : ""
                                }`}
                              >
                                {item.status}
                              </div>
                            </div>
                          </td>
                          <td className="!rounded-r-[12px]">
                            <div className="tr1 !rounded-r-[12px]" style={{ paddingLeft: 30 }}>
                              {item.reference}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </FadeInAnimation>
              )}
            </div>

          </div>
            {/* Pagination UI */}
            <div className="!w-full h-[68px] px-6 mx-auto pt-3 pb-4 bg-[#141414] -mt-8 rounded-xl justify-between  items-center inline-flex">
              {/* Previous Button */}
              <div
                className="rounded-lg justify-start items-start flex cursor-pointer"
                onClick={prevPage}
              >
                <div className="px-3.5 py-2 rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#4a4a4a] justify-center items-center gap-2 flex overflow-hidden">
                
                    <PrevArrowIcon />
                 
                  <div className="text-[#a5a5a5] text-sm font-medium leading-tight">
                    Previous
                  </div>
                </div>
              </div>

              {/* Page Numbers */}
              <div className="justify-start items-start gap-0.5 flex">
                {[
                  ...Array(
                    Math.ceil(filteredTransactions.length / itemsPerPage)
                  ),
                ].map((_, index) => {
                  const pageNumber = index + 1;
                  return (
                    <div
                      key={pageNumber}
                      onClick={() => paginate(pageNumber)}
                      className={`w-10 h-10 rounded-lg justify-center items-center flex overflow-hidden cursor-pointer ${
                        currentPage === pageNumber
                          ? "bg-[#030500] text-white"
                          : "bg-[#141414] text-[#616161]"
                      }`}
                    >
                      <div className="w-10 self-stretch p-3 rounded-lg justify-center items-center inline-flex">
                        <div className="text-sm font-medium leading-tight">
                          {pageNumber}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* Next Button */}
              <div
                className="rounded-lg justify-start items-start flex cursor-pointer"
                onClick={nextPage}
              >
                <div className="px-3.5 py-2 bg-white rounded-lg justify-center items-center gap-2 flex overflow-hidden">
                  <div className="text-[#030500] text-sm font-medium font-['Inter'] leading-tight">
                    Next
                  </div>
                  <NextArrowIcon />
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default TransactionHistory;
