import React from 'react';
// import { Rocket, Sparkles } from 'lucide-react';
import logo from "../assets/logo.svg";
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();


  return (
    <footer className="bg-black text-white py-16">
      <div className="container mx-auto px-11 border-gray-800 border-t pt-10">
        {/* Main Footer Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {/* Logo Section */}
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
            <img src={logo} alt="" />
            </div>
          </div>

          {/* Product Links */}
          <div className="space-y-4">
            <h3 className="text-sm font-semibold uppercase">Product</h3>
            <ul className="space-y-2">
              {/* <li><a href="#" className="text-gray-300 hover:text-white transition">Trendx One</a></li> */}
              <li><a onClick={()=>navigate("/pricing")} className=" cursor-pointer text-gray-300 hover:text-white transition">Trendx One</a></li>
              <li><a onClick={()=>navigate("/pricing")} className=" cursor-pointer text-gray-300 hover:text-white transition">Trendx Launch</a></li>
              <li><a href="https://techfactory.xyz/p/y/e/" className=" cursor-pointer text-gray-300 hover:text-white transition">The Factory</a></li>
              <li><a href="https://usestableblocks.com" className=" cursor-pointer text-gray-300 hover:text-white transition">StableBlocks</a></li>
              <li><a onClick={()=>navigate("/pricing")} className=" cursor-pointer text-gray-300 hover:text-white transition">Pricing</a></li>
            </ul>
          </div>

          {/* Company Links */}
          <div className="space-y-4">
            <h3 className="text-sm font-semibold uppercase">Company</h3>
            <ul className="space-y-2">
              <li><a href="#" className="text-gray-300 hover:text-white transition">About us</a></li>
              {/* <li><a href="#" className="text-gray-300 hover:text-white transition">Careers</a></li> */}
            </ul>
          </div>

          {/* Support Links */}
          <div className="space-y-4">
            <h3 className="text-sm font-semibold uppercase">Support & Resources</h3>
            <ul className="space-y-2">
              <li><a href="https://blog.trendx.app" className="text-gray-300 hover:text-white transition">Blog</a></li>
              <li><a href="https://contact.trendx.app/" className="text-gray-300 hover:text-white transition">Contact Us</a></li>
            </ul>
          </div>
        </div>

        {/* Newsletter Section */}
        <div className="border-t border-gray-800 pt-16 mb-16">
          <div className="max-w-2xl">
            <div className="flex items-center space-x-2 mb-4">
              {/* <Rocket className="w-5 h-5 text-gray-300" /> */}
              <p className="text-gray-300">
                Subscribe to receive our latest updates, features,<br/>
                founder guides, and success stories right to your inbox<br/>
              🚀
              </p>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-800 pt-8 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <div className="text-sm text-gray-400">
            © {currentYear} Trendx, LLC. All rights reserved.
          </div>
          <div className="flex space-x-1">
            <a href="https://twitter.com/trendxapp?t=Uuzys2o8udzE7tgTwGIYqQ&s=09" className="text-gray-400 hover:text-white transition">Twitter |</a>
            <a href="https://www.instagram.com/trendx.space/" className="text-gray-400 hover:text-white transition">Instagram |</a>
            <a href="https://www.linkedin.com/company/trendx/mycompany/" className="text-gray-400 hover:text-white transition">LinkedIn </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;