import React, { useEffect, useState } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useNavigate } from "react-router-dom";
import plus from "../../assets/outline_plus.svg";
import ValuesCard from "../../Components/ValuesCard";
import list from "../../assets/platList.svg";
import listCheck from "../../assets/listCheck.svg";
import listPending from "../../assets/listPending.svg";
import listCancel from "../../assets/listCancel.svg";
import FadeInAnimation from "../../Components/FadeIn";
import emptyList from "../../assets/empty-list.svg";
import c1 from "../../assets/c1.png";
import py from "../../assets/py.svg";
import py2 from "../../assets/py2.svg";
import dollar from "../../assets/dollar.svg";
import pfi from "../../assets/pfi.svg";
import c2 from "../../assets/c2.png";
import c3 from "../../assets/c3.png";
import c4 from "../../assets/c4.png";
import c5 from "../../assets/c5.png";
import g1 from "../../assets/g1.png";
import g2 from "../../assets/g2.png";
import g3 from "../../assets/g3.png";
import g4 from "../../assets/g4.png";
import g5 from "../../assets/g5.png";
import {
  GetAllSpotlight,
  GetSpotlightOverview,
} from "../../RequestHandlers/Spotlight";
import { PaginatedItems, Item } from "../../types";
import { log } from "node:console";
import { formatDate } from "../../Components/formatDate";
import { CircleArrowIcon, DownloadIcon } from "../../Components/iconComponents";

interface SpotlightStats {
  totalSpotlights: number;
  inReviewCount: number;
  acceptedCount: number;
  draftCount: number;
}

function PayoutScreen() {
  const [vLoad, setVLoad] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const loaderArr = [1, 2, 3, 4];
  const navigate = useNavigate();
  // Static data
const transactions = [
  {
    id: 1,
    amount: "$500",
    payoutDate: "02-12-2024",
    method: "Flick",
    status: "Pending",
    reference: "AD5673991872DE",
  },
  {
    id: 2,
    amount: "$200",
    payoutDate: "02-12-2024",
    method: "Trust",
    status: "Success",
    reference: "AD5673991872DE",
  },
  {
    id: 3,
    amount: "$100",
    payoutDate: "02-12-2024",
    method: "Coinbase",
    status: "Failed",
    reference: "AD5673991872DE",
  },
  {
    id: 4,
    amount: "$100",
    payoutDate: "02-12-2024",
    method: "Rainbow",
    status: "Success",
    reference: "AD5673991872DE",
  },
  {
    id: 5,
    amount: "$200",
    payoutDate: "02-12-2024",
    method: "Metamask",
    status: "Success",
    reference: "AD5673991872DE",
  },
];



  const capitalizeFirstLetter = (word: string) => {
    return word?.charAt(0)?.toUpperCase() + word.slice(1) || "....";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="dash-body">
      <SideNav activeTab="Payout" />
      <div className="content-body">
        <ProfileNav />
        <div className="section-ph">
          <div className="ph-text-sec">
            <h4>Payouts</h4>
            <p>Withdraw funds seamlessly through Flick or on-chain options.</p>
          </div>
          <div
            className="lnp-btn"
            onClick={() => {
              navigate("/Withdraw");
            }}
          >
            <DownloadIcon /> Withdraw funds
          </div>
        </div>
        <div className="dash-overview !gap-4">
          <div className="num-holders !grow">
            <ValuesCard
              extraClass={"v-cards !h-[135px] "}
              delay={0.5}
              text1="Available funds"
              icon={dollar}
              loading={vLoad}
              bottomComp={
                <>
                  <p className="count-value">$2,610k</p>
                  <p className="text-[10px] flex items-center gap-1">
                    <span className="text-[10px] text-green-600">10%</span>
                    <span>
                      <CircleArrowIcon />
                    </span>
                    <span className="text-[10px] text-[#B3B4B3]">
                      +$200 this week
                    </span>
                  </p>
                </>
              }
            />
          </div>
          <div className="num-holders !grow">
            <ValuesCard
              extraClass={"v-cards !h-[135px]"}
              delay={0.5}
              text1="Total funds raised "
              icon={py}
              loading={vLoad}
              bottomComp={
                <>
                  <p className="count-value">$2,610k</p>
                  <p className="text-[10px] flex items-center gap-1">
                    <span className="text-[10px] text-green-600">10%</span>
                    <span>
                      <CircleArrowIcon />
                    </span>
                    <span className="text-[10px] text-[#B3B4B3]">
                      +$200 this week
                    </span>
                  </p>
                </>
              }
            />
          </div>
          <div className="num-holders !grow">
            <ValuesCard
              extraClass={"v-cards !h-[135px]"}
              delay={0.5}
              text1="Payouts"
              icon={py2}
              loading={vLoad}
              bottomComp={
                <>
                  <p className="count-value mb-3">$0.00</p>
                </>
              }
            />
          </div>
        </div>

        <div className="lp-cont">
          <div className="lp-header">
            <h4>Transaction history</h4>
            <div
              onClick={() => {
                navigate(`/TransactionHistory`);
              }}
              className="text-[#A7FC00] text-sm font-normal cursor-pointer"
            >
              See all
            </div>
          </div>
          <div className="lp-content-holder">
            {isLoading1 ? (
              <>
                <table className="table">
                  <thead className="th1">
                    <tr>
                      <th>Amount</th>
                      <th>Payout date</th>
                      <th>Method</th>
                      <th style={{ paddingLeft: 10 }}>Status</th>
                      <th style={{ paddingLeft: 10 }}>Reference</th>
                    </tr>
                  </thead>
                  <tbody className="tBody">
                    {loaderArr.map((item: any, i: number) => (
                      <tr key={i}>
                        <td>
                          <div className="tr">
                            <div className="img-load"></div>
                            <div className="tr-text">
                              <div className="t-load"></div>
                              <div className="in-load"></div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            <div className={`f-item-load`}></div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            <div className="d-load"></div>
                          </div>
                        </td>
                        <td className="funding-goal">
                          <div className="tr1" style={{ paddingLeft: 10 }}>
                            <div className="d-load"></div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1" style={{ paddingLeft: 10 }}>
                            <div
                              className="prog-bar-cont"
                              style={{ color: "#616161" }}
                            >
                              <div className="prog-bar">
                                <div
                                  className="bar"
                                  style={{
                                    width: "25%",
                                    backgroundColor: "#838383",
                                  }}
                                ></div>
                              </div>
                              <div className="prog-load"></div>
                            </div>
                          </div>
                        </td>
                        <td className="ddLine">
                          <div className="tr-last">
                            <div className="d-load"></div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : transactions?.length === 0 ? (
              <>
                <div className="empty-wrap">
                  <img src={emptyList} alt="" />
                  <p>No project available</p>
                  <span>List of projects will appear here</span>
                </div>
              </>
            ) : (
              <FadeInAnimation delay={0.5} duration={1}>
                <table className="table">
                  <thead className="th1">
                    <tr>
                      <th>Amount</th>
                      <th style={{ paddingLeft: 70 }}>Payout date</th>
                      <th>Method</th>
                      <th style={{ paddingLeft: 30 }}>Status</th>
                      <th style={{ paddingLeft: 30 }}>Reference</th>
                      {/* <th style={{ paddingLeft: 30 }}>Actions</th> */}
                    </tr>
                  </thead>
                  <tbody className="tBody">
                    {transactions?.map((item: any) => (
                      <tr key={item.id}>
                        <td>
                          <div
                            className="tr"
                            style={{ cursor: "pointer" }}
                            // onClick={() => {
                            //   navigate(`/SpotlightDetails`, {
                            //     state: { id: item.id },
                            //   });
                            // }}
                          >
                            <div className="tr-text">
                              <p>{item.amount}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1" style={{ paddingLeft: 70 }}>
                            {item.payoutDate}
                          </div>
                        </td>
                        <td>
                          <div className="tr1">{item.method}</div>
                        </td>
                        <td className="funding-goal">
                          <div className="tr1" style={{ paddingLeft: 30 }}>
                            <div
                              className={`f-item ${
                                item.status.includes("Failed")
                                  ? "f-item-error"
                                  : item.status.includes("Pending")
                                  ? "f-item-prog"
                                  : item.status.includes("Success")
                                  ? "f-item-suc"
                                  : ""
                              }`}
                            >
                              {capitalizeFirstLetter(item.status)}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1" style={{ paddingLeft: 30 }}>
                            {item.reference}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </FadeInAnimation>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PayoutScreen;
