import React, { useEffect, useState } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useNavigate } from "react-router-dom";
import plus from "../../assets/outline_plus.svg";
import ValuesCard from "../../Components/ValuesCard";
import list from "../../assets/platList.svg";
import listCheck from "../../assets/listCheck.svg";
import listPending from "../../assets/listPending.svg";
import listCancel from "../../assets/listCancel.svg";
import FadeInAnimation from "../../Components/FadeIn";
import emptyList from "../../assets/empty-list.svg";
import c1 from "../../assets/c1.png";
import fi from "../../assets/fi.svg";
import dollar from "../../assets/dollar.svg";
import pfi from "../../assets/pfi.svg";
import c2 from "../../assets/c2.png";
import c3 from "../../assets/c3.png";
import c4 from "../../assets/c4.png";
import c5 from "../../assets/c5.png";
import g1 from "../../assets/g1.png";
import g2 from "../../assets/g2.png";
import g3 from "../../assets/g3.png";
import g4 from "../../assets/g4.png";
import g5 from "../../assets/g5.png";
import {
  GetAllSpotlight,
  GetSpotlightOverview,
} from "../../RequestHandlers/Spotlight";
import { PaginatedItems, Item } from "../../types";
import { log } from "node:console";
import { formatDate } from "../../Components/formatDate";

interface SpotlightStats {
  totalSpotlights: number;
  inReviewCount: number;
  acceptedCount: number;
  draftCount: number;
}

function AffiliateScreen() {
  const [vLoad, setVLoad] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const loaderArr = [1, 2, 3, 4];
  const navigate = useNavigate();
  const [spotlightStats, setSpotlightStats] = useState<SpotlightStats>({
    totalSpotlights: 0,
    inReviewCount: 0,
    acceptedCount: 0,
    draftCount: 0,
  });
  const [mainSpotlightData, setMainSpotlightData] = useState<any>([]);
  const [spotlightData, setSpotlightData] = useState<any>([]);
  // Static data
  const staticData = [
    {
      id: 1,
      project: "Cityscape Living",
      category: "Real estate",
      status: "Not set up",
      totalAffiliate: "-",
      fundingRaised: "-",
      commissionDue: "-",
      action: "Create affiliate network",
      unitesAgq: 10,
      img: c1,
      gImg: g1,
    },
    // {
    //   id: 2,
    //   project: "Truffle Skin",
    //   category: "Beauty",
    //   status: "Not set up",
    //   dateListed: "01-01-2024",
    //   fundingGoal: "$30,000",
    //   impression: 20,
    //   saves: 10,
    //   unitesAgq: 10,
    //   img: c2,
    //   gImg: g2,
    // },
    {
      id: 3,
      project: "Mawi",
      category: "Entertainment",
      status: "Not set up",
      totalAffiliate: "-",
      fundingRaised: "-",
      commissionDue: "-",
      action: "Create affiliate network",
      unitesAgq: 10,
      img: c3,
      gImg: g3,
    },
    {
      id: 4,
      project: "Wellness Hub",
      category: "Healthcare",
      status: "Not set up",
      totalAffiliate: "-",
      fundingRaised: "-",
      commissionDue: "-",
      action: "Create affiliate network",
      unitesAgq: 10,
      img: c5,
      gImg: g4,
    },
    {
      id: 5,
      project: "FutureLab Hub",
      category: "Technology",
      status: "Not set up",
      totalAffiliate: "-",
      fundingRaised: "-",
      commissionDue: "-",
      action: "Create affiliate network",
      unitesAgq: 10,
      img: c4,
      gImg: g5,
    },
  ];

  // Set the static data to the spotlightData state
  useEffect(() => {
    setSpotlightData(staticData);
  }, []);

  const capitalizeFirstLetter = (word: string) => {
    return word?.charAt(0)?.toUpperCase() + word.slice(1) || "....";
  };


  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="dash-body">
      <SideNav activeTab="Affiliate program" />
      <div className="content-body">
        <ProfileNav />
        <div className="section-ph">
          <div className="ph-text-sec">
            <h4>Affiliate Program</h4>
            <p>Invite affiliates to help your project raise more funds</p>
          </div>
          {/* <div
            className="lnp-btn"
            onClick={() => {
              navigate("/ListSpotlightPromtScreen");
            }}
          >
            <img src={plus} alt="" /> New spotlight
          </div> */}
        </div>
        <div className="dash-overview">
          <div className="num-holders">
            <ValuesCard
              extraClass={"v-cards !h-[135px]"}
              delay={0.5}
              text1="Funds through affiliates"
              icon={dollar}
              loading={vLoad}
              bottomComp={
                <>
                  <p className="count-value">$0</p>
                </>
              }
            />
          </div>
          <div className="num-holders">
            <ValuesCard
              extraClass={"v-cards !h-[135px]"}
              delay={0.5}
              text1="Commissions due"
              icon={dollar}
              loading={vLoad}
              bottomComp={
                <>
                  <p className="count-value">$0</p>
                </>
              }
            />
          </div>
          <div className="num-holders">
            <ValuesCard
              extraClass={"v-cards !h-[135px]"}
              delay={0.5}
              text1="Active affiliate networks"
              icon={fi}
              loading={vLoad}
              bottomComp={
                <>
                  <p className="count-value">0 networks</p>
                </>
              }
            />
          </div>
          <div className="num-holders">
            <ValuesCard
              extraClass={"v-cards !h-[135px]"}
              delay={0.5}
              text1="Total affiliates"
              icon={pfi}
              loading={vLoad}
              bottomComp={
                <>
                  <p className="count-value">0 members</p>
                </>
              }
            />
          </div>
        </div>

        <div className="lp-cont">
          <div className="lp-header">
            <h4>Listed projects</h4>
          </div>
          <div className="lp-content-holder">
            {isLoading1 ? (
              <>
                <table className="table">
                  <thead className="th1">
                    <tr>
                      <th>Project name</th>
                      <th>Status</th>
                      <th>Total affiliates</th>
                      <th style={{ paddingLeft: 10 }}>Funds raised</th>
                      <th style={{ paddingLeft: 10 }}>Commissions due</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="tBody">
                    {loaderArr.map((item: any, i: number) => (
                      <tr key={i}>
                        <td>
                          <div className="tr">
                            <div className="img-load"></div>
                            <div className="tr-text">
                              <div className="t-load"></div>
                              <div className="in-load"></div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            <div className={`f-item-load`}></div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            <div className="d-load"></div>
                          </div>
                        </td>
                        <td className="funding-goal">
                          <div className="tr1" style={{ paddingLeft: 10 }}>
                            <div className="d-load"></div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1" style={{ paddingLeft: 10 }}>
                            <div
                              className="prog-bar-cont"
                              style={{ color: "#616161" }}
                            >
                              <div className="prog-bar">
                                <div
                                  className="bar"
                                  style={{
                                    width: "25%",
                                    backgroundColor: "#838383",
                                  }}
                                ></div>
                              </div>
                              <div className="prog-load"></div>
                            </div>
                          </div>
                        </td>
                        <td className="ddLine">
                          <div className="tr-last">
                            <div className="d-load"></div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : spotlightData?.length === 0 ? (
              <>
                <div className="empty-wrap">
                  <img src={emptyList} alt="" />
                  <p>No project available</p>
                  <span>List of projects will appear here</span>
                </div>
              </>
            ) : (
              <FadeInAnimation delay={0.5} duration={1}>
                <table className="table">
                  <thead className="th1">
                    <tr>
                      <th>Project name</th>
                      <th>Status</th>
                      <th>Total affiliates</th>
                      <th style={{ paddingLeft: 30 }}>Funds raised</th>
                      <th style={{ paddingLeft: 30 }}>Commissions due</th>
                      <th style={{ paddingLeft: 30 }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="tBody">
                    {spotlightData?.map((item: any) => (
                      <tr key={item.id}>
                        <td>
                          <div
                            className="tr"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate(`/SpotlightDetails`, {
                                state: { id: item.id },
                              });
                            }}
                          >
                            <img src={item.img} alt="" />
                            <div className="tr-text">
                              <p>{item.project}</p>
                              <span>
                                {capitalizeFirstLetter(item?.category)}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            <div
                              className={`f-item ${
                                item.status.includes("Not set up")
                                  ? "f-item-error"
                                  : item.status.includes("in-review")
                                  ? "f-item-prog"
                                  : item.status.includes("draft")
                                  ? "f-item-draft"
                                  : ""
                              }`}
                            >
                              {item?.status?.includes("in-review")
                                ? "In review"
                                : capitalizeFirstLetter(item.status)}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">{item.fundingRaised}</div>
                        </td>
                        <td className="funding-goal">
                          <div className="tr1" style={{ paddingLeft: 30 }}>
                            {item.fundingRaised}
                          </div>
                        </td>
                        <td>
                          <div className="tr1" style={{ paddingLeft: 30 }}>
                            {item.commissionDue}
                          </div>
                        </td>
                        <td className="ddLine">
                          <div className="tr-last" style={{ paddingLeft: 30 }}>
                            <span
                              onClick={() => {
                                navigate(`/CreateAffiliate`, {
                                  state: {
                                    projectN: item.project,
                                    industry: item.category,
                                  },
                                });
                              }}
                              className="text-[13px] py-2 px-[14px] w-[168px] rounded-[12px] border border-[#4A4A4A] cursor-pointer"
                            >
                              {item.action}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </FadeInAnimation>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AffiliateScreen;
