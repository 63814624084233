import React, { CSSProperties, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface TimeInputProps {
  label?: string;
  selectedTime?: Date | null;
  onChange?: (time: Date | null) => void;
  error?: boolean;
  errorText?: string;
  contStyle?: CSSProperties;
  contClass?: string;
  labelStyle?: CSSProperties;
}

export default function TimeInput({
  label = "",
  selectedTime,
  onChange,
  error,
  errorText,
  contStyle,
  contClass,
  labelStyle,
}: TimeInputProps) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div>
      <div
        className={`input-container ${
          isFocused || selectedTime ? "focused" : ""
        } ${error ? "inputError" : ""} ${contClass}`}
        style={contStyle}
      >
        {label && (
          <label
            style={
              isFocused || selectedTime
                ? {
                    color: "#A5A5A5",
                    position: "absolute",
                    zIndex: 100,
                    paddingLeft: 24,
                    top: "20%",
                    fontSize: 14,
                    transition: "all 0.5s",
                  }
                : {
                    color: "#A5A5A5",
                    position: "absolute",
                    zIndex: 100,
                    paddingLeft: 24,
                    top: "30%",
                    fontSize: 16,
                    transition: "all 0.5s",
                  }
            }
          >
            {label}
          </label>
        )}
        <div className="input-wrapper">
          <DatePicker
            selected={selectedTime}
            onChange={(time) => {
              onChange && onChange(time);
              setIsFocused(!!time);
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15} // Adjust intervals as needed (e.g., 15 mins)
            timeCaption="Time"
            dateFormat="h:mm aa" // Formats time as 12-hour with AM/PM
            className="floating-input custom-date-picker"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(!!selectedTime)}
          />
        </div>
      </div>
      {error && <p className="error-text">{errorText}</p>}
    </div>
  );
}
