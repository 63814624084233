import "../../../css/settings/style.css";
import Input from "../../Input";
import trashIcon from "../../../assets/trash.svg";
import plusIcon from "../../../assets/plus.svg";
import { SettingsFormValues } from "../../../Screens/Settings/self-listed/SelfListed";
import { FormikProps } from "formik";
import { useSettingsContext } from "../../../context/SettingsContext";

interface Props<T> {
  formik: FormikProps<T>;
}
const CompanyContact: React.FC<Props<SettingsFormValues>> = ({ formik }) => {
  const values = formik.values;
  const setFieldValue = formik.setFieldValue;
  const error = formik.errors;
  const { typeOfCompanyInfo } = useSettingsContext();
  return (
    <>
      <div className="settings-form-container">
        <div className="title-container">
          <p>Company contact</p>
        </div>
        <div
          style={{
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <div className="settings-form-row">
            <div className="settings-input">
              <Input
                label="Website URL"
                contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                contClass="list-input-cont"
                extraClass="list-input"
                value={values.website}
                onChange={({ target }) => {
                  setFieldValue("website", target.value);
                }}
                error={error.website}
                errorText={error.website}
              />
            </div>
            <div className="settings-input">
              <Input
                label="LinkedIn URL"
                contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                contClass="list-input-cont"
                extraClass="list-input"
                value={values.companyLinkedin}
                onChange={({ target }) => {
                  setFieldValue("companyLinkedin", target.value);
                }}
                error={error.companyLinkedin}
                errorText={error.companyLinkedin}
              />
            </div>
          </div>

          <div className="settings-form-row">
            <div
              style={{
                width: typeOfCompanyInfo === "company-listed" ? "50%" : "100%",
                marginBottom: "16px",
              }}
            >
              <Input
                label="Email Address"
                contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                contClass="list-input-cont"
                extraClass="list-input"
                value={values.name}
                onChange={({ target }) => {
                  setFieldValue("name", target.value);
                }}
                error={error.name}
                errorText={error.name}
              />
            </div>
            {typeOfCompanyInfo === "company-listed" && (
              <div
                style={{
                  width: "50%",
                  marginBottom: "16px",
                }}
              >
                <Input
                  label="Contact Number"
                  value={values.phone}
                  onChange={({ target }) => {
                    setFieldValue("phone", target.value);
                  }}
                  error={error.phone}
                  errorText={error.phone}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyContact;
