import "../../../css/settings/style.css";
import { useCallback, useEffect, useState } from "react";
import Step1 from "./step-1";
import Step2 from "./step-2";
import { useFormik } from "formik";
import {
  GetCompanyProfile,
  UpdateCompanyProfile,
} from "../../../RequestHandlers/Company";
import { settingsSchema } from "../../../libs/schema/settingsSchema";
import Toast, { handleToast } from "../../../Components/Toast";
import { GetIndustries } from "../../../RequestHandlers/Auth";
export interface Portfolio {
  id: number;
  name: string;
  link: string;
}

export interface Achievement {
  id: number;
  name: string;
  link: string;
}

export interface Partnership {
  id: number;
  partnerCompany: string;
  duration: number;
  purpose: string;
}

export interface SupportingDocument {
  id: number;
  name: string;
  link: string;
}

export interface Stakeholder {
  id: number;
  name: string;
  link: string;
  role: string;
}

export interface SettingsFormValues {
  name: string;
  description: string;
  companyRegistration: null | string;
  CEO: string;
  ceoLinkedin: string;
  companySize: string;
  website: string;
  linkedIn: string;
  phone: string;
  industry: string;
  officeLocation: string;
  marketPresence: string;
  mission: string;
  vision: string;
  strategies: string;
  portifolio: Portfolio[];
  achievements: Achievement[];
  partnerShip: Partnership[];
  supportingDocuments: SupportingDocument[];
  stakeholders: Stakeholder[];
  companyLinkedin: string;
}
const filterDataToSettingsFormValues = (apiData: any): SettingsFormValues => {
  const settingsFormFields = [
    "name",
    "description",
    "CEO",
    "ceoLinkedin",
    "companySize",
    "website",
    "linkedIn",
    "phone",
    "officeLocation",
    "marketPresence",
    "mission",
    "vision",
    "strategies",
    "companyLinkedin",
    "portifolio",
    "achievements",
    "supportingDocuments",
    "partnerShip",
    "stakeholders",
    "companyRegistration",
    "industry",
  ];

  // Automatically filter out properties not in SettingsFormValues
  const filteredData: any = {};
  settingsFormFields.forEach((field) => {
    if (apiData.hasOwnProperty(field)) {
      filteredData[field] = apiData[field] || "";
    }
  });

  return filteredData as SettingsFormValues;
};

const SelfListed = () => {
  const [data, setData] = useState<SettingsFormValues>();
  const [industries, setIndustries] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const initialValues: SettingsFormValues = {
    name: data?.name || "",
    description: data?.description || "",
    companyRegistration: data?.companyRegistration || "",
    CEO: data?.CEO || "",
    ceoLinkedin: data?.ceoLinkedin || "",
    companySize: data?.companySize || "",
    website: data?.website || "",
    linkedIn: data?.linkedIn || "",
    phone: data?.phone || "",
    industry: "",
    officeLocation: data?.officeLocation || "",
    marketPresence: data?.marketPresence || "",
    mission: data?.mission || "",
    vision: data?.vision || "",
    strategies: data?.strategies || "",
    portifolio: data?.portifolio || [],
    achievements: data?.achievements || [],
    partnerShip: data?.partnerShip || [],
    supportingDocuments: data?.supportingDocuments || [],
    stakeholders: data?.stakeholders || [],
    companyLinkedin: data?.companyLinkedin || "",
  };

  const updateCompanyProfileHandler = async (data: any) => {
    try {
      setLoading(true);

      const response = await UpdateCompanyProfile(data);
      console.log("update response", response);
      if (response) {
        handleToast("Profile updated", "success");
      }
    } catch (error: any) {
      console.log("Error occured while updating company profile", error);
      handleToast(error?.message || "Network error", "error");
    } finally {
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      console.log("form values", values);
      updateCompanyProfileHandler(values);
    },
    validationSchema: settingsSchema,
  });
  const [step, setStep] = useState(1);

  const getCompanyDetailsHandler = async () => {
    try {
      const res = await GetCompanyProfile();
      console.log("response", res);
      if (res && res.data) {
        const mappedData = filterDataToSettingsFormValues(res.data);
        console.log("mapped data", mappedData);
        setData(mappedData);
        formik.resetForm({ values: mappedData });
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };
  const transformArray = (dataArray: typeof industries) => {
    return dataArray.map((item: any) => ({
      label: item?.name,
      value: item?.id,
    }));
  };
  const getIndustriesHandler = useCallback(async () => {
    const response = await GetIndustries();
    const transformedArray = transformArray(response);
    localStorage.setItem("industries", JSON.stringify(transformedArray));
    setIndustries(transformedArray);
  }, []);

  useEffect(() => {
    getCompanyDetailsHandler();
    getIndustriesHandler();
  }, []);
  useEffect(() => {
    (() => {
      if (industries.length > 0 && formik.values?.industry !== "") {
        const localIndustries: any = localStorage.getItem("industries");
        const savedIndustries = JSON.parse(localIndustries);
        const y = savedIndustries?.filter(
          (item: any) =>
            item?.label === formik.values?.industry ||
            item?.value === formik.values?.industry
        );
        formik.setFieldValue("industry", y?.[0]?.value);
      }
    })();
  }, [industries, formik.values?.industry]);
  const renderSteps = () => {
    switch (step) {
      case 1:
        return <Step1 formik={formik} setStep={setStep} loading={loading} />;
        break;
      case 2:
        return <Step2 formik={formik} setStep={setStep} loading={loading} />;
        break;

      default:
        return <Step1 formik={formik} setStep={setStep} loading={loading} />;
        break;
    }
  };
  return (
    <>
      {renderSteps()}
      <Toast />
    </>
  );
};

export default SelfListed;
