// thius is for storing gthe complianace pages⏬️
export function storeCompliancePath(value: string) {
  localStorage.setItem("comPath", JSON.stringify(value));
}

export function getCompliancePath() {
  // @ts-ignore
  const value = JSON.parse(localStorage.getItem("comPath"));
  return value || "Compliance";
}

export function deleteCompliance() {
  localStorage.removeItem("comPath");
}

/////////////////////////////////////////////////////////////

export function storeComID(value: string) {
    localStorage.setItem("comID", JSON.stringify(value));
  }

  export function getComId() {
    // @ts-ignore
    const value = JSON.parse(localStorage.getItem("comID"));
    return value;
  }