import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { CredentailsContext } from "../context/CredentailContext";
import closeIcon from "../assets/close.svg";

interface PProp {
  cancel?: any;
  logOut?: any;
}

let showLogoutFn: () => void;

export function handleUnpublish() {
  showLogoutFn();
}
function Unpublish() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { setStoredCredentails } = useContext(CredentailsContext);

  const handleLogout = () => {
    // Clear cookies
    Cookies.remove("webCook");

    // Clear context or any other state
    // @ts-ignore
    setStoredCredentails(null);
    navigate("/");
    setShow(false);
  };

  showLogoutFn = () => {
    setShow(true);
  };

  return show ? (
    <div className="logout-pop">
      <div className="logout-card" style={{ height: 215 }}>
        <div className="logout-header">
          <p>Unpublish this project?</p>

          <img
            src={closeIcon}
            alt=""
            onClick={() => {
              setShow(false);
            }}
          />
        </div>
        <div className="logout-content">
          <p className="logout-text">
          The unpublish feature is currently unavailable, so this project will remain visible to investors. You can find it in drafts by using the status filter.
          </p>
          {/* <p className="logout-text">
            Unpublishing will hide this project and make it inaccessible to
            investors until you republish. You’ll find them in drafts using the
            status filter.
          </p> */}
          <div className="logout-action-cont">
            <div
              className="logout-action-btn"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </div>
            {/* <div
              className="logout-action-btn logout-btn"
              style={{
                backgroundColor: "#A7FC00",
                color: "#000",
                width: 92,
                fontWeight: 500
              }}
              onClick={() => {
                handleLogout();
              }}
            >
              Unpublish
            </div> */}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default Unpublish;
