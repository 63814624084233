import { FormikProps } from "formik";
import "../../../css/settings/style.css";
import TextArea from "../../Textera";
import { SettingsFormValues } from "../../../Screens/Settings/self-listed/SelfListed";
interface Props<T> {
  formik: FormikProps<T>;
}
const CompanyProfile: React.FC<Props<SettingsFormValues>> = ({ formik }) => {
  const values = formik.values;
  const errors = formik.errors;
  const setFieldValue = formik.setFieldValue;
  return (
    <>
      <div className="settings-form-container">
        <div className="title-container">
          <p>Company profile</p>
        </div>
        <div
          style={{
            width: "100%",
            marginBottom: 16,
          }}
        >
          <TextArea
            label="Missions"
            extraClass="textarea textarea1"
            value={values.mission}
            onChange={({ target }) => setFieldValue("mission", target.value)}
            error={errors.mission}
            errorText={errors.mission}
          />
        </div>
        <div
          style={{
            width: "100%",
            marginBottom: 16,
          }}
        >
          <TextArea
            label="Vision"
            extraClass="textarea textarea1"
            value={values.vision}
            onChange={({ target }) => setFieldValue("vision", target.value)}
            error={errors.vision}
            errorText={errors.vision}
          />
        </div>
        <div
          style={{
            width: "100%",
            marginBottom: 16,
          }}
        >
          <TextArea
            label="Strategy"
            extraClass="textarea textarea1"
            value={formik.values.strategies}
            onChange={({ target }) => setFieldValue("strategies", target.value)}
            error={errors.strategies}
            errorText={errors.strategies}
          />
        </div>
      </div>
    </>
  );
};

export default CompanyProfile;
