import React from "react";
import uploadImg from "../assets/upload.svg";
import play from "../assets/play.svg";
import img from "../assets/gallery.svg";
import Check from "../assets/circle-check.svg";
import trash from "../assets/trash.svg";

import uploadSuccessImg from "../assets/uploadedImg.svg"
import docIcon from "../assets/doc.svg"
interface PProps {
  topText?: string;
  fileInputRef?: any;
  handleFileSelect?: any;
  type?: string;
  onContainerClick?: any;
  isUploading?: any;
  isCompleted?: boolean;
  progress?: number;
  fileSize?: string;
  del?: any;
  height?: string | number;
  fileName?: string | any;
  accept?: string;
  message?: string;
  isSuccsess?: boolean
}
function ImageVideoUploader({
  topText,
  fileInputRef,
  handleFileSelect,
  type,
  onContainerClick,
  isUploading,
  fileName,
  isCompleted,
  progress,
  accept,
  fileSize,
  del,
}: PProps) {
  return (
    <div
      style={{
        width: "100%",
        height: isUploading ? 84 : isCompleted ? 64 : 169,
        backgroundColor: "#0D0D0D",
        borderRadius: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        position: "relative",
        marginBottom: 16,
        transition: "all 0.5s ease",
      }}
      onClick={onContainerClick}
    >
      {isUploading || fileName ? (
        <>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              padding: 15,
              alignItems: "flex-start",
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            <img
              src={type === "image" ? img : play}
              alt=""
              style={{ marginLeft: 10 }}
            />
            <div style={{ marginLeft: 20 }}>
              <p style={{ fontSize: 12, color: "#fff" }}>{fileName}</p>
              <div style={{ display: "flex", marginTop: 7, gap: 10 }}>
                {" "}
                <p
                  style={{
                    color: isCompleted ? "#9B9B9B" : "#616161",
                    fontSize: 8,
                    fontWeight: "500",
                  }}
                  className="f-nunito"
                >
                  {fileSize}
                </p>{" "}
                <p style={{ fontSize: 12, color: "#616161", marginTop: -5 }}>
                  .
                </p>
                {isCompleted ? (
                  <>
                    <img src={Check} alt="" />
                  </>
                ) : (
                  <></>
                )}
                <p
                  className="f-nunito"
                  style={{ fontSize: 8, color: "#B3B4B3" }}
                >
                  {isCompleted ? "Completed" : "Uploading..."}
                </p>
              </div>
            </div>
            {isCompleted && (
              <button
                style={{
                  background: "none",
                  border: "none",
                  position: "absolute",
                  right: 15,
                  top: 20,
                  cursor: "pointer",
                }}
                onClick={del}
              >
                <img width={14} height={14} src={trash} alt="" />
              </button>
            )}
            {isCompleted ? (
              <></>
            ) : (
              <div
                style={{
                  width: "70%",
                  height: "5px",
                  backgroundColor: "#D9D9D9",
                  borderRadius: "5px",
                  overflow: "hidden",
                  position: "absolute",
                  bottom: 15,
                  marginLeft: 10,
                }}
              >
                <div
                  style={{
                    width: `${progress}%`,
                    height: "100%",
                    backgroundColor: "#A7FC00",
                    transition: "width 0.3s ease",
                  }}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <p
            style={{
              position: "absolute",
              top: 16,
              left: 20,
              fontSize: 16,
              color: "#A5A5A5",
              fontWeight: 500,
            }}
          >
            {topText}
          </p>
          <img src={uploadImg} alt="" />
          <p style={{ fontSize: 12, color: "#fff", marginTop: 10 }}>
            Upload {type === "image" ? "Image" : "Video"}
          </p>
          <span style={{ color: "#838383", fontSize: 12 }}>
            (375*150 recommended)
          </span>
        </>
      )}
      <input
        type="file"
        accept={accept}
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelect}
      />
    </div>
  );
}

export default ImageVideoUploader;
