import { RequestHandler } from "./Request";
import Cookies from "js-cookie";

const request = new RequestHandler();

export function GetVoucherOverview(): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get("tbs/owner/voucher/overview", token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function GetVoucherHolder(
  page: number,
  limit: number,
  search?: string,
  voucherId?: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      const url = search
        ? `tbs/owner/voucher/holders?page=${page}&limit=${limit}&search=${search}`
        : `tbs/owner/voucher/holders?page=${page}&limit=${limit}`;
      request.get(url, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function GetHolderById(
  page: number,
  limit: number,
  id: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      const url = `tbs/owner/voucher/holders?voucherId=${id}`;
      request.get(url, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function GetVoucherTransaction(
  id: string,
  page: number,
  limit: number
): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      const url = `tbs/voucher/transactions/${id}?page=${page}&limit=${limit}`;
      request.get(url, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function GetVouchers(
  page: number,
  limit: number,
  status?: string,
  search?: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      let url = `tbs/owner/voucher/list?page=${page}&limit=${limit}`;
      if (status) {
        url += `&status=${status}`;
      }
      if (search) {
        url += `&search=${search}`;
      }
      request.get(url, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}

export function GetCashBackRequest(
  page: number,
  limit: number,
  search?: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      let url = `tbs/owner/vouchers/pending-withdrawals?page=${page}&limit=${limit}`;
      if (search) {
        url += `&search=${search}`;
      }
      request.get(url, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function GetCashBackByID(
  page: number,
  limit: number,
  id: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      const url = `tbs/owner/voucher/withdrawals?page=${page}&limit=${limit}&voucherId=${id}`;
      request.get(url, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function GetVouchersByID(id: string): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(`tbs/owner/voucher/${id}`, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function GetVoucherSaleChart(
  id: string,
  duration: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(
        `tbs/owner/voucher/sales-chart/${id}?days=${duration}`,
        token,
        [
          (data: any) => {
            resolve(data);
          },
        ]
      );
    } else {
      console.log("null");
    }
  });
}
export function DeleteVoucherByID(id: string): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.delete(`tbs/owner/voucher/${id}`, {}, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function CreateVoucher(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.post(`investment/owner/create-brand-voucher`, body, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function ReportIssue(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.post(`tbs/owner/voucher/report-issue`, body, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function DeleteVouchers(id: any): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.delete(`tbs/owner/voucher/${id}`, {}, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function UpdateVoucherStatus(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.put(`tbs/owner/voucher/update`, body, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function AddUpdate(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.post(`tbs/owner/voucher/debit`, body, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
