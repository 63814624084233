import React, { useEffect, useState } from "react";
import FadeInAnimation from "./FadeIn";
import Logo1 from "../assets/newLogo.svg";
import { ReactComponent as DashIcon } from "../assets/dashboard.svg";
import { ReactComponent as Project } from "../assets/searching.svg";
import { ReactComponent as Compliance } from "../assets/compliance.svg";
import { ReactComponent as Voucher } from "../assets/solar_tag-linear.svg";
import { ReactComponent as Analytic } from "../assets/analytics.svg";
import { ReactComponent as Spotlight } from "../assets/ph_star.svg";
import { ReactComponent as Reward } from "../assets/reward.svg";
import { ReactComponent as Aff } from "../assets/ppl.svg";
import { ReactComponent as Payout } from "../assets/paper.svg";
import { ReactComponent as Refferal } from "../assets/briefcase.svg";
import { ReactComponent as Settings } from "../assets/settings-linear.svg";
import { ReactComponent as Support } from "../assets/customer-care.svg";
import { ReactComponent as LogoutIcon } from "../assets/logout.svg";
import { useNavigate } from "react-router-dom";
import Logout, { handleLogout } from "./Logout";
import { getComId, getCompliancePath, storeComID } from "../utils/Localstorage";
import { GetUserDetails } from "../RequestHandlers/User";
interface PProps {
  activeTab: string;
}
function SideNav({ activeTab }: PProps) {
  const [expandSideBar, setExpandSideBar] = useState(false);
  const [DAO, setDAO] = useState([]);

  const navigate = useNavigate();
  const compliancePath = getCompliancePath();
  console.log(compliancePath, "fghjk");

  const getDao = (email: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email,
    });

    fetch("https://trendx.app/api/getDAO.php", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status == "success") {
          setDAO(result.data);
          !getComId() && storeComID(result.data[0].id);
        }
      })
      .catch((error) => console.error(error));
  };

  const getUserDetails = async () => {
    try {
      const res = await GetUserDetails();
      if (res.avatar) {
        getDao(res.contactEmail);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const links = [
    {
      name: "Dashboard",
      delay: 0.4,
      icon: DashIcon,
      screen: "/Dashboard",
    },
    {
      name: "Dao",
      delay: 0.6,
      icon: Compliance,
      screen: "/" + (  DAO.length == 0 ? compliancePath ?? "Dao" : "DaoDahsboard"),
    },
    { name: "Projects", delay: 0.6, icon: Project, screen: "/Project" },
    // { name: "Compliance", delay: 0.6, icon: Compliance, screen: "/Compliance" },
    { name: "Vouchers", delay: 0.8, icon: Voucher, screen: "/Voucher" },
    // { name: "Analytics", delay: 1, icon: Analytic, screen: "/Project" },
    { name: "Spotlight", delay: 1.2, icon: Spotlight, screen: "/Spotlight" },
    // { name: "Rewards", delay: 1.4, icon: Reward, screen: "/Reward" },
    // { name: "Affiliate program", delay: 1.6, icon: Aff, screen: "/Affiliate" },
    // { name: "Payout", delay: 1.8, icon: Payout, screen: "/Payout" },
    // { name: "Referral", delay: 2, icon: Refferal, screen: "/Project" },
    { name: "Settings", delay: 2.2, icon: Settings, screen: "/SettingsScreen" },
    { name: "Support", delay: 2.2, icon: Support, screen: "/Support" },
    { name: "Logout", delay: 2.2, icon: LogoutIcon, screen: "" },
  ];
  return (
    <>
      <div className={`side-nav1 ${expandSideBar ? "side-expand" : ""}`}></div>
      <div className={`side-nav ${expandSideBar ? "side-expand" : ""}`}>
        <FadeInAnimation delay={0.2} duration={1}>
          <div className="logo-wrap">
            <img src={Logo1} alt="" />
            {/* <p>TBS</p> */}
          </div>
        </FadeInAnimation>
        <div className="side-link-list">
          {links.map((item, index) => (
            <FadeInAnimation delay={item.delay} duration={1} key={index}>
              <div
                className={`side-link ${
                  activeTab === item.name ? "active" : ""
                }`}
                style={{
                  marginBottom:
                    index === 9 ? 48 : index === links.length - 1 ? 100 : 10,
                }}
                onClick={() => {
                  if (item.name === "Logout") {
                    handleLogout();
                    console.log("logout");
                  } else {
                    navigate(item.screen);
                  }
                }}
              >
                <div className="overlay"></div>
                <item.icon
                  className="side-img"
                  style={
                    item.icon === Project ||
                    item.icon === Analytic ||
                    item.icon === Spotlight ||
                    item.icon === Reward ||
                    item.icon === Aff ||
                    item.icon === LogoutIcon ||
                    item.icon === Compliance
                      ? { fill: "currentColor" }
                      : item.icon === Payout
                      ? { stroke: "currentColor" }
                      : { stroke: "currentColor" }
                  }
                />
                <p>{item.name}</p>
              </div>
            </FadeInAnimation>
          ))}
        </div>
        {/* <button
        onClick={() => {
          setExpandSideBar(!expandSideBar);
        }}
      >
        reduce
      </button> */}
      </div>
      <Logout />
    </>
  );
}

export default SideNav;
