import { RequestHandler } from "./Request";
import Cookies from "js-cookie";

const request = new RequestHandler();
export function GetCollection(page: number, limit: number): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(
        `tbs/owner/get-collections?page=${page}&limit=${limit}`,
        token,
        [
          (data: any) => {
            resolve(data);
          },
        ]
      );
    } else {
      console.log("null");
    }
  });
}
export function GetCollectionById(id: number): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(`tbs/collection/get/${id}`, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function GetBlogByCollection(
  page: number,
  limit: number,
  id: number
): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(
        `tbs/collection/blog/get?page=${page}&limit=${limit}&collectionId=${id}`,
        token,
        [
          (data: any) => {
            resolve(data);
          },
        ]
      );
    } else {
      console.log("null");
    }
  });
}
export function GetCollectionOverview(id: number): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(`tbs/collection/overview/${id}`, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function GetCollectionTransaction(
  page: number,
  limit: number,
  status: string,
  id: number
): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(
        `tbs/transaction/get?page=${page}&limit=${limit}&status=${status}&collectionId=${id}`,
        token,
        [
          (data: any) => {
            resolve(data);
          },
        ]
      );
    } else {
      console.log("null");
    }
  });
}
export function GetCollectionFundChart(
  id: number,
  duration: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(
        `tbs/funds-chart?collectionId=${id}&days=${duration}`,
        token,
        [
          (data: any) => {
            resolve(data);
          },
        ]
      );
    } else {
      console.log("null");
    }
  });
}
export function AddCollectionBlog(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.post(`tbs/collection/blog/add`, body, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function GetCollectionTotalFund(id: number): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(`tbs/total-funds?collectionId=${id}`, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}

export function GetProjects(
  page: number,
  limit: number,
  status?: string,
  search?: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(
        `tbs/owner/get-collections?page=${page}&limit=${limit}&search=${search}`,
        token,
        [
          (data: any) => {
            resolve(data);
          },
        ]
      );
    } else {
      console.log("null");
    }
  });
}
export function GetProjectsToSpotlight(
  page: number,
  limit: number,
  status?: string,
): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(
        `tbs/owner/get-collections?page=${page}&limit=${limit}&status=${status}`,
        token,
        [
          (data: any) => {
            resolve(data);
          },
        ]
      );
    } else {
      console.log("null");
    }
  });
}
export function CreateProject(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.post(`investment/owner/create-collection`, body, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
