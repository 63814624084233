import Cookies from "js-cookie";
import { RequestHandler } from "./Request";

const request = new RequestHandler();
export function SendBatchEmails(body: any): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.post(`tbs/email/batch-email`, body, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
