import React, { useState, useRef, useEffect } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useNavigate } from "react-router-dom";
import { GetUserDetails } from "../../RequestHandlers/User";
import { storeComID, storeCompliancePath } from "../../utils/Localstorage";

function Compliance() {
  const navigate = useNavigate();
  const [showDelMenu, setShowDelMenu] = useState(null);
  const [vName, setVName] = useState("");
  const [description, setDescription] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [details, setdetails] = useState({ contactEmail: "" });
  const [DAO, setDAO] = useState([]);
  const [DAOLoading, setDAOLoading] = useState(true);

  function capitalizeFirstLetter(word: string): string {
    if (!word) return ""; // Handle empty or null strings
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  const getDao = (email: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email,
    });

    fetch("https://trendx.app/api/getDAO.php", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setDAOLoading(false);
        if (result.status == "success") {
          setDAO(result.data);
        }
      })
      .catch((error) => console.error(error));
  };

  const getUserDetails = async () => {
    try {
      const res = await GetUserDetails();
      if (res.avatar) {
        setdetails(res);
        getDao(res.contactEmail);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    storeCompliancePath("Dao")
    getUserDetails();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="dash-body">
        <SideNav activeTab="Compliance" />
        <div className="content-body">
          <ProfileNav />
          <div className="section-ph">
            <div className="ph-text-sec">
              <h4>Compliance</h4>
              <p>Set-up and manage DAO</p>
            </div>
            <div className="relative">
              <div
                className="lnp-btn"
                onClick={() => {
                  setShowPopup(!showPopup);
                }}
              >
                Manage DAO
              </div>
              {showPopup && (
                <div className="absolute top-14 left-2 -translate-x-1/2 w-[250px] p-4 bg-[#222] text-white rounded-lg shadow-lg">
                  {DAOLoading ? (
                    <div className="dot-loader">
                      <span className="dot2"></span>
                      <span className="dot2"></span>
                      <span className="dot2"></span>
                    </div>
                  ) : DAO.length !== 0 ? (
                    <div>
                      {DAO.map((items: any) => (
                        <div
                          className="flex gap-1 hover:bg-[#444] w-full h-[30px] items-center cursor-pointer"
                          onClick={() =>{
                            navigate("/DaoDahsboard")
                            storeComID(items.id)
                          }
                          }
                        >
                          <p className="text-[#fff] text-xs ml-2">
                            {items.preferred_name_1}
                          </p>
                          <p className="text-[#fff] text-xs font-bold">DAO</p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-gray-400 italic">No DAO found</p>
                  )}
                  <button
                    className="mt-4 w-full bg-lime-400 text-black font-semibold px-4 py-2 rounded-md"
                    onClick={() => navigate("/CreateDao")}
                  >
                    Register a new DAO
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="v-holder ">
            <div className="v-head pl-[2%]">
              <h4 className="v-head-text">😎 Get Started</h4>
            </div>
            <p style={{ color: "#ddd" }} className="mt-5 pl-[2%]">
              Incorporate DAO and tokenize asset to unlock <br />
              liquidity options.
            </p>
            <div
              style={{ transition: "0.5s" }}
              className=" ml-[2%] w-[150px] bg-[#fff] h-[50px] flex items-center justify-center rounded-[20px] mt-5 cursor-pointer hover:bg-[#ddd]"
              onClick={() => {
                navigate("/CreateDao");
              }}
            >
              Start DAO
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Compliance;
