import React, { useEffect, useState } from "react";
import city from "../../assets/living.png";
import LineChartSingle from "../../Components/LineChartSingle";
import pdf from "../../assets/pdf.svg";
import arrowOutward from "../../assets/arrowOutward.svg";
import img from "../../assets/gallery.svg";
import {
  GetBlogByCollection,
  GetCollectionById,
} from "../../RequestHandlers/Collection";
import { formatDate2 } from "../../Components/formatDate";
import { log } from "node:console";

interface PProps {
  id: number;
}
function ProjectDetails({ id }: PProps) {
  const [prStatus, setPrStatus] = useState("progress");
  const [ProjectDetails, setProjectDetails] = useState<any>([]);
  const [updates, setUpdates] = useState<any>([]);
  const [supDoc, setSupDoc] = useState<any>([]);
  const [riskFactors, setRiskFactors] = useState<any>(null);
  const [mStrats, setMStrats] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [roi, setRoi] = useState<any>([]);

  const getCollectbyId = async () => {
    setLoading(true);
    try {
      const res = await GetCollectionById(id);
      console.log(res);

      if (res.id) {
        setProjectDetails(res);
        setPrStatus(res.status);
        setSupDoc(res.suppoertingDocuments);
        setRiskFactors(res.riskFactors);
        setMStrats(res.mitigationStrategies);
        setRoi(res.returnOfInvestmentDuration);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const extractFileName = (url: string): string => {
    // Use a regular expression to match the file name at the end of the URL
    const match = url.match(/\/([^/]+)\.(jpg|jpeg|png|gif|webp)$/i);
    // If a match is found, return the file name (without the extension)
    return match ? match[1] : "File name not found";
  };
  const getBlogbycollection = async () => {
    try {
      const res = await GetBlogByCollection(1, 10, id);
      console.log(id);

      console.log("updates", res.items);
      setUpdates(res.items);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getCollectbyId();
      getBlogbycollection();
    }
  }, []);
  return (
    <div className="spotlight-details">
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="dot-loader">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
        </div>
      ) : (
        <>
          <div className="details-wrap1">
            <h4 className="dtl-h4">About project</h4>
            <p className="dtl-p">{ProjectDetails.Description}</p>
            <div className="dtl-overview">
              <h4 className="dtl-h4">Project overview</h4>
              <div className="dtl-ovo">
                <div className="dtl-item">
                  <p className="dtl-p">Industry</p>
                  <h5 className="dtl-p" style={{ color: "#fff" }}>
                    {ProjectDetails.industry}
                  </h5>
                </div>
                <div className="dtl-item">
                  <p className="dtl-p">Funding goal</p>
                  <h5 className="dtl-p" style={{ color: "#fff" }}>
                    ${ProjectDetails?.fundingGoal?.toLocaleString()}
                  </h5>
                </div>
                <div className="dtl-item">
                  <p className="dtl-p">Funding round</p>
                  <h5 className="dtl-p" style={{ color: "#fff" }}>
                    {ProjectDetails.fundingRound}
                  </h5>
                </div>
                <div className="dtl-item">
                  <p className="dtl-p">Avalable Units</p>
                  <h5 className="dtl-p" style={{ color: "#fff" }}>
                    {ProjectDetails?.nftMintLimit}
                  </h5>
                </div>
                <div className="dtl-item">
                  <p className="dtl-p">Unit price</p>
                  <h5 className="dtl-p" style={{ color: "#fff" }}>
                    ${ProjectDetails?.nftPrice?.toLocaleString()}
                  </h5>
                </div>
                <div
                  className="dtl-item"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <p className="dtl-p">Status</p>
                  <div
                    className={`f-item ${
                      prStatus.includes("Failed")
                        ? "f-item-error"
                        : prStatus.includes("progress")
                        ? "f-item-prog"
                        : prStatus.includes("Draft")
                        ? "f-item-draft"
                        : ""
                    }`}
                    style={{ width: "auto", marginTop: 6 }}
                  >
                    {ProjectDetails?.status == "active"? "Published" : ProjectDetails?.status }
                  </div>
                </div>
              </div>
            </div>
            <h4 className="dtl-h4" style={{ marginTop: 40 }}>
              Use of funds
            </h4>
            <h5 className="dtl-p" style={{ color: "#B3B4B3" }}>
              {ProjectDetails.useOfFunds}
            </h5>
            <h4 className="dtl-h4" style={{ marginTop: 40 }}>
              Industry competitors
            </h4>
            <h5 className="dtl-p" style={{ color: "#B3B4B3" }}>
              {ProjectDetails.competitors}
            </h5>
            <h4 className="dtl-h4" style={{ marginTop: 40 }}>
              Competitive advantage
            </h4>
            <h5 className="dtl-p" style={{ color: "#B3B4B3" }}>
              {ProjectDetails.competitiveAdvantages}
            </h5>

            {updates.length === 0 ? (
              <></>
            ) : (
              <div className="dtl-overview">
                <h4 className="dtl-h4">Updates</h4>
                {updates.map((item: any, index: number) => {
                  console.log("ibibibb", item);

                  return (
                    <>
                      <div className="dtl-ovo" style={{ marginBottom: 20 }}>
                        <div className="dtl-item" style={{ width: "100%" }}>
                          <p className="dtl-p">Update Title</p>
                          <h5 className="dtl-p" style={{ color: "#fff" }}>
                            {item?.title}
                          </h5>
                        </div>
                        <div className="dtl-item" style={{ width: "50%" }}>
                          <p className="dtl-p">Update Title</p>
                          <h5 className="dtl-p" style={{ color: "#fff" }}>
                            {item?.updateType}
                          </h5>
                        </div>
                        <div className="dtl-item" style={{ width: "50%" }}>
                          <p className="dtl-p">Date</p>
                          <h5 className="dtl-p" style={{ color: "#fff" }}>
                            {formatDate2(item?.created_at)}
                          </h5>
                        </div>
                        <div className="dtl-item" style={{ width: "100%" }}>
                          <p className="dtl-p">Description</p>
                          <h5 className="dtl-p" style={{ color: "#fff" }}>
                            {item.body}
                          </h5>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            )}
          </div>
          <div className="details-wrap2">
            {roi.length === 0 ? (
              <></>
            ) : (
              <>
                <h4 className="dtl-h4">Projected ROI</h4>
                <div
                  className="l-chart--hold"
                  style={{ borderRadius: 10, marginTop: 12 }}
                >
                  <LineChartSingle roiData={roi} />
                </div>
              </>
            )}

            {supDoc.length === 0 ? (
              <></>
            ) : (
              <>
                <h4 className="dtl-h4" style={{ marginTop: 40 }}>
                  Supporting Document
                </h4>
                <div
                  className="l-chart--hold"
                  style={{
                    borderRadius: 10,
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "transparent",
                  }}
                >
                  {supDoc.map((item: any, index: any) => {
                    return (
                      <a href={item} target="_blank" className="ex-link">
                        <button>
                          <div>
                            <img
                              src={item.includes(".pdf") ? pdf : img}
                              alt=""
                            />{" "}
                            {extractFileName(item)}
                          </div>
                          <img src={arrowOutward} alt="" />
                        </button>
                      </a>
                    );
                  })}
                </div>
              </>
            )}

            {riskFactors === null && mStrats === null ? (
              <></>
            ) : (
              <>
                <h4 className="dtl-h4" style={{ marginTop: 40 }}>
                  Risk assessment
                </h4>
                {riskFactors === null ? (
                  <></>
                ) : (
                  <div className="risk-facts mb-[16px]">
                    <h5 className="dtl-p" style={{ color: "#B3B4B3" }}>
                      Risk factor
                    </h5>
                    {riskFactors.map((item: any, index: number) => {
                      return (
                        <h5 className="dtl-p" style={{ color: "#F2F3F2" }}>
                          {index + 1}. 
                          {item}
                        </h5>
                      );
                    })}
                  </div>
                )}
                {mStrats === null ? (
                  <></>
                ) : (
                  <div className="risk-facts">
                    <h5 className="dtl-p" style={{ color: "#B3B4B3" }}>
                      Mitigation Strategies
                    </h5>
                    {mStrats.map((item: any, index: number) => {
                      return (
                        <h5 className="dtl-p" style={{ color: "#F2F3F2" }}>
                          {index + 1}. 
                          {item}
                        </h5>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ProjectDetails;
