import React, { useEffect, useRef, useState } from "react";
import "../css/style.css";
import "../css/responsive.css";
import "../css/font.css";
import NavBar from "../Components/NavBar";
import FadeInAnimation from "../Components/FadeIn";
import TyperAnimation from "../Components/TyperAnimation";
import PrimaryBtn from "../Components/PrimaryBtn";
import AnalyticImg from "../assets/AnalyticsImg.webp";
import DashboardImg from "../assets/DashboardImg.webp";
import { useNavigate } from "react-router-dom";
// import flick from "../assets/partners/flick.svg";
// import stb from "../assets/partners/stb.svg";
import { motion } from "framer-motion";
import Footer from "../Components/FooterNew";
import { useMediaQuery } from "react-responsive";
import lisk from "../assets/partners/lisk.png";
import stb from "../assets/partners/stableblocks.png";
import tradlander from "../assets/partners/tradelenda.png";
import cv from "../assets/partners/cv labs.png";
import flick from "../assets/partners/flick.png";
import afirk from "../assets/partners/afriskaut.png";
import mBanner from "../assets/mBanner.png";
import d1 from "../assets/1d.png";
import d2 from "../assets/2d.png";
import d3 from "../assets/3d.png";
import d4 from "../assets/4d.png";
import trustpilot from "../assets/trustpilot.png";
import star from "../assets/star.png";
import b1 from "../assets/b1.png";
import b2 from "../assets/b2.png";
import circleDesgin from "../assets/circleDesgin.png";
import HeaderHelmet from "../Components/HeaderHelmet";

export default function PricingScreen() {
  const images = [DashboardImg, AnalyticImg];
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 567 });
  const typerText = [
    "List your projects on StableBlocks.",
    "Track your projects' success.",
    "Get tools for visibility and funding.",
  ];
  const [currentImage, setCurrentImage] = useState(images[0]);
  const [fadeKey, setFadeKey] = useState(0);
  const [move, setMove] = useState("0");

  useEffect(() => {
    // Switch images every 5 seconds
    const interval = setInterval(() => {
      setFadeKey((prevKey) => prevKey + 1);
      setCurrentImage((prevImage) => {
        const nextIndex = (images.indexOf(prevImage) + 1) % images.length;
        return images[nextIndex];
      });
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setMove("50%");
    setTimeout(() => {}, 5000);
  }, []);
  // const partners = [lisk, kai, moni, flick, afirk, tradlander, stb, cv];
  const partners = [lisk, tradlander, flick, afirk, stb, cv];

  const partnersImg = [
    <img src={lisk} alt="" className={`prt-img`} />,
    <img src={tradlander} alt="" className={`prt-img`} />,
    <img src={flick} alt="" className={`prt-img`} />,
    <img src={afirk} alt="" className={`prt-img`} />,
    <img src={stb} alt="" className={`prt-img2`} />,
    <img src={cv} alt="" className={`prt-img3`} />,
  ];
  const InfiniteScroll = () => {
    return (
      <div className="partners-container">
        {/* <div className="partners mt-[40px] w-[100%] flex justify-center items-center gap-[40px]"> */}
        <div className="partners mt-[40px] w-[100%] flex  items-center gap-[40px]">
          {[...partnersImg, ...partnersImg, ...partnersImg, ...partnersImg].map(
            (item, index) => item
          )}
        </div>
      </div>
    );
  };

  function PaymentBox({ img, title, price, subtitle }: any) {
    return (
      <div className="w-[45%] h-[170px] ">
        <img src={img} alt="" style={{ height: 60 }} />
        <div className="flex align-center gap-3 mt-2 mb-3">
          <h3 className=" font-[OpenSauceSans] text-[10px] md:text-lg font-semibold text-white ">
            {title}
          </h3>
          <h3 className="font-[OpenSauceSans] text-[10px] md:text-lg font-medium text-[#B8F46C] line-through ">
            {price}
          </h3>
        </div>
        <h3 className=" font-[OpenSauceSans] text-[10px] md:text-sm font-extrabold text-[#999]">
          {subtitle}
        </h3>
      </div>
    );
  }

  function PricingCard({
    title,
    price,
    features,
    highlight = false,
    style,
    subtitle,
    subtext,
  }: any) {
    return (
      <div className={`rounded-xl p-8 ${"bg-[#090E01]"} ${style}`}>
        <div className="h-20 w-20 rounded-full bg-green-500/20 flex items-center justify-center mb-6">
          <img src={b1} alt="" />
        </div>
        <h3 className=" font-[OpenSauceSans] text-xl font-bold text-white mb-2">{title}</h3>
        <h3 className=" font-[OpenSauceSans] text-sm font-semibold text-[#aaa] mb-10">{subtitle}</h3>
        <div className="mb-8">
          <span className="text-6xl font-bold text-white font-[OpenSauceSans]">${price}</span>
          {/* <span className="text-gray-400 ml-2">/mo</span> */}
          <h3 className=" font-[OpenSauceSans] text-sm font-semibold text-[#aaa] mb-2 mt-5">{subtext}</h3>
        </div>
        <button
          className="w-[50%] md:w-[50%] py-2 px-6 rounded-[sm] bg-[white] hover:bg-[#eee] text-bold text-[16px] font-medium transition-colors mb-8"
          onClick={() => navigate("/tokenizewithus")}
        >
          Launch your DAO
        </button>
        <ul className="space-y-3 mb-8">
          {features.map((feature: any, index: any) => (
            <li key={index} className="flex items-center text-gray-300">
              <div className="h-1.5 w-1.5 rounded-full bg-white mr-3"></div>
              {feature}
            </li>
          ))}
        </ul>

        <div className="flex items-center gap-1 mt-[100px]">
          <img src={circleDesgin} alt="" className="h-[10px]" />
          <h3 className=" font-[OpenSauceSans] text-sm font-bold text-[#aaa] mt-180">
            Onboarding fee required for DAO incorporation
          </h3>
        </div>
      </div>
    );
  }

  function PricingCard2({
    title,
    price,
    features,
    highlight = false,
    style,
    subtitle,
    subtext,
  }: any) {
    return (
      <div className={`rounded-xl p-8 ${"bg-[#16270E]"} ${style}`}>
        <div className="h-20 w-20 rounded-full bg-green-500/20 flex items-center justify-center mb-6">
          <img src={b2} alt="" />
        </div>
        <h3 className=" font-[OpenSauceSans] text-xl font-bold text-white mb-2">{title}</h3>
        <h3 className=" font-[OpenSauceSans] text-sm font-semibold text-[#aaa] mb-10">{subtitle}</h3>
        <div className="mb-6">
          <span className="text-6xl font-bold text-white font-[OpenSauceSans] font-[OpenSauceSans]">${price}</span>
          {/* <span className="text-gray-400 ml-2">/mo</span> */}
          <h3 className=" font-[OpenSauceSans] text-sm font-semibold text-[#aaa] mb-2 mt-5">{subtext}</h3>
        </div>
        <button
          className="w-[50%] md:w-[30%] py-2 px-6 rounded-[sm] bg-[white] hover:bg-[#eee] text-bold text-[16px] font-medium transition-colors mb-8"
          onClick={() => window.location.href = "https://trendx.app/stripe/one.php"}
        >
          Pay Now
        </button>
        <div className="w-[100%] flex gap-3 align-center flex-wrap">
          <PaymentBox
            img={d1}
            title={"Onboarding Fee"}
            subtitle={
              <>
                Access to all the tools you need <br /> to manage tokens
              </>
            }
            price={"$299"}
          />
          <PaymentBox
            img={d2}
            title="Compliance"
            subtitle={
              <>
                Stay compliant with annual report <br /> filing
              </>
            }
            price="$2,499"
          />

          <PaymentBox
            img={d3}
            title="Accounting"
            subtitle={
              <>
                Managing portfolios, <br /> investments, and tax obligations
              </>
            }
            price="$899"
          />

          <PaymentBox
            img={d4}
            title="Mailroom"
            subtitle={
              <>
                Get a physical address and <br /> virtual mailbox to stay
                compliant
              </>
            }
            price="$379"
          />

          {/* <PaymentBox
            img={d2}
            title={"Compliance"}
            subtitle={"Stay compliant with annual report \n filing"}
            price={"$2,499"}
          />
          <PaymentBox
            img={d3}
            title={"Accounting"}
            subtitle={"Managing portfolios, investments, and tax obligations"}
            price={"$899"}
          />
          <PaymentBox
            img={d4}
            title={"Mailroom"}
            subtitle={
              "Get a physical address and virtual mailbox to stay compliant"
            }
            price={"$379"}
          /> */}
        </div>
      </div>
    );
  }

  function BreakDownSection({ features, title, subtitle, price, img }: any) {
    return (
      <div className="w-[100%] flex flex-col md:flex-row">
        <div className="w-[60%]">
          <img src={img} alt="" style={{ height: 90 }} />
          <div className="flex align-center gap-3 mb-5 mt-5">
            <h3 className=" font-[OpenSauceSans] text-xl font-semibold text-white ">{title}</h3>
          </div>
          <h3 className=" font-[OpenSauceSans] text-md font-bold text-[#aaa] mb-5">{subtitle}</h3>

          <ul className="space-y-3 mb-8">
            {features.map((feature: any, index: any) => (
              <li key={index} className="flex items-center text-gray-300 font-[OpenSauceSans]">
                <div className="h-1.5 w-1.5 rounded-full bg-white mr-3"></div>
                {feature}
              </li>
            ))}
          </ul>
        </div>

        <div className="w-[40%] md:self-end pb-[1.5%]">
          <h3 className=" font-[OpenSauceSans] text-[60px] font-[900] text-white mb-3">{price}</h3>
          <h3 className=" font-[OpenSauceSans] text-md font-bold text-[#aaa] mb-7">
            Annually · Per DAO
          </h3>
          <button
            className=" font-[OpenSauceSans] w-[80%] md:w-[30%] py-2 px-6 rounded-[sm] bg-[white] hover:bg-[#eee] text-black text-[9px] font-medium transition-colors mb-4"
            onClick={() => navigate("/tokenizewithus")}
          >
            Get Started
          </button>
        </div>
      </div>
    );
  }

  function Testimonial() {
    return (
      <div className="bg-[#080D01] rounded-xl h-[400px] px-10 md:px-40 my-16 flex flex-col justify-center ">
        <div className="flex items-center justify-between mb-4">
          <img src={trustpilot} alt="" className="w-[100px] md:w-[200px]" />
          <img src={star} alt="" className="w-[50px] md:w-[100px]" />
        </div>
        <p className=" font-[OpenSauceSans] text-gray-300 text-sm md:text-lg mb-4 font-bold">
          "Using Trendx gives my entire bookclub a new perspective.
We can now seamlessly acquire IP for amazing stories that
we love, support writers and also build a community around
projects that we are passionate about. Being able to do that in
one place is the only reason why tokenisation works for us"
        </p>
        <div>
          <p className=" font-[OpenSauceSans] text-white font-medium">Taylie Mitchell</p>
          <p className=" font-[OpenSauceSans] text-gray-400">Australia</p>
        </div>
      </div>
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderHelmet
        title="Pricing | TrendX"
        description="$399.00 · Stay compliant · Get started with either Trendx One or Launch."
        url="https://trendx.app/pricing"
        image="https://trendx.app/logo192.png"
      />
      <div className="body2 home-body">
        <NavBar currentActive="About TBS" />

        <FadeInAnimation delay={2.5} duration={1}>
          <h3 className=" font-[OpenSauceSans] text-xl font-semibold text-[#73AC5B] mt-10">
            Pricing
          </h3>
        </FadeInAnimation>
        <FadeInAnimation delay={2.5} duration={1}>
          <h4 className="font-[OpenSauceSans]  bannerText2">
            The all-in-one {!isMobile && <br />} tokenization platform.
          </h4>
        </FadeInAnimation>

        <FadeInAnimation delay={3.5} duration={1}>
          <div className="desTextCont2">
            <div className="desTextContInner2">
              <p className=" font-[OpenSauceSans] desText2">
                Get started on your tokenisation journey with Trendx Launch and
                One. Access all the tools
                <br />
                needed to incorporate a DAO, stay compliant, manage distribution
                and unlock liquidity.{" "}
              </p>
            </div>
          </div>
        </FadeInAnimation>
      </div>
      <FadeInAnimation delay={1.5} duration={1}>
        {/* <div className="w-[100%] flex justify-between align-center px-[8.5%] mb-10"> */}
        <div className="w-[100%] flex flex-col md:flex-row justify-between px-[8.5%] mb-10 gap-5 md:gap-0">
          <PricingCard
            title="Launch"
            price="579"
            // icon={Link2}
            features={[
              "Setup DAO",
              "Access liquidity pools",
              "Launch and manage tokens",
              "24/7 expert support",
              "Get token distribution tools",
              "Smart contract support",
            ]}
            style={"w-[100%] md:w-[40%]"}
            subtitle="Incorporate your DAO"
            subtext="USD · One-time fee"
          />
          <PricingCard2
            title="One"
            price="3499"
            subtitle="All the tools you need to launch a DAO, stay compliant and manage distribution."
            subtext="Yearly · Billed annually at $3,499"
            // icon={BarChart3}
            highlight={true}
            features={[
              "Everything in Launch, plus:",
              "Advanced analytics",
              "Custom token features",
              "Priority support",
              "Up to 5 team seats",
              "API access",
            ]}
            style={"w-[100%] md:w-[57%]"}
          />
        </div>
      </FadeInAnimation>

      <FadeInAnimation delay={1.5} duration={1}>
        <div className="w-[100%] px-[8.5%]">
          <BreakDownSection
            img={d3}
            title={"Accounting"}
            subtitle=" Managing portfolios, investments, and tax obligations"
            price="$899"
            features={[
              "Priority support",
              "Change cost basis method by year",
              "Tax lots breakdown",
              "Tax loss harvesting",
              "Performance tracking",
              "Portfolio tracking",
              "TurboTax & HR Block integrations",
              "Tax reports for 2024 and all past years",
              "Up to 10,000 transactions per year",
            ]}
          />

          <div className="border-t border-[#555] w-full mt-7 mb-[5%]"></div>

          <BreakDownSection
            img={d2}
            title={"Compliance"}
            subtitle="Stay compliant with annual report filings"
            price="$2,499"
            features={[
              "Form 5472 (for foreign-owned U.S. entities)",
              "Form 1120 (corporate tax return for DAO’s)",
              "Form K1 & 1065",
              "Unlimited Forms 1099-NEC or 1099-MISC (for contractor payments)",
            ]}
          />
        </div>
      </FadeInAnimation>
      <div className=" flex justify-end pr-[5%] mt-[10%]">
        <div
          className="trustpilot-widget "
          data-locale="en-US"
          data-template-id="56278e9abfbbba0bdcd568bc"
          data-businessunit-id="67a803768525b99cf9095f08"
          data-style-height="52px"
          data-style-width="100%"
        >
          <a
            href="https://www.trustpilot.com/review/trendx.app"
            target="_blank"
            rel="noopener"
          >
            Trustpilot
          </a>
        </div>
      </div>
      <FadeInAnimation delay={1.5} duration={1}>
        <div className="w-[100%] px-[8.5%]">
          <Testimonial />
        </div>
      </FadeInAnimation>

      <div className="b1-sec">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: 1,
            },
          }}
          className="mt-[124px] w-[480px] self-start bby"
        >
          <p
            className="text-white text-[16px] font-normal"
            style={{ color: "#A7FC00" }}
          >
            Tokenize and Scale
          </p>
          <h4 className="text-white text-[40px] font-semibold">
            Everything you need to fund your vision.
          </h4>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: 1,
            },
          }}
          className="mt-[64px] self-start bk"
        >
          <p className=" font-[OpenSauceSans] text-white text-[20px] font-semibold">
            Backed by
          </p>
          <div className="flex items-center gap-[40px] mt-[25px] backed-by">
            <img src={cv} alt="" />
            <img src={lisk} alt="" />
          </div>
        </motion.div>
      </div>
      {<Footer />}
      {/* <div className="footer mt-[124px] w-[100%]">
        <Footer />
        {isMobile && <div className="text-[110px] trx">Trendx</div>}
        {isMobile && (
          <div className="copyright-holder">
            <p className=" font-[OpenSauceSans] text-[0.75rem] text-white copyright">
              © 2024 All Rights Reserved
            </p>
          </div>
        )}
      </div> */}
    </>
  );
}
