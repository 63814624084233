import React, { useState, useRef, useEffect } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useLocation, useNavigate } from "react-router-dom";
import chevBack from "../../assets/ion_chevron-back.svg";
import clip from "../../assets/clip.png";

import Toast, { handleToast } from "../../Components/Toast";
import { GetUserDetails } from "../../RequestHandlers/User";
import { storeCompliancePath } from "../../utils/Localstorage";
import { useFileUpload } from "../../context/uploadFile";

const generateAddress = () => {
  const chars = "0123456789abcdef";
  let address = "0x";
  for (let i = 0; i < 40; i++) {
    address += chars[Math.floor(Math.random() * chars.length)];
  }
  return address;
};

function CreateDao2() {
  const navigate = useNavigate();
  const location = useLocation();
    const { uploadFile, isUploading, uploadError } = useFileUpload();

  const data = location.state.data || {};
  const [selectedValue, setSelectedValue] = useState("");
  const [address, setAddress] = useState(generateAddress());
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>(null);
  const [uploadedFileUrl1, setUploadedFileUrl1] = useState<string | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef1 = useRef<HTMLInputElement>(null);

  const [fileName, setFileName] = useState("");
  const [fileName1, setFileName1] = useState("");

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const data = await uploadFile(file);
      setUploadedFileUrl1(data.secure_url);
      setFileName(file.name);
    }
    
  };

  const handleFileChange1 = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const data = await uploadFile(file);
          setUploadedFileUrl(data.secure_url);
      setFileName1(file.name);
    }
  };

  const [submitHook, setSubmitHook] = useState(false);

  const [pValue1, setPValue1] = useState("");
  const inputRef1 = useRef<HTMLInputElement>(null);

  const [pValue2, setPValue2] = useState("");
  const inputRef2 = useRef<HTMLInputElement>(null);

  const [pValue3, setPValue3] = useState("");
  const inputRef3 = useRef<HTMLInputElement>(null);

  const [pValue4, setPValue4] = useState("");
  const inputRef4 = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);

  const [details, setdetails] = useState<any>({ contactEmail: "" });
  const inputRef5 = useRef<HTMLInputElement>(null);

  const [pValue6, setPValue6] = useState("");
  const inputRef6 = useRef<HTMLInputElement>(null);

  const CheckError = (value: any) => {
    if (value == "" || value == " " || value == null || value == undefined) {
      return false;
    }
    console.log(value);
    return true;
  };

  const handleDivClick = () => {
    // @ts-ignore
    fileInputRef.current?.click();
  };

  const createDAO = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      surname: pValue1,
      first_name: pValue2,
      last_name: pValue3,
      gender: "Male",
      address: pValue4,
      wallet_address: address,
      upload_id: uploadedFileUrl,
      logo: uploadedFileUrl1,
      preferred_name_1: data.preferred_name_1,
      preferred_name_2: data.preferred_name_2,
      preferred_name_3: data.preferred_name_3,
      industry: data.industry,
      jurisdiction: data.jurisdiction,
      dao_purpose: data.dao_purpose,

      email: details.contactEmail,
    });

    fetch("https://trendx.app/api/insertDAO.php", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status == "success") {
          setLoading(false);
          localStorage.setItem("DAOData", JSON.stringify({
            email:details.contactEmail,
            name:details.first_name
          }));
          
          window.location.href = "https://trendx.app/stripe/DAO.php";
        } else {
          setLoading(false);
          handleToast("A problem occurred, try again later");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const submit = () => {
    // navigate("/CreateDao2");
    setSubmitHook(true);
    if (
      CheckError(pValue1) &&
      CheckError(pValue2) &&
      CheckError(pValue3) &&
      CheckError(pValue4) &&
      CheckError(fileName)
    ) {
      setLoading(true);
      createDAO();
    }
  };

  const PageForm = ({
    title,
    dropdown = false,
    file = false,
    list = [],
    disable = false,
    value = null,
    setValue,
    inputRef,
    error = false,
    handleFileChange,
    fileName,
  }: any) => {
    const handleInputChange = (e: any) => {
      setValue(e.target.value); // Don't trim here to prevent losing focus
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    };

    return (
      <div className="flex items-center gap-6 mb-[3%]">
        <h3 className="font-[OpenSauceSans] text-sm font-medium text-white w-[150px]">
          {title}
        </h3>
        {dropdown ? (
          <div className="flex w-[600px] justify-between">
            {/* <input
              type="text"
              className="w-[300px] p-2 rounded-lg bg-[#222] text-white focus:outline-none focus:ring-2 focus:ring-gray-400"
            /> */}
            <div className="flex flex-col">
              <input
                ref={inputRef}
                value={value}
                onChange={handleInputChange}
                type="text"
                className="w-[300px] p-2 rounded-lg bg-[#222] text-white focus:outline-none focus:ring-2 focus:ring-gray-400"
              />
              {error && (
                <p className="text-[#BA292B] text-sm">
                  {title} can not be empty
                </p>
              )}
            </div>
            <div className="relative w-[150px]">
              <select
                name=""
                id=""
                value={selectedValue}
                // className="w-[300px] p-2 rounded-lg bg-[#222] text-white focus:outline-none pr-[2%]"
                className="w-full p-2 pr-8 rounded-lg bg-[#222] text-white focus:outline-none focus:ring-2 focus:ring-gray-400 appearance-none"
                onChange={(e) => {
                  setSelectedValue(e.target.value);
                }}
              >
                <option value={"Male"} selected>
                  Male
                </option>
                {list.map((item: string) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-5 flex items-center pointer-events-none text-[#fff] text-xs">
                ▼
              </div>
            </div>
          </div>
        ) : file ? (
          <div>
            <div
              className="w-[600px] p-3 rounded-lg bg-[#222] text-white focus:outline-none focus:ring-2 focus:ring-gray-400 flex justify-between items-center cursor-pointer"
              onClick={() => {
                // @ts-ignore
                inputRef.current?.click();
              }}
            >
              <span>{fileName}</span> {/* Show selected file name */}
              <img src={clip} alt="" className="h-[15px]" />
            </div>
            {error && (
              <p className="text-[#BA292B] text-sm">{title} can not be empty</p>
            )}
            <p className="text-xs text-[#666] italic mt-1">
              Passport, drivers licence, etc.
            </p>

            <input
              type="file"
              hidden
              onChange={handleFileChange}
              ref={inputRef}
              value={value}
              // onChange={(e)=>{
              //   // handleInputChange(e)
              //   handleFileChange(e)
              // }}
            />
          </div>
        ) : (
          // <input
          //   disabled={disable}
          //   value={value}
          //   type="text"
          //   className="w-[600px] p-2 rounded-lg bg-[#222] text-white focus:outline-none focus:ring-2 focus:ring-gray-400"
          // />
          <div className="flex flex-col">
            <input
              disabled={disable}
              ref={inputRef}
              value={value}
              onChange={handleInputChange}
              type="text"
              className="w-[600px] p-2 rounded-lg bg-[#222] text-white focus:outline-none focus:ring-2 focus:ring-gray-400"
            />
            {error && (
              <p className="text-[#BA292B] text-sm">{title} can not be empty</p>
            )}
          </div>
        )}
      </div>
    );
  };

  const getUserDetails = async () => {
    try {
      const res = await GetUserDetails();
      if (res.avatar) {
        setdetails(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserDetails();
    storeCompliancePath("CreateDao2");
  }, []);

  return (
    <>
      <div className="dash-body">
        <Toast />
        <SideNav activeTab="Compliance" />
        <div className="content-body">
          <ProfileNav />
          <div
            className="c-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={chevBack} alt="" />
            <p>Back</p>
          </div>
          <div className="sec-header" style={{ marginBottom: 3 }}>
            <p style={{ fontWeight: 400 }}>DAO Incorporation Onboarding</p>
          </div>
          <p
            style={{
              fontWeight: 400,
              color: "#999",
              fontSize: 10,
              marginBottom: 20,
            }}
          >
            Personal Details
          </p>

          <PageForm
            title="Surname"
            dropdown={true}
            list={["Female", "Prefer not to say"]}
            value={pValue1}
            setValue={setPValue1}
            inputRef={inputRef1}
            error={submitHook == true && CheckError(pValue1) == false}
          />
          <PageForm
            title="First Name"
            value={pValue2}
            setValue={setPValue2}
            inputRef={inputRef2}
            error={submitHook == true && CheckError(pValue2) == false}
          />
          <PageForm
            title="Last Name"
            value={pValue3}
            setValue={setPValue3}
            inputRef={inputRef3}
            error={submitHook == true && CheckError(pValue3) == false}
          />
          <PageForm
            title="Address"
            value={pValue4}
            setValue={setPValue4}
            inputRef={inputRef4}
            error={submitHook == true && CheckError(pValue4) == false}
          />
          <PageForm
            title="Wallet Address"
            disable={true}
            value={address}
            // value="0x6fb1cc5c4f927E3c047be79D6B99aFA30e4D9Fc0"
          />
          <PageForm
            title="Upload ID"
            file={true}
            inputRef={fileInputRef}
            error={submitHook == true && CheckError(fileName) == false}
            handleFileChange={handleFileChange}
            fileName={fileName}
          />
          
          <PageForm
            title="Upload Business Logo"
            file={true}
            inputRef={fileInputRef1} // Use a separate ref
            handleFileChange={handleFileChange1}
            error={submitHook == true && CheckError(fileName1) == false}
            fileName={fileName1}
          />

          <div className="w-full flex justify-end px-[5%]">
            <button
              style={{ transition: "0.5s" }}
              className=" ml-[2%] w-[150px] bg-[#fff] h-[50px] flex items-center justify-center rounded-[20px] mt-5 cursor-pointer hover:bg-[#ddd]"
              onClick={() => {
                // handleToast("Success, You'd received an email confirming you DAO","success");
                submit();
              }}
            >
              {loading ? (
                <div className="dot-loader">
                  <span className="dot2"></span>
                  <span className="dot2"></span>
                  <span className="dot2"></span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateDao2;
