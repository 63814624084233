 const ActivityIndicator = ({ size = "80px", color = "#FFC107" }) => {
  const borderSize = `calc(${size} / 10)`;

  return (
    <div
      className="custom-spinner"
      style={{
        width: size,
        height: size,
        position: "relative",
        borderWidth: borderSize,
        borderColor: "#ffffff",
      }}
    >
      <div
        className="filter"
        style={{
          width: "88%",
          height: "88%",
          position: "absolute",
          backgroundColor: "#141414",
          borderRadius: "100%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      ></div>
      <div
        className="custom-spinner-arc"
        style={{
          borderWidth: borderSize,
          borderTopColor: color,
          borderRightColor: "transparent",
          borderBottomColor: "transparent",
          borderLeftColor: "transparent",
        }}
      ></div>
    </div>
  );
};


export default ActivityIndicator