import React from "react";
import envelop from "../../assets/envelop.gif";
import SecBtn from "../../Components/SecBtn";
import { useLocation, useNavigate } from "react-router-dom";
import tick from "../../assets/tick.gif";

export default function VoucherDeleted() {
  const navigate = useNavigate();
  const location = useLocation();
  const code = location.state.code || "";
  const type = location.state.type || "";
  return (
    <section className="emailBody">
      <img src={tick} alt="" className="envelop" />
      <div className="textWrap">
        <h4>
          {type === "unPublished"
            ? "Voucher unPublished"
            : type === "update"
            ? "Update added"
            : "Voucher deleted"}
        </h4>
        <p>
          {type === "unPublished"
            ? `Voucher ${code} has been unpublished.`
            : type === "update"
            ? `Voucher ${code} has been updated.`
            : `Voucher ${code} has been deleted permanently.`}
        </p>
      </div>
      <div className="btn-Wrap">
        <SecBtn
          text="Back to vouchers"
          onPress={() => {
            navigate("/Voucher");
          }}
        />
      </div>
    </section>
  );
}
