import "../../../css/settings/style.css";
import Input from "../../Input";
import trashIcon from "../../../assets/trash.svg";
import plusIcon from "../../../assets/plus.svg";
import { FormikProps } from "formik";
import {
  SettingsFormValues,
  Stakeholder,
} from "../../../Screens/Settings/self-listed/SelfListed";

interface Props<T> {
  formik: FormikProps<T>;
}

const KeyStakeHolders: React.FC<Props<SettingsFormValues>> = ({ formik }) => {
  const handleAddStakeholder = () => {
    const newStakeholder: Stakeholder = {
      id: Date.now(),
      name: "",
      role: "",
      link: "",
    };
    formik.setFieldValue("stakeholders", [
      ...formik.values.stakeholders,
      newStakeholder,
    ]);
  };

  const handleDeleteStakeholder = (id: number) => {
    const updatedStakeholders = formik.values.stakeholders.filter(
      (stakeholder) => stakeholder.id !== id
    );
    formik.setFieldValue("stakeholders", updatedStakeholders);
  };

  const handleInputChange = (
    id: number,
    field: keyof Stakeholder,
    value: string
  ) => {
    const updatedStakeholders = formik.values.stakeholders.map((stakeholder) =>
      stakeholder.id === id ? { ...stakeholder, [field]: value } : stakeholder
    );
    formik.setFieldValue("stakeholders", updatedStakeholders);
  };

  return (
    <div className="settings-form-container">
      <div className="title-container">
        <p>Key stakeholders</p>
      </div>
      {formik.values.stakeholders.map((stakeholder, index) => (
        <div
          key={stakeholder.id}
          style={{
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <div className="settings-form-row">
            <div className="settings-input">
              <Input
                label={`Name ${index + 1}`}
                contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                contClass="list-input-cont"
                extraClass="list-input"
                value={stakeholder.name}
                onChange={(e) =>
                  handleInputChange(stakeholder.id, "name", e.target.value)
                }
              />
            </div>
            <div className="settings-input">
              <Input
                label="Role"
                contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                contClass="list-input-cont"
                extraClass="list-input"
                value={stakeholder.role}
                onChange={(e) =>
                  handleInputChange(stakeholder.id, "role", e.target.value)
                }
              />
            </div>
            <div className="settings-input">
              <Input
                label="LinkedIn Profile"
                contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                contClass="list-input-cont"
                extraClass="list-input"
                value={stakeholder.link}
                onChange={(e) =>
                  handleInputChange(stakeholder.id, "link", e.target.value)
                }
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 14,
              marginTop: 26,
              padding: "0px 36px",
            }}
          >
            <img
              style={{
                width: 16,
                height: 16,
                cursor: "pointer",
              }}
              src={trashIcon}
              alt="Delete"
              onClick={() => handleDeleteStakeholder(stakeholder.id)}
            />
            <p
              className="delete-text"
              onClick={() => handleDeleteStakeholder(stakeholder.id)}
            >
              Delete
            </p>
          </div>
        </div>
      ))}
      <div
        className="add-company-portfolio-container"
        onClick={handleAddStakeholder}
      >
        <img src={plusIcon} alt="Add Stakeholder" />
        <p>Add stakeholder</p>
      </div>
    </div>
  );
};

export default KeyStakeHolders;
