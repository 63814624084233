import "../../css/profile/styles.css";
import Input from "../Input";
import { useEffect, useMemo, useState } from "react";
import uploadImage from "../../assets/file-upload.svg";
import {
  GetUserDetails,
  UpdateUserAvatar,
  UpdateUserDetails,
} from "../../RequestHandlers/User";
import SecBtn from "../SecBtn";
import Toast, { handleToast } from "../Toast";
import useCloudinaryUpload from "../../libs/hooks/useCloudinary";

const ProfileInformation = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    avatar: "",
  });

  const [initialFormData, setInitialFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    avatar: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    avatar: "",
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [updating, setUpdating] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const { uploadFile } = useCloudinaryUpload();
  const validateForm = () => {
    let valid = true;
    const newErrors: typeof errors = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      avatar: "",
    };

    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required.";
      valid = false;
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last name is required.";
      valid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      newErrors.email = "Valid email is required.";
      valid = false;
    }

    const phoneRegex = /^\+?\d{10,15}$/;
    if (
      !formData.phoneNumber.trim() ||
      !phoneRegex.test(formData.phoneNumber)
    ) {
      newErrors.phoneNumber = "Valid phone number is required.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setFormData((prev) => ({ ...prev, avatar: URL.createObjectURL(file) }));
    }
  };
  const handleDrop = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    setIsDragOver(false); // Reset the drag-over state
    const file = event.dataTransfer.files?.[0];
    if (file) {
      setSelectedFile(file);
      setFormData((prev) => ({ ...prev, avatar: URL.createObjectURL(file) }));
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    setIsDragOver(true); // Set the drag-over state to true
  };

  const handleDragLeave = () => {
    setIsDragOver(false); // Reset the drag-over state when leaving the area
  };

  const getUserDetails = async () => {
    try {
      const res = await GetUserDetails();
      console.log(res);
      
      if (res) {
        const userData = {
          firstName: res?.firstname || "",
          lastName: res?.lastname || "",
          email: res.contactEmail || "",
          phoneNumber: res.contactPhoneNumber || "",
          avatar: res.avatar || "",
        };
        setFormData(userData);
        setInitialFormData(userData);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const hasChanges = () => {
    const otherFieldsChanged =
      JSON.stringify({
        ...formData,
        avatar: undefined,
      }) !==
      JSON.stringify({
        ...initialFormData,
        avatar: undefined,
      });

    const avatarChanged = formData.avatar !== initialFormData.avatar;

    return otherFieldsChanged || avatarChanged;
  };

  const isDisabled = useMemo(
    () => !hasChanges() || !validateForm(),
    [formData, initialFormData]
  );

  const updateUserProfileHandler = async () => {
    try {
      setUpdating(true);
      const data = {
        firstname: formData.firstName,
        lastname: formData.lastName,
        contactPhoneNumber: formData.phoneNumber,
      };
      const response = await UpdateUserDetails(data);
      if (response) {
        handleToast("Profile updated", "success");
        setInitialFormData(formData);
      }
    } catch (error: any) {
      console.log("error occured while updating profile", error);
      handleToast(error?.message || "Network error", "error");
    } finally {
      setUpdating(false);
    }
  };
  const updateUserAvatar = async () => {
    try {
      setUpdating(true);
      const imageResponse: any = await uploadFile({ file: selectedFile });

      if (imageResponse) {
        const response = await UpdateUserAvatar({ image: imageResponse });
        if (response) {
          handleToast("Profile updated", "success");
          setFormData((prev) => ({ ...prev, avatar: imageResponse }));
        }
      }
    } catch (error: any) {
      console.log("error occured while updating profile", error);
      handleToast(error?.message || "Network error", "error");
    } finally {
      setUpdating(false);
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    const otherFieldsChanged =
      JSON.stringify({
        ...formData,
        avatar: undefined,
      }) !==
      JSON.stringify({
        ...initialFormData,
        avatar: undefined,
      });
    e.preventDefault();

    if (validateForm()) {
      try {
        if (formData.avatar !== initialFormData.avatar && selectedFile) {
          await updateUserAvatar();
        }

        if (otherFieldsChanged) {
          await updateUserProfileHandler();
        }
      } catch (error) {
        console.error("Error updating profile:", error);
      }
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <div className="personal-info-view">
      <div className="profile-form-container">
        <div style={{ width: "100%" }}>
          <h2 className="profile-form-title">Personal Information</h2>
        </div>

        <form className="profile-form" onSubmit={handleSubmit}>
          <Input
            contStyle={{ margin: "16px 0px", background: "#0D0D0D" }}
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            error={errors.firstName}
          />
          <Input
            contStyle={{ background: "#0D0D0D", margin: "16px 0px" }}
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            error={errors.lastName}
          />
          <Input
            contStyle={{ background: "#0D0D0D", margin: "16px 0px" }}
            label="Email Address"
            name="email"
            disabled={true}
            value={formData.email}
            onChange={handleInputChange}
            error={errors.email}
          />
          <Input
            contStyle={{ background: "#0D0D0D", margin: "16px 0px" }}
            label="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            error={errors.phoneNumber}
          />

          <div className="profile-image-upload-container">
            <div className="image-section">
              {formData.avatar ? (
                <img
                  src={formData.avatar}
                  alt="Selected"
                  className="uploaded-image-preview"
                />
              ) : (
                <p>No image selected</p>
              )}
              {errors.avatar && <p className="error-text">{errors.avatar}</p>}
            </div>

            {/* <label
                            htmlFor="file-input"
                            className="profile-img-upload-section"
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                        >
                            <div className="profile-img-upload-view">
                                <img
                                    style={{ width: 35.11, height: 35.51 }}
                                    src={uploadImage}
                                    alt="Upload Icon"
                                />
                                <p className="click-text">
                                    Click or drag file to this area to upload
                                </p>
                                <p className="file-type">
                                    SVG, PNG, or JPEG (max. 375px x 150px)
                                </p>

                                <input
                                    type="file"
                                    accept="image/png, image/jpeg, image/svg+xml"
                                    id="file-input"
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                />
                            </div>
                        </label> */}
            <label
              htmlFor="file-input"
              className={`profile-img-upload-section ${
                isDragOver ? "dragover" : ""
              }`}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
            >
              <div className="profile-img-upload-view">
                <img
                  style={{ width: 35.11, height: 35.51 }}
                  src={uploadImage}
                  alt="Upload Icon"
                />
                <p className="click-text">
                  Click or drag file to this area to upload
                </p>
                <p className="file-type">
                  SVG, PNG, or JPEG (max. 375px x 150px)
                </p>

                <input
                  type="file"
                  accept="image/png, image/jpeg, image/svg+xml"
                  id="file-input"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
            </label>
          </div>

          <div className="save-view">
            <SecBtn
              text="Cancel"
              style={{
                background: "transparent",
                color: "#FFFFFF",
                width: "72px",
                padding: " 8px 14px",
                borderRadius: "12px",
                border: "1px solid #4A4A4A",
                fontSize: "14px",
                fontWeight: "500",
                outline: "none",
                height: 42,
              }}
              onPress={() => setFormData(initialFormData)}
            />
            <SecBtn
              style={{
                color: " #030500",
                width: "115px",
                padding: "8px 0px",

                borderRadius: " 12px",
                fontSize: "14px",
                fontWeight: "500",
                backgroundColor: "#A7FC00",
                opacity: isDisabled ? 0.6 : 1,
                height: 42,
              }}
              loading={updating}
              onPress={(e) => handleSubmit(e)}
              disabled={isDisabled}
              text="Save Changes"
            />
          </div>
        </form>
      </div>
      <Toast />
    </div>
  );
};

export default ProfileInformation;
