import { RequestHandler } from "./StbRequest";

const request = new RequestHandler();
export function Login(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    request.post(`user/login`, body, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}

export function SpotlightProject(id: any, body: object, token: any): Promise<any> {
  return new Promise((resolve, reject) => {
    if (token) {
      const tkn = token.replace(/^"|"$/g, "");
      request.post(`investment/spotlight/collection/${id}`, body, tkn, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function AddSpotlightGallry(
  id: any,
  body: object,
  token: any
): Promise<any> {
  return new Promise((resolve, reject) => {
    if (token) {
      const tkn = token.replace(/^"|"$/g, "");
      request.post(`investment/spotlight/gallary/${id}`, body, tkn, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
