import React, { useEffect } from "react";
import envelop from "../assets/envelop.gif";
import SecBtn from "../Components/SecBtn";
import { useNavigate } from "react-router-dom";

export default function EmailSentScreen() {
  const sendEmail = (email: string, password: string) => {
    const formdata = new FormData();
    formdata.append("to", email);
    formdata.append("password", password);

    fetch("https://trendx.app/api/sendemail.php", {
      method: "POST",
      body: formdata,
      redirect: "follow",
    })
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        localStorage.removeItem("userData");
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");

    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      sendEmail(userData.email.trim(), userData.password);
    }
  }, []);
  const navigate = useNavigate();
  return (
    <section className="emailBody">
      <img src={envelop} alt="" className="envelop" />
      <div className="textWrap">
        <h4>Email sent</h4>
        <p>
          Thank you for contacting our tokenization team. We’ll get back
          <br />
          to you shortly to schedule a consultation call.
        </p>
      </div>
      <div className="btn-Wrap">
        <SecBtn
          text="Back to home"
          onPress={() => {
            navigate("/");
          }}
        />
      </div>
    </section>
  );
}
