import React, { createContext, useContext, useState, ReactNode } from "react";

interface SettingsContextType {
    activeOption: string;
    setActiveOption: React.Dispatch<React.SetStateAction<string>>;
    typeOfCompanyInfo: string;
    setTypeOfCompanyInfo: React.Dispatch<React.SetStateAction<string>>;
}

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

const SettingsContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [activeOption, setActiveOption] = useState<string>("company-info");
    const [typeOfCompanyInfo, setTypeOfCompanyInfo] = useState<string>("self-listed");

    return (
        <SettingsContext.Provider value={{ activeOption, setActiveOption, typeOfCompanyInfo, setTypeOfCompanyInfo }}>
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettingsContext = (): SettingsContextType => {
    const context = useContext(SettingsContext);
    if (!context) {
        throw new Error("useSettingsContext must be used within a SettingsContextProvider");
    }
    return context;
};

export default SettingsContextProvider;
