import { RequestHandler } from "./Request";
import Cookies from "js-cookie";

const request = new RequestHandler();

export function GetSpotlightOverview(): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get("tbs/spotlight/overview", token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}

export function GetAllSpotlight(): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(
        "tbs/spotlight?page=1&limit=100&coverType=all&type=all&status=all",
        token,
        [
          (data: any) => {
            resolve(data);
          },
        ]
      );
    } else {
      console.log("null");
    }
  });
}

export function GetSpotlightById(id: number): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(`tbs/spotlight/${id}`, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function SpotlightProject(id: any, body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.post(`investment/spotlight/collection/${id}`, body, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function AddGallary(id: any, body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.post(`investment/spotlight/collection/${id}`, body, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
