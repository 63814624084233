import React, { useEffect, useState } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useNavigate } from "react-router-dom";
import plus from "../../assets/outline_plus.svg";
import ValuesCard from "../../Components/ValuesCard";
import list from "../../assets/platList.svg";
import listCheck from "../../assets/listCheck.svg";
import listPending from "../../assets/listPending.svg";
import listCancel from "../../assets/listCancel.svg";
import FadeInAnimation from "../../Components/FadeIn";
import emptyList from "../../assets/empty-list.svg";
import c1 from "../../assets/c1.png";
import c2 from "../../assets/c2.png";
import c3 from "../../assets/c3.png";
import c4 from "../../assets/c4.png";
import c5 from "../../assets/c5.png";
import g1 from "../../assets/g1.png";
import g2 from "../../assets/g2.png";
import g3 from "../../assets/g3.png";
import g4 from "../../assets/g4.png";
import g5 from "../../assets/g5.png";
import {
  GetAllSpotlight,
  GetSpotlightOverview,
} from "../../RequestHandlers/Spotlight";
import { PaginatedItems, Item } from "../../types";
import { log } from "node:console";
import { formatDate } from "../../Components/formatDate";
interface SpotlightStats {
  totalSpotlights: number;
  inReviewCount: number;
  acceptedCount: number;
  draftCount: number;
}

function Spotlight() {
  const [vLoad, setVLoad] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const loaderArr = [1, 2, 3, 4];
  const navigate = useNavigate();
  const [spotlightStats, setSpotlightStats] = useState<SpotlightStats>({
    totalSpotlights: 0,
    inReviewCount: 0,
    acceptedCount: 0,
    draftCount: 0,
  });
  const [mainSpotlightData, setMainSpotlightData] = useState<any>([]);
  const [spotlightData, setSpotlightData] = useState<PaginatedItems<Item>>({
    items: [],
    meta: {
      currentPage: 0,
      itemsPerPage: 0,
      totalPages: 0,
      itemCount: 0,
      totalItems: 0,
    },
  });
  const [data, setData] = useState<any>([
    {
      id: 1,
      project: "Cityscape Living",
      category: "Real estate",
      status: "Funding open",
      dateListed: "02-07-2024",
      fundingGoal: "$10,000",
      impression: 20,
      saves: 10,
      unitesAgq: 10,
      img: c1,
      gImg: g1,
    },
    {
      id: 2,
      project: "Truffle Skin",
      category: "Beauty",
      status: "Failed to fund",
      dateListed: "01-01-2024",
      fundingGoal: "$30,000",
      impression: 20,
      saves: 10,
      unitesAgq: 10,
      img: c2,
      gImg: g2,
    },
    {
      id: 3,
      project: "Mawi",
      category: "Entertainment",
      status: "In progress",
      dateListed: "02-05-2024",
      fundingGoal: "$20,000",
      impression: 20,
      saves: 10,
      unitesAgq: 10,
      img: c3,
      gImg: g3,
    },
    {
      id: 4,
      project: "Wellness Hub",
      category: "Healthcare",
      status: "Funding open",
      dateListed: "06-07-2024",
      fundingGoal: "$7,000",
      impression: 20,
      saves: 10,
      unitesAgq: 10,
      img: c5,
      gImg: g4,
    },
    {
      id: 5,
      project: "FutureLab Hub",
      category: "Technology",
      status: "Drafts",
      dateListed: "07-10-2024",
      fundingGoal: "$15,000",
      impression: 20,
      saves: 10,
      unitesAgq: 10,
      img: c4,
      gImg: g5,
    },
  ]);

  const capitalizeFirstLetter = (word: string) => {
    return word?.charAt(0)?.toUpperCase() + word.slice(1) || "....";
  };

  const getSpotlightOverview = async () => {
    setIsLoading(true);
    try {
      const res: SpotlightStats = await GetSpotlightOverview();
      setSpotlightStats(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllSpotlight = async () => {
    setIsLoading1(true);
    try {
      const res = await GetAllSpotlight();
      setSpotlightData(res);
      setMainSpotlightData(res.items);
      console.log("spotlight", res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading1(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getSpotlightOverview();
    getAllSpotlight();
  }, []);

  return (
    <div className="dash-body">
      <SideNav activeTab="Spotlight" />
      <div className="content-body">
        <ProfileNav />
        <div className="section-ph">
          <div className="ph-text-sec">
            <h4>Spotlight</h4>
            <p>Take a tour of your latest spotlights</p>
          </div>
          <div
            className="lnp-btn"
            onClick={() => {
              navigate("/ListSpotlightPromtScreen");
            }}
          >
            <img src={plus} alt="" /> New spotlight
          </div>
        </div>
        <div className="dash-overview">
          <div className="num-holders">
            <ValuesCard
              extraClass={"v-cards"}
              delay={0.5}
              text1="All spotlights"
              icon={list}
              loading={vLoad}
              bottomComp={
                <>
                  <p className="count-value">
                    {spotlightStats.totalSpotlights} spotlight
                  </p>
                </>
              }
            />
          </div>
          <div className="num-holders">
            <ValuesCard
              extraClass={"v-cards"}
              delay={0.5}
              text1="Accepted requests"
              icon={listCheck}
              loading={vLoad}
              bottomComp={
                <>
                  <p className="count-value">
                    {spotlightStats.acceptedCount} accepted
                  </p>
                </>
              }
            />
          </div>
          <div className="num-holders">
            <ValuesCard
              extraClass={"v-cards"}
              delay={0.5}
              text1="Pending requests"
              icon={listPending}
              loading={vLoad}
              bottomComp={
                <>
                  <p className="count-value">
                    {spotlightStats.inReviewCount} pending
                  </p>
                </>
              }
            />
          </div>
          <div className="num-holders">
            <ValuesCard
              extraClass={"v-cards"}
              delay={0.5}
              text1="Rejected requests"
              icon={listCancel}
              loading={vLoad}
              bottomComp={
                <>
                  <p className="count-value">
                    {spotlightStats.draftCount} rejected
                  </p>
                </>
              }
            />
          </div>
        </div>
        <div className="lp-cont">
          <div className="lp-header">
            <h4>Spotlights</h4>
            <div
              className="vr-btn"
              onClick={() => {
                navigate("/AllSpotlight");
              }}
            >
              View all
            </div>
          </div>
          <div className="lp-content-holder">
            {isLoading1 ? (
              <>
                <table className="table">
                  <thead className="th1">
                    <tr>
                      <th>Spotlight list</th>
                      <th>Status</th>
                      <th>Date Listed</th>
                      <th style={{ paddingLeft: 10 }}>Impressions</th>
                      <th style={{ paddingLeft: 10 }}>Saves</th>
                      <th>Units acquired</th>
                    </tr>
                  </thead>
                  <tbody className="tBody">
                    {loaderArr.map((item: any, i: number) => (
                      <tr key={i}>
                        <td>
                          <div className="tr">
                            <div className="img-load"></div>
                            <div className="tr-text">
                              <div className="t-load"></div>
                              <div className="in-load"></div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            <div className={`f-item-load`}></div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            <div className="d-load"></div>
                          </div>
                        </td>
                        <td className="funding-goal">
                          <div className="tr1" style={{ paddingLeft: 10 }}>
                            <div className="d-load"></div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1" style={{ paddingLeft: 10 }}>
                            <div
                              className="prog-bar-cont"
                              style={{ color: "#616161" }}
                            >
                              <div className="prog-bar">
                                <div
                                  className="bar"
                                  style={{
                                    width: "25%",
                                    backgroundColor: "#838383",
                                  }}
                                ></div>
                              </div>
                              <div className="prog-load"></div>
                            </div>
                          </div>
                        </td>
                        <td className="ddLine">
                          <div className="tr-last">
                            <div className="d-load"></div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : spotlightData?.items?.length === 0 ? (
              <>
                <div className="empty-wrap">
                  <img src={emptyList} alt="" />
                  <p>No project available</p>
                  <span>List of projects will appear here</span>
                </div>
              </>
            ) : (
              <FadeInAnimation delay={0.5} duration={1}>
                <table className="table">
                  <thead className="th1">
                    <tr>
                      <th>Spotlight list</th>
                      <th>Status</th>
                      <th>Date Listed</th>
                      <th style={{ paddingLeft: 30 }}>Impressions</th>
                      <th style={{ paddingLeft: 30 }}>Saves</th>
                      <th style={{ paddingLeft: 30 }}>Units acquired</th>
                    </tr>
                  </thead>
                  <tbody className="tBody">
                    {spotlightData?.items?.slice(0, 6).map((item: any) => (
                      <tr key={item.id}>
                        <td>
                          <div
                            className="tr"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate(`/SpotlightDetails`, {
                                state: { id: item.id },
                              });
                            }}
                          >
                            <img src={item.cover} alt="" />
                            <div className="tr-text">
                              <p>{item.title}</p>
                              <span>{capitalizeFirstLetter(item?.type)}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            <div
                              className={`f-item ${
                                item.status.includes("rejected")
                                  ? "f-item-error"
                                  : item.status.includes("in-review")
                                  ? "f-item-prog"
                                  : item.status.includes("draft")
                                  ? "f-item-draft"
                                  : ""
                              }`}
                            >
                              {item?.status?.includes("in-review")
                                ? "In review"
                                : capitalizeFirstLetter(item.status)}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            {formatDate(item.created_at)}
                          </div>
                        </td>
                        <td className="funding-goal">
                          <div className="tr1" style={{ paddingLeft: 30 }}>
                            {item.views}
                          </div>
                        </td>
                        <td>
                          <div className="tr1" style={{ paddingLeft: 30 }}>
                            {item.saves}
                          </div>
                        </td>
                        <td className="ddLine">
                          <div className="tr-last" style={{ paddingLeft: 30 }}>
                            {item.unitsAcquired}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </FadeInAnimation>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Spotlight;
