import { useState } from "react";
import HeaderBar from "../../Components/HeaderBar";
import ProfileNav from "../../Components/ProfileNav";
import SettingsTabHeader from "../../Components/settings/settings-tab-header.component";
import SideNav from "../../Components/SideNav";
import SelfListed from "./self-listed/SelfListed";
import CompanyListed from "./company-listed/CompanyListed";
import BulkEmails from "./bulk-emails";
import { useSettingsContext } from "../../context/SettingsContext";

const SettingsScreen = () => {
  const { activeOption, setActiveOption } = useSettingsContext();
  const renderSection = () => {
    switch (activeOption) {
      case "company-info":
        return <SelfListed />;
        break;

      case "bulk-email":
        return <BulkEmails />;
      default:
        break;
    }
  };
  return (
    <>
      <div
        style={{
          paddingBottom: 119,
        }}
        className="dash-body"
      >
        <SideNav activeTab="Settings" />
        <div className="content-body">
          <ProfileNav />
          <HeaderBar />
          <div
            style={{
              width: "100%",
              marginTop: 20,
            }}
          >
            <h1 className="notification-header-title">Settings</h1>
          </div>

          <div className="notification-tab-container">
            <SettingsTabHeader
              activeOption={activeOption}
              setActiveOption={setActiveOption}
            />
          </div>

          <div
            style={{
              width: "100%",
              marginTop: 20,
            }}
          >
            {renderSection()}
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsScreen;
