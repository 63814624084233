import React, { useState, useEffect, useRef } from "react";
import sucTick from "../assets/sucTick.svg";
import unSucTick from "../assets/unSucTick.svg";
import tPending from "../assets/tPending.svg";
import tClose from "../assets/tClose.svg";

let showToastFn: (text: string, type?: "success" | "error" | "pending") => void;

export const handleToast = (
  text: string,
  type: "success" | "error" | "pending" = "success"
) => {
  if (showToastFn) {
    showToastFn(text, type);
  }
};

export default function Toast({toShow=false}:any) {
  const [show, setShow] = useState(toShow);
  const [text, setText] = useState("");
  const [type, setType] = useState<any>("success");
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    showToastFn = (message, toastType) => {
      console.log(message);
      
      setText(message);
      setType(toastType);
      setShow(true); // ✅ Show toast
      // Clear any existing timeout before setting a new one
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setShow(false);
      }, 5000);
    };
  }, []);

  const closeToast = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setShow(false);
  };

  return (
    <div
      className={`toast-container ${show ? "show" : ""}`}
      style={{
        backgroundColor:
          type === "success"
            ? "#EDFECC"
            : type === "error"
            ? "#F5DEE3"
            : "#F0ECFF",
        borderColor:
          type === "success"
            ? "#86CA00"
            : type === "error"
            ? "#E3A8B4"
            : "#B49FF5",
      }}
    >
      <img
        src={
          type === "error" ? unSucTick : type === "success" ? sucTick : tPending
        }
        alt={
          type === "error"
            ? "Error Icon"
            : type === "success"
            ? "Success Icon"
            : "Pending Icon"
        }
        className="icon"
      />
      <p className="toast-header-text" style={{ color: "#000000" }}>
        {text}
      </p>
      <button className="toast-close-btn" onClick={closeToast}>
        <img src={tClose} alt="Close" />
      </button>
    </div>
  );
}
