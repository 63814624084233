import React, { useState, useEffect, useRef } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressRing from "../../Components/ProgressRing";
import chevBack from "../../assets/ion_chevron-back.svg";
import ImageVideoUploader from "../../Components/ImageVideoUploader";
import plus from "../../assets/plus.svg";
import Input from "../../Components/Input";
import { useFileUpload } from "../../context/uploadFile";
import ActivityIndicator from "../../Components/ActivityIndicator";
import uploadedImg from "../../assets/uploadedImg.svg";
import gallary from "../../assets/gallery.svg";
import trash from "../../assets/trash.svg";
import roundedCheck from "../../assets/rc.svg";
import uploadImg from "../../assets/upload.svg";
import { GetIndustries } from "../../RequestHandlers/Auth";
import Toast, { handleToast } from "../../Components/Toast";
import {
  GetProjects,
  GetProjectsToSpotlight,
} from "../../RequestHandlers/Collection";
import {
  AddSpotlightGallry,
  Login,
} from "../../RequestHandlers/AllStbrequest";
import { RequestHandler } from "../../RequestHandlers/StbRequest";
import { SpotlightProject } from "../../RequestHandlers/Spotlight";
import { ArrowDownIcon, CloseIcon } from "../../Components/iconComponents";

interface Row {
  title: string;
  link: string;
}
function CreateAffiliate() {
  const navigate = useNavigate();
    const location = useLocation();
    const { projectN, industry } = location.state || {}; 
  const type = "Talent";
  const imgFileInputRef = useRef<HTMLInputElement[]>([]);
  const videoFileInputRef = useRef<HTMLInputElement[]>([]);
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>(null);
  const fileInputRef = useRef(null);
  const [errors, setErrors] = useState<any>({});
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [fr, setFr] = useState<any>([]);
  const { uploadFile, isUploading, uploadError } = useFileUpload();
  const [isFocused, setIsFocused] = useState(false);
  const [aboutTalent, setAboutTalent] = useState("");
  const [tName, setTName] = useState("");
  const [activeIndustry, setActiveIndustry] = useState<any>(null);
  const [dropActive, setDropActive] = useState(false);
  const [industries, setIndustries] = useState<any>(null);
  const [projects, setProjects] = useState([]);
  const [projectIndustry, setProjectIndustry] = useState(industry||"");
  const [projectName, setProjectName] = useState(projectN ||"");
  const [isUploading1, setIsUploading1] = useState<boolean[]>([]);
  const [progress, setProgress] = useState<number[]>([]);
  const [isCompleted, setIsCompleted] = useState<boolean[]>([]);
  const [isVUploading1, setIsVUploading1] = useState<boolean[]>([]);
  const [vProgress, setVProgress] = useState<number[]>([]);
  const [isVCompleted, setIsVCompleted] = useState<boolean[]>([]);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
    const [spotlightId, setSpotlightId] = useState("");
    
    interface IncomeRange {
    id: number;
    name: string;
  }

  const [selectedIncomeRanges, setSelectedIncomeRanges] = useState<
    IncomeRange[]
  >([]);

  const [image, setImages] = useState([
    { fileName: "", link: "", fileSize: "", completed: false },
  ]);
  const [videos, setVideos] = useState([
    { fileName: "", link: "", fileSize: "", completed: false },
  ]);
  const [rows, setRows] = useState<Row[]>([
    { title: "", link: "" },
    { title: "", link: "" },
   
  ]);
  const numberofmonths = [
    { name: "3 Month", value: 3 },
    { name: "6 Months", value: 6 },
    { name: "9 Months", value: 9 },
    { name: "12 Months", value: 12 },
    { name: "15 Months", value: 15 },
  ];

  // Dummy Data for Target Investors (income range)
  const incomeRange = [
    { id: 1, name: "$0 - $50,000" },
    { id: 2, name: "$50,001 - $100,000" },
    { id: 3, name: "$100,001 - $250,000" },
    { id: 4, name: "$250,001 - $500,000" },
    { id: 5, name: "$500,001 - $1,000,000" },
    { id: 6, name: "$1,000,001+" },
    ];
    
  const handleSelect = (item: any) => {
    const isSelected = selectedIncomeRanges.includes(item);
    if (isSelected) {
      setSelectedIncomeRanges(
        selectedIncomeRanges.filter((range) => range !== item)
      );
    } else {
      setSelectedIncomeRanges([...selectedIncomeRanges, item]);
    }
  };

  const handleFieldChange = (field: string, value: string) => {
    console.log(field, value); // For demonstration, here you might update the form state
  };

  // Convert the selectedIncomeRanges array to a comma-separated string for handleFieldChange
  const selectedNames = selectedIncomeRanges
    .map((range) => range.name)
    .join(", ");

  // Remove selected income range when "X" icon is clicked
  const removeSelectedRange = (range: any) => {
    setSelectedIncomeRanges(selectedIncomeRanges.filter((r) => r !== range));
  };

  const handleAddMoreImage = () => {
    setImages([
      ...image,
      { fileName: "", link: "", fileSize: "", completed: false },
    ]);
  };
  const handleAddMoreVideo = () => {
    setVideos([
      ...videos,
      { fileName: "", link: "", fileSize: "", completed: false },
    ]);
  };
  const updateStateAtIndex = (
    setState: React.Dispatch<React.SetStateAction<any[]>>,
    index: number,
    value: any
  ) => {
    setState((prev) => {
      const updated = [...prev];
      updated[index] = value;
      return updated;
    });
  };

  // Initialize refs
  useEffect(() => {
    imgFileInputRef.current = image.map(
      (_, i) =>
        imgFileInputRef.current[i] || React.createRef<HTMLInputElement>()
    );
    videoFileInputRef.current = image.map(
      (_, i) =>
        videoFileInputRef.current[i] || React.createRef<HTMLInputElement>()
    );
  }, []);

//   const handleFieldChange = (field: string, value: string) => {
//     setErrors((prevErrors: any) => {
//       const updatedErrors = { ...prevErrors };
//       if (updatedErrors[field]) {
//         delete updatedErrors[field];
//       }
//       return updatedErrors;
//     });
//   };
  const handleUploadClick = () => {
    // @ts-ignore
    fileInputRef.current.click();
  };
  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const file = e.target.files[0];
        console.log(file);

        const data = await uploadFile(file);
        setUploadedFileUrl(data.secure_url);
        if (data.secure_url) {
          console.log("Uploaded file:", data.asset_id);
          setSelectedFile(data);
          handleFieldChange("coverImage", e.target.value);
        } else {
          setSelectedFile(null);
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };
  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto";
    const scrollHeight = textarea.scrollHeight;
    textarea.style.height = `${Math.min(scrollHeight, 130)}px`;
  };
  const getIndustries = async () => {
    try {
      const res = await GetIndustries();
      setIndustries(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getIndustries();
    window.scrollTo(0, 0);
  }, []);
  const handleImageSelect = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileSizeInKB = (file.size / 1024).toFixed(2);
      setImages((prev) =>
        prev.map((item, idx) =>
          idx === index
            ? {
                ...item,
                fileName: file.name,
                fileSize: `${fileSizeInKB} KB`,
              }
            : item
        )
      );
      updateStateAtIndex(setIsUploading1, index, true);
      let timer1 = 0;
      updateStateAtIndex(setProgress, index, 0);
      const intervalId = setInterval(() => {
        if (timer1 === 90) {
          clearInterval(intervalId);
        } else {
          timer1 += 10;
          updateStateAtIndex(setProgress, index, timer1);
        }
      }, 200);
      try {
        const data = await uploadFile(file);
        console.log("Uploaded file:", data);
        if (data.secure_url) {
          updateStateAtIndex(setProgress, index, 100);
          updateStateAtIndex(setIsCompleted, index, true);
          setImages((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    link: data.secure_url,
                    completed: true,
                  }
                : item
            )
          );
          handleFieldChange(`image${index}`, data.secure_url);
        } else {
          console.error("File upload failed: No secure_url received");
          setImages((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    fileName: "",
                    link: "",
                    fileSize: "",
                    completed: false,
                  }
                : item
            )
          );
        }
      } catch (error) {
        console.error("Upload failed:", error);
      } finally {
        updateStateAtIndex(setIsUploading1, index, false);
      }
    }
  };
  const handleVideoSelect = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      console.log(file);
      const fileSizeInKB = (file.size / 1024).toFixed(2);
      setVideos((prev) =>
        prev.map((item, idx) =>
          idx === index
            ? {
                ...item,
                fileName: file.name,
                fileSize: `${fileSizeInKB} KB`,
              }
            : item
        )
      );
      updateStateAtIndex(setIsVUploading1, index, true);
      let timer1 = 0;
      updateStateAtIndex(setVProgress, index, 0);
      const intervalId = setInterval(() => {
        if (timer1 === 90) {
          clearInterval(intervalId);
        } else {
          timer1 += 10;
          updateStateAtIndex(setVProgress, index, timer1);
        }
      }, 600);
      try {
        const data = await uploadFile(file);
        setUploadedFileUrl(data.secure_url);
        console.log("Uploaded file:", data);
        if (data.secure_url) {
          updateStateAtIndex(setVProgress, index, 100);
          updateStateAtIndex(setIsVCompleted, index, true);
          setVideos((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    link: data.secure_url,
                    completed: true,
                  }
                : item
            )
          );
          handleFieldChange(`video${index}`, data.secure_url);
        } else {
          console.error("File upload failed: No secure_url received");
          setVideos((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    fileName: "",
                    link: "",
                    fileSize: "",
                    completed: false,
                  }
                : item
            )
          );
        }
      } catch (error) {
      } finally {
        updateStateAtIndex(setIsVUploading1, index, false);
      }
    }
  };
  const handleImageDelete = (index: number, fileName: string) => {
    updateStateAtIndex(setIsCompleted, index, false);
    setImages((prev: any) => {
      const updatedImages = prev.filter((_: any, idx: any) => idx !== index);
      updateStateAtIndex(setIsCompleted, index, false);
      if (updatedImages.length === 0) {
        return [
          {
            fileName: "",
            link: "",
            fileSize: "",
            completed: false,
          },
        ];
      }
      return updatedImages;
    });
  };
  const handleVideoDelete = (index: number, fileName: any) => {
    updateStateAtIndex(setIsVCompleted, index, false);
    setVideos((prev: any) => {
      const updatedImages = prev.filter((_: any, idx: any) => idx !== index);
      updateStateAtIndex(setIsVCompleted, index, false);
      if (updatedImages.length === 0) {
        return [
          {
            fileName: "",
            link: "",
            fileSize: "",
            completed: false,
          },
        ];
      }
      return updatedImages;
    });
  };

  const spotlight = async (id: any, body: object) => {
    setLoading(true);
    try {
      const res = await SpotlightProject(id, body);
      setSpotlightId(res.id);
      if (res.id) {
        navigate("/SpotLightAdded");
        // uploadMedia(res.id);
      } else {
        handleToast(res.message, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const uploadMedia = async (id: any) => {
    const totalItems = image.length + videos.length;
    let uploadedCount = 0;
    let hasError = false;
    try {
      for (const img of image) {
        if (img.link && img.link !== "") {
          const res = await AddSpotlightGallry(
            id,
            { link: img.link, type: "image" },
            token
          );
          console.log("img", res);
          if (res.error) {
            handleToast(res.message, "error");
            hasError = true;
          } else {
            uploadedCount++;
          }
        }
      }
      for (const vid of videos) {
        if (vid.link && vid.link !== "") {
          const res = await AddSpotlightGallry(
            id,
            { link: vid.link, type: "video" },
            token
          );
          console.log("vid", res);
          if (res.error) {
            handleToast(res.message, "error");
            hasError = true;
          } else {
            uploadedCount++;
          }
        }
      }
      if (uploadedCount === totalItems) {
        navigate("/SpotLightAdded");
      } else if (hasError && uploadedCount > 0) {
        handleToast("Some media failed to upload. Please retry.", "error");
      } else {
        handleToast("Upload failed. Please try again.", "error");
      }
    } catch (error) {
      console.log("Error uploading media:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: Record<string, string> = {};
    if (projectName.trim() === "") {
      newErrors.title = "Field is required";
    }
    if (projectIndustry.trim() === "") {
      newErrors.title = "Field is required";
    }
    if (uploadedFileUrl === null) {
      newErrors.coverImage = "Field is required";
    }
    if (aboutTalent.trim() === "") {
      newErrors.aboutTalent = "Field is required";
    }
    if (activeIndustry === null) {
      newErrors.activeIndustry = "Field is required";
    }
    const hasUploadedFile =
      image.some((item) => item.link) || videos.some((item) => item.link);
    if (!hasUploadedFile) {
      newErrors.uploadedFiles =
        "At least one image or video needs to be uploaded";
    }
    handleToast("Upload at least 1 image or video", "error");
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    const videoLinks = videos
      .map((video: any) => ({
        link: video.link,
        type: "video",
      }))
      .filter((video) => video.link !== "");

    const imageLinks = image
      .map((image: any) => ({
        link: image.link,
        type: "image",
      }))
      .filter((image) => image.link !== "");
    const allLinks = [...videoLinks, ...imageLinks];
    const body = {
      title: projectName,
      subtTitle: activeIndustry.companyName,
      cover: uploadedFileUrl,
      coverType: "image",
      description: aboutTalent,
      type: type,
      galaries: allLinks,
    };
    console.log("Form submitted successfully:", body);
    spotlight(activeIndustry.id, body);
  };

  useEffect(() => {
    handleToast("trev", "success");
    fetch("https://usestableblocks.com/funding_round/")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setFr(data);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  const getProject = async () => {
    try {
      const res = await GetProjectsToSpotlight(1, 20, "active");
      if (res.items) {
        setProjects(res.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loginStb = async () => {
    try {
      const res = await Login({
        email: "scot@mailinator.com",
        password: "Password123#",
      });
      setToken(res.token);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getProject();
    loginStb();
  }, []);
    
    
    // new ROI

     const handleDropdownChange = (
       index: number,
       selectedOption: { name: string; value: any }
     ) => {
       const updatedRows = [...rows];
       updatedRows[index].title = selectedOption.value;
       setRows(updatedRows);
     };

      const handleInputChange = (
        index: number,
        field: keyof Row,
        value: string
      ) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
    };

    const handleAddRow = () => {
      setRows([...rows, { title: "", link: "" }]);
    };

      const handleRemoveRow = () => {
        if (rows.length > 2) {
          setRows((prevRows) => prevRows.slice(0, -1)); // Remove the last row immutably
        } else {
          alert("At least 2 periods are required.");
        }
    };
     const getFilteredOptions = (index: number) => {
       const selectedValues = rows
         .map((row, rowIndex) => (rowIndex !== index ? row.title : null)) // Exclude the current row's selected value
         .filter((value) => value !== null); // Filter out nulls
       console.log(selectedValues);

       return numberofmonths.filter(
         // @ts-ignore
         (month) => !selectedValues.includes(month.value)
       );
     };
    
  return (
    <>
      <Toast />
      <div className="dash-body">
        <SideNav activeTab="Affiliate program" />
        <div className="content-body">
          <ProfileNav />
          <div
            className="c-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={chevBack} alt="" />
            <p>Back</p>
          </div>
          <div className="sec-header">
            <p>Create Affiliate Network</p>
          </div>
          <div className="list-content-holder">
            
            
            <div className="content-cont">
              <h3 className="text-white text-lg mb-4">Project info</h3>
              {/* Project Name */}
              <div
                className="input-holder"
                style={{ marginTop: 16, width: "100%" }}
              >
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Project Name"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={projectName}
                  onChange={(e: any) => {
                    setProjectName(e.target.value);
                    handleFieldChange("title", e.target.value);
                  }}
                  error={errors.title}
                  errorText={errors.title}
                />
              </div>
              {/* Project Industry */}
              <div
                className="input-holder"
                style={{ marginTop: 16, width: "100%" }}
              >
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Project Industry"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={projectIndustry}
                  onChange={(e: any) => {
                    setProjectIndustry(e.target.value);
                    handleFieldChange("title", e.target.value);
                  }}
                  error={errors.title}
                  errorText={errors.title}
                />
              </div>
            </div>
            {/* ----------------------------============================================== */}
            <div className="content-cont">
              <h3 className="text-white text-lg mb-4">
                Affiliate network info
              </h3>

              {/* Target income range */}
              <div className="dropdown-holder mt-4" style={{ width: "100%" }}>
                <div
                  className={`reg-file-cont drop-down ${
                    selectedIncomeRanges.length > 0 ? "focused" : ""
                  } ${errors.selectedIncomeRanges ? "img-cont-error" : ""}`}
                  onClick={() => {
                    setDropActive(!dropActive);
                  }}
                >
                  <label className="floating-label !text-sm">
                    Target Investors (income range)
                  </label>
                  <div className="active-ind w-full flex !justify-start !items-end">
                    {selectedIncomeRanges.length > 0 ? (
                      selectedIncomeRanges.map((range) => (
                        <div className="flex items-center w-full">
                          <span
                            key={range.name}
                            className="selected-tag !text-base mr-3"
                          >
                            {range.name}
                            {/* Add the "X" delete icon */}
                          </span>
                          <span
                            className="delete-icon flex justify-center items-center size-[15px] bg-white rounded-full"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent dropdown from toggling
                              removeSelectedRange(range); // Remove the item
                              handleFieldChange(
                                "selectedIncomeRanges",
                                selectedNames
                              ); // Update the field value
                            }}
                          >
                           <CloseIcon />
                          </span>
                        </div>
                      ))
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <div className="chev">
                    <ArrowDownIcon />
                  </div>
                </div>

                {errors.selectedIncomeRanges && (
                  <p className="error-text">{errors.selectedIncomeRanges}</p>
                )}

                {dropActive && (
                  <div className="in-drop-down">
                    {incomeRange.map((item, index) => (
                      <div
                        key={index}
                        className={`ind ${
                          selectedIncomeRanges.includes(item)
                            ? "ind-active"
                            : ""
                        }`}
                        onClick={() => handleSelect(item)}
                      >
                        {item.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* Target Region */}
              <div className="dropdown-holder mt-4" style={{ width: "100%" }}>
                <div
                  className={`reg-file-cont drop-down ${
                    selectedIncomeRanges.length > 0 ? "focused" : ""
                  } ${errors.selectedIncomeRanges ? "img-cont-error" : ""}`}
                  onClick={() => {
                    setDropActive(!dropActive);
                  }}
                >
                  <label className="floating-label !text-sm">
                    Target Region
                  </label>
                  <div className="active-ind w-full flex !justify-start !items-end">
                    {selectedIncomeRanges.length > 0 ? (
                      selectedIncomeRanges.map((range) => (
                        <div className="flex items-center w-full">
                          <span
                            key={range.name}
                            className="selected-tag !text-base mr-3"
                          >
                            {range.name}
                            {/* Add the "X" delete icon */}
                          </span>
                          <span
                            className="delete-icon flex justify-center items-center size-[15px] bg-white rounded-full"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent dropdown from toggling
                              removeSelectedRange(range); // Remove the item
                              handleFieldChange(
                                "selectedIncomeRanges",
                                selectedNames
                              ); // Update the field value
                            }}
                          >
                           <CloseIcon />
                          </span>
                        </div>
                      ))
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <div className="chev">
                    <ArrowDownIcon />
                  </div>
                </div>

                {errors.selectedIncomeRanges && (
                  <p className="error-text">{errors.selectedIncomeRanges}</p>
                )}

                {dropActive && (
                  <div className="in-drop-down">
                    {incomeRange.map((item, index) => (
                      <div
                        key={index}
                        className={`ind ${
                          selectedIncomeRanges.includes(item)
                            ? "ind-active"
                            : ""
                        }`}
                        onClick={() => handleSelect(item)}
                      >
                        {item.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
{/* Target Age Group */}
              <div className="dropdown-holder mt-4" style={{ width: "100%" }}>
                <div
                  className={`reg-file-cont drop-down ${
                    selectedIncomeRanges.length > 0 ? "focused" : ""
                  } ${errors.selectedIncomeRanges ? "img-cont-error" : ""}`}
                  onClick={() => {
                    setDropActive(!dropActive);
                  }}
                >
                  <label className="floating-label !text-sm">
                    Target Age Group
                  </label>
                  <div className="active-ind w-full flex !justify-start !items-end">
                    {selectedIncomeRanges.length > 0 ? (
                      selectedIncomeRanges.map((range) => (
                        <div className="flex items-center w-full">
                          <span
                            key={range.name}
                            className="selected-tag !text-base mr-3"
                          >
                            {range.name}
                            {/* Add the "X" delete icon */}
                          </span>
                          <span
                            className="delete-icon flex justify-center items-center size-[15px] bg-white rounded-full"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent dropdown from toggling
                              removeSelectedRange(range); // Remove the item
                              handleFieldChange(
                                "selectedIncomeRanges",
                                selectedNames
                              ); // Update the field value
                            }}
                          >
                           <CloseIcon />
                          </span>
                        </div>
                      ))
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <div className="chev">
                    <ArrowDownIcon />
                  </div>
                </div>

                {errors.selectedIncomeRanges && (
                  <p className="error-text">{errors.selectedIncomeRanges}</p>
                )}

                {dropActive && (
                  <div className="in-drop-down">
                    {incomeRange.map((item, index) => (
                      <div
                        key={index}
                        className={`ind ${
                          selectedIncomeRanges.includes(item)
                            ? "ind-active"
                            : ""
                        }`}
                        onClick={() => handleSelect(item)}
                      >
                        {item.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="flex items-center gap-4">
                <div
                  className="input-holder !text-sm"
                  style={{ marginTop: 16, width: "100%" }}
                >
                  <Input
                    contClass="list-input-cont"
                    extraClass="list-input !text-lg"
                    label="Affiliate Network Name"
                    contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                    value={projectIndustry}
                    onChange={(e: any) => {
                      setProjectIndustry(e.target.value);
                      handleFieldChange("title", e.target.value);
                    }}
                    error={errors.title}
                    errorText={errors.title}
                  />
                </div>

                <div
                  className="input-holder !text-sm"
                  style={{ marginTop: 16, width: "100%" }}
                >
                  <Input
                    contClass="list-input-cont"
                    extraClass="list-input !text-lg"
                    label="Affiliate Network Duration"
                    contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                    value={projectIndustry}
                    onChange={(e: any) => {
                      setProjectIndustry(e.target.value);
                      handleFieldChange("title", e.target.value);
                    }}
                    error={errors.title}
                    errorText={errors.title}
                  />
                </div>
              </div>

              <div
                className={`abt-talent-cont ${
                  errors.aboutTalent ? "img-cont-error" : ""
                }`}
              >
                <div className={`abt-label ${isFocused ? "abt-label1" : ""}`}>
                  Affiliate Description (why are you creating this affiliate
                  network?)
                </div>
                <textarea
                  ref={textareaRef}
                  className="abt-talent-text"
                  value={aboutTalent}
                  onChange={(e) => {
                    setAboutTalent(e.target.value);
                    adjustHeight(e.target);
                    handleFieldChange("aboutTalent", e.target.value);
                  }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={(e) => setIsFocused(aboutTalent != "" ? true : false)}
                />
              </div>
              {errors.aboutTalent && (
                <p className="error-text">{errors.aboutTalent}</p>
              )}

              <div className="flex gap-4 items-center">
                <div className="dropdown-holder mt-4" style={{ width: "100%" }}>
                  <div
                    className={`reg-file-cont drop-down ${
                      selectedIncomeRanges.length > 0 ? "focused" : ""
                    } ${errors.selectedIncomeRanges ? "img-cont-error" : ""}`}
                    onClick={() => {
                      setDropActive(!dropActive);
                    }}
                  >
                    <label className="floating-label !text-sm">
                      Commission Structure
                    </label>
                    <div className="active-ind w-full flex !justify-start !items-end">
                      {selectedIncomeRanges.length > 0 ? (
                        selectedIncomeRanges.map((range) => (
                          <div className="flex items-center w-full">
                            <span
                              key={range.name}
                              className="selected-tag !text-base mr-3"
                            >
                              {range.name}
                              {/* Add the "X" delete icon */}
                            </span>
                            <span
                              className="delete-icon flex justify-center items-center size-[15px] bg-white rounded-full"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent dropdown from toggling
                                removeSelectedRange(range); // Remove the item
                                handleFieldChange(
                                  "selectedIncomeRanges",
                                  selectedNames
                                ); // Update the field value
                              }}
                            >
                              <svg
                                width="16"
                                height="15"
                                viewBox="0 0 16 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="0.5"
                                  width="15"
                                  height="15"
                                  rx="7.5"
                                  fill="white"
                                />
                                <path
                                  d="M5.07812 4.58594L11.0122 10.4367"
                                  stroke="#030500"
                                  stroke-width="0.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M11.0156 4.58594L5.08154 10.4367"
                                  stroke="#030500"
                                  stroke-width="0.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                          </div>
                        ))
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <div className="chev">
                      <ArrowDownIcon />
                    </div>
                  </div>

                  {errors.selectedIncomeRanges && (
                    <p className="error-text">{errors.selectedIncomeRanges}</p>
                  )}

                  {dropActive && (
                    <div className="in-drop-down">
                      {incomeRange.map((item, index) => (
                        <div
                          key={index}
                          className={`ind ${
                            selectedIncomeRanges.includes(item)
                              ? "ind-active"
                              : ""
                          }`}
                          onClick={() => handleSelect(item)}
                        >
                          {item.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div
                  className="input-holder !text-sm"
                  style={{ marginTop: 16, width: "100%" }}
                >
                  <Input
                    contClass="list-input-cont"
                    extraClass="list-input !text-lg"
                    label="Flat fee - $ per project unit funded"
                    contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                    value={projectIndustry}
                    onChange={(e: any) => {
                      setProjectIndustry(e.target.value);
                      handleFieldChange("title", e.target.value);
                    }}
                    error={errors.title}
                    errorText={errors.title}
                  />
                </div>
              </div>

              <div className="dropdown-holder mt-4 w-[calc(50%-8px)]">
                <div
                  className={`reg-file-cont drop-down ${
                    selectedIncomeRanges.length > 0 ? "focused" : ""
                  } ${errors.selectedIncomeRanges ? "img-cont-error" : ""}`}
                  onClick={() => {
                    setDropActive(!dropActive);
                  }}
                >
                  <label className="floating-label !text-sm">
                    Payout Frequency
                  </label>
                  <div className="active-ind w-full flex !justify-start !items-end">
                    {selectedIncomeRanges.length > 0 ? (
                      selectedIncomeRanges.map((range) => (
                        <div className="flex items-center w-full">
                          <span
                            key={range.name}
                            className="selected-tag !text-base mr-3"
                          >
                            {range.name}
                            {/* Add the "X" delete icon */}
                          </span>
                          <span
                            className="delete-icon flex justify-center items-center size-[15px] bg-white rounded-full"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent dropdown from toggling
                              removeSelectedRange(range); // Remove the item
                              handleFieldChange(
                                "selectedIncomeRanges",
                                selectedNames
                              ); // Update the field value
                            }}
                          >
                           <CloseIcon />
                          </span>
                        </div>
                      ))
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <div className="chev">
                    <ArrowDownIcon />
                  </div>
                </div>

                {errors.selectedIncomeRanges && (
                  <p className="error-text">{errors.selectedIncomeRanges}</p>
                )}

                {dropActive && (
                  <div className="in-drop-down">
                    {incomeRange.map((item, index) => (
                      <div
                        key={index}
                        className={`ind ${
                          selectedIncomeRanges.includes(item)
                            ? "ind-active"
                            : ""
                        }`}
                        onClick={() => handleSelect(item)}
                      >
                        {item.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div
                className={`img-upload-cont ${
                  selectedFile ? "img-upload-cont1" : ""
                } ${errors.coverImage ? "img-cont-error" : ""}`}
                onClick={() => {
                  handleUploadClick();
                }}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                />
                <div className="upp-nav">
                  <p>Upload Project Assets (logo, flyers, banners)</p>
                  <>
                    {selectedFile || isUploading ? (
                      <>
                        {isUploading ? (
                          <ActivityIndicator size="24px" color="#a7fc00" />
                        ) : (
                          <img src={roundedCheck} />
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </div>
                {/* {selectedFile || isUploading ? (
                ) : (
                  <></>
                )} */}
                <img src={selectedFile ? uploadedImg : uploadImg} alt="" />
                <p>
                  {" "}
                  {selectedFile
                    ? "Image Uploaded Successfully"
                    : "Upload Business Related Image "}
                </p>
                <span
                  onClick={() => {
                    if (uploadError) {
                    }
                  }}
                >
                  {selectedFile
                    ? `${selectedFile?.original_filename}.${selectedFile?.format}`
                    : uploadError
                    ? "Try again"
                    : "(375*150 recommended)"}
                </span>
                {selectedFile && (
                  <div className="bbb-nav">
                    <div className="nn-wrap">
                      <img src={gallary} alt="" />
                      <p>
                        {selectedFile?.original_filename}.{selectedFile?.format}
                      </p>
                    </div>
                    <img
                      src={trash}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedFile(null);
                      }}
                    />
                  </div>
                )}
              </div>

              {errors.aboutTalent && (
                <p className="error-text">{errors.aboutTalent}</p>
              )}
            </div>
            {/* -----------------------====================================------------------------ */}
            <div className="content-cont !pt-4">
              <div
                className={`abt-talent-cont ${
                  errors.aboutTalent ? "img-cont-error" : ""
                }`}
              >
                <div className={`abt-label ${isFocused ? "abt-label1" : ""}`}>
                  Brand Messaging (Key messages and unique talking points about
                  the project)
                </div>
                <textarea
                  ref={textareaRef}
                  className="abt-talent-text"
                  value={aboutTalent}
                  onChange={(e) => {
                    setAboutTalent(e.target.value);
                    adjustHeight(e.target);
                    handleFieldChange("aboutTalent", e.target.value);
                  }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={(e) => setIsFocused(aboutTalent != "" ? true : false)}
                />
              </div>
            </div>
            {/* =================++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}

            <div className="content-cont">
              <p className="ov">
                Links (project website link, links to posts/ articles about the
                project)
              </p>
              {rows.map((item, index) => {
                return (
                  <div className="!w-full">
                    <div className="input-holder !w-full !mt-4">
                      <Input
                        contClass="list-input-cont"
                        extraClass="list-input"
                        label="Title"
                        contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                        value={item.title}
                        onChange={(e) => {
                          handleInputChange(index, "title", e.target.value);
                          handleFieldChange(`roi${index}`, item.title);
                        }}
                        error={!!errors[`roi${index}`]} // Highlight the input in error
                        errorText={errors[`roi${index}`]}
                      />
                    </div>

                    <div className="input-holder !w-full !mt-4">
                      <Input
                        contClass="list-input-cont"
                        extraClass="list-input"
                        label="Enter Link"
                        contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                        value={item.link}
                        onChange={(e) => {
                          handleInputChange(index, "link", e.target.value);
                          handleFieldChange(`roi${index}`, item.link);
                        }}
                        error={!!errors[`roi${index}`]} // Highlight the input in error
                        errorText={errors[`roi${index}`]}
                      />
                    </div>

                    {/* add a Romove  text with onclick handleRemoveRow */}
                    <div className="flex justify-end mt-4 w-full">
                      <div
                        className="remove-btn text-[red] text-sm"
                        onClick={() => {
                          handleRemoveRow();
                        }}
                      >
                        Delete
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="dash"></div>
              <div className="inc-btn-cont">
                <div
                  className="add-btn"
                  onClick={() => {
                    handleAddRow();
                  }}
                >
                  <img src={plus} alt="" /> Add more
                </div>
              </div>
            </div>

            {/* ======================================================== */}
            <div className="content-cont">
              <p className="ov">Gallery</p>
              <div className="gallary-wrap">
                <div className="gal-hold">
                  {image.map((item, index) => {
                    return (
                      <ImageVideoUploader
                        topText="Upload Image"
                        isUploading={isUploading1[index] || false}
                        isCompleted={item.completed}
                        progress={progress[index]}
                        fileInputRef={(el: any) =>
                          (imgFileInputRef.current[index] = el)
                        }
                        accept="image/*"
                        handleFileSelect={(e: any) => {
                          handleImageSelect(index, e);
                        }}
                        del={(e: any) => {
                          e.stopPropagation();
                          handleImageDelete(index, item.fileName);
                        }}
                        type="image"
                        fileName={item.fileName}
                        fileSize={item.fileSize}
                        onContainerClick={() => {
                          imgFileInputRef.current[index].click();
                        }}
                      />
                    );
                  })}
                  <div className="inc-btn-cont">
                    <div
                      className="add-btn"
                      onClick={() => {
                        handleAddMoreImage();
                      }}
                    >
                      <img src={plus} alt="" /> Add Image
                    </div>
                  </div>
                </div>
                <div className="gal-hold">
                  {videos.map((item, index) => {
                    return (
                      <ImageVideoUploader
                        topText="Upload Video"
                        isUploading={isVUploading1[index] || false}
                        isCompleted={item.completed}
                        progress={vProgress[index]}
                        fileInputRef={(el: any) =>
                          (videoFileInputRef.current[index] = el)
                        }
                        type="video"
                        accept="video/*"
                        handleFileSelect={(e: any) => {
                          handleVideoSelect(index, e);
                        }}
                        del={(e: any) => {
                          e.stopPropagation();
                          handleVideoDelete(index, item.fileName);
                        }}
                        fileName={item.fileName}
                        fileSize={item.fileSize}
                        onContainerClick={() => {
                          videoFileInputRef.current[index].click();
                        }}
                      />
                    );
                  })}
                  <div className="inc-btn-cont">
                    <div
                      className="add-btn"
                      onClick={() => {
                        handleAddMoreVideo();
                      }}
                    >
                      <img src={plus} alt="" /> Add Video
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* -----------------------====================================------------------------ */}
            <div className="content-cont !pt-4">
              <div
                className={`abt-talent-cont ${
                  errors.aboutTalent ? "img-cont-error" : ""
                }`}
              >
                <div className={`abt-label ${isFocused ? "abt-label1" : ""}`}>
                  Affiliate network guidelines (rules you want all affiliates to
                  abide by)
                </div>
                <textarea
                  ref={textareaRef}
                  className="abt-talent-text"
                  value={aboutTalent}
                  onChange={(e) => {
                    setAboutTalent(e.target.value);
                    adjustHeight(e.target);
                    handleFieldChange("aboutTalent", e.target.value);
                  }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={(e) => setIsFocused(aboutTalent != "" ? true : false)}
                />
              </div>
            </div>

            <div className="content-cont !pt-4">
              <div className="text-white !font-[300] !text-lg">
                <p>Affiliate support channel </p>
              </div>
              <div className="flex gap-4 items-center">
                <div
                  className="input-holder !text-sm"
                  style={{ marginTop: 16, width: "100%" }}
                >
                  <Input
                    contClass="list-input-cont"
                    extraClass="list-input !text-lg"
                    label="Your Email"
                    contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                    value={projectIndustry}
                    onChange={(e: any) => {
                      setProjectIndustry(e.target.value);
                      handleFieldChange("title", e.target.value);
                    }}
                    error={errors.title}
                    errorText={errors.title}
                  />
                </div>
                <div
                  className="input-holder !text-sm"
                  style={{ marginTop: 16, width: "100%" }}
                >
                  <Input
                    contClass="list-input-cont"
                    extraClass="list-input !text-lg"
                    label="Phone Number"
                    contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                    value={projectIndustry}
                    onChange={(e: any) => {
                      setProjectIndustry(e.target.value);
                      handleFieldChange("title", e.target.value);
                    }}
                    error={errors.title}
                    errorText={errors.title}
                  />
                </div>
              </div>
            </div>

            <div className="std-cont">
              {/* <div className="std-btn">Save to draft</div> */}
              <div
                className={`c-btn ${loading ? "c-disabled" : "!w-[127px]"}`}
                onClick={handleFormSubmit}
              >
                {loading ? (
                  <div className="dot-loader">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                  </div>
                ) : (
                  "Create network"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateAffiliate;
