import React, { useState, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import HomeScreen from "./Screens/HomeScreen";
import WhatIsTokenizationScreen from "./Screens/WhatIsTokenizationScreen";
import TokenizeWithUs from "./Screens/TokenizeWithUs";
import EmailSentScreen from "./Screens/EmailSentScreen";
import SignUpScreen from "./Screens/SignUpScreen";
import VerifyEmailScreen from "./Screens/VerifyEmailScreen";
import SignUpScreen2 from "./Screens/SignUpScreen2";
import CreatePassword from "./Screens/CreatePassword";
import AccountCreatedScreen from "./Screens/AccountCreatedScreen";
import LoginScreen from "./Screens/LoginScreen";
import ForgotPassword from "./Screens/ForgotPasswordScreen";
import VerifyEmailScreen2 from "./Screens/VeriyEmailScreen2";
import ResetPassword from "./Screens/ResetPasswordScreen";
import PasswordResetSuccess from "./Screens/PasswordResetSuccess";
import Cookies from "js-cookie";
import { CredentailsContext } from "./context/CredentailContext";
import Dashboard from "./Screens/Dashboard";
import ProjectScreen from "./Screens/Projects/ProjectScreen";
import ListProjectPromtScreen from "./Screens/Projects/ListProjectPromtScreen";
import ListTalent from "./Screens/Projects/ListTalent/ListTalent";
import ListTalent2 from "./Screens/Projects/ListTalent/ListTalent2";
import CompanyDetails from "./Screens/Projects/ListTalent/CompanyDetails";
import CompanyDetails2 from "./Screens/Projects/ListTalent/CompanyDetails2";
import ProjectListed from "./Screens/Projects/ProjectListed";
import ListProject from "./Screens/Projects/ListProject/ListProject";
import ListProject2 from "./Screens/Projects/ListProject/ListProject2";
import ProjectCompanyDetails from "./Screens/Projects/ListProject/ProjectCompanyDetails";
import ProjectCompanyDetails2 from "./Screens/Projects/ListProject/ProjectCompanyDetails2";
import { FileUploadProvider } from "./context/uploadFile";
import NotificationScreen from "./Screens/NotificationScreen";
import ProfileScreen from "./Screens/Profile";
import Voucher from "./Screens/Vouchers/Voucher";
import VoucherDeleted from "./Screens/Vouchers/VoucherDeleted";
import IssueReported from "./Screens/Vouchers/IssueReported";
import VoucherCashback from "./Screens/Vouchers/VoucherCashback";
import VoucherHolders from "./Screens/Vouchers/VoucherHolders";
import PublishedVoucher from "./Screens/Vouchers/PublishedVoucher";
import AllCashbackRequest from "./Screens/Vouchers/AllCashbackRequest";
import VoucherActivity from "./Screens/Vouchers/VoucherActivity";
import CreateNewVoucher1 from "./Screens/Vouchers/CreateNewVoucher1";
import CreateNewVoucher2 from "./Screens/Vouchers/CreateNewVoucher2";
import CreateNewVoucher3 from "./Screens/Vouchers/CreateNewVoucher3";
import VoucherCreated from "./Screens/Vouchers/VoucherCreated";
import Spotlight from "./Screens/Spotlight/Spotlight";
import AllSpotlight from "./Screens/Spotlight/AllSpotlight";
import ListSpotlightPromtScreen from "./Screens/Spotlight/ListSpotlightPromtScreen";
import SpotlightDetail from "./Screens/Spotlight/SpotlightDetail";
import Reward from "./Screens/Rewards/Reward";
import ListReward from "./Screens/Rewards/ListReward";
import RewardListed from "./Screens/Rewards/RewardListed";
import CreateProjectSpotlight from "./Screens/Spotlight/spotlightProject/CreateProjectSpotlight";
import CreateProjectSpotlight2 from "./Screens/Spotlight/spotlightProject/CreateProjectSpotlight2";
import CreateTalentSpotlight from "./Screens/Spotlight/spotlightTalent/CreateTalentSpotlight";
import CreateTalentSpotlight2 from "./Screens/Spotlight/spotlightTalent/CreateTalentSpotlight2";
import CreateOwnerSpotlight from "./Screens/Spotlight/spotlightOwner/CreateOwnerSpotlight";
import CreateOwnerSpotlight2 from "./Screens/Spotlight/spotlightOwner/CreateOwnerSpolight2";
import Support from "./Screens/Suppot/Suppot";
import SearchScreen from "./Screens/SearchScreen";
import ProjectDetailsScreen from "./Screens/Projects/ProjectDetailsScreen";
import SettingsScreen from "./Screens/Settings/Settings";
import BulkEmailSuccess from "./Screens/Settings/bulk-emails/bulk-emails-success";
import SettingsContextProvider from "./context/SettingsContext";
import OnboardingCategory from "./Screens/onboarding/OnboardingCategory";
import OnboardingJurisdiction from "./Screens/onboarding/OnboardingJurisdiction";
import SpotLightAdded from "./Screens/Spotlight/SpolightAdded";
import AffiliateScreen from "./Screens/AffiliateProgram/AffiliateScreen";
import CreateAffiliate from "./Screens/AffiliateProgram/CreateAffiliate";
import PayoutScreen from "./Screens/Payout/PayoutScreen";
import TransactionHistory from "./Screens/Payout/TransactionHistory";
import Withdraw from "./Screens/Payout/Withdraw";
import PricingScreen from "./Screens/PricingScreen";
import Compliance from "./Screens/Compliance/Compliance";
import CreateDao from "./Screens/Compliance/CreateDao";
import CreateDao2 from "./Screens/Compliance/CreateDao2";
import DaoDahsboard from "./Screens/Compliance/DaoDahsboard";
import DaoContent from "./Screens/Compliance/DaoContent";
import DaoSmartContract from "./Screens/Compliance/DaoSmartContract";
import DaoDocument from "./Screens/Compliance/DaoDocument";
import EmailDAOSentScreen from "./Screens/EmailDAOSentScreen";

function App() {
  const [storedCredentails, setStoredCredentails] = useState(null);
  const credentials = Cookies.get("webCook");

  useEffect(() => {
    const credentials = Cookies.get("webCook");
    if (credentials) {
      setStoredCredentails(JSON.parse(credentials));
    }
  }, []);
  // PrivateRoute: Ensures only authenticated users can access certain routes
  const PrivateRoute = ({ children }: any) => {
    return credentials ? children : <Navigate to="/" />;
  };

  // RedirectLoggedIn: Redirects logged-in users to Dashboard for public routes
  const RedirectLoggedIn = ({ children }: any) => {
    return credentials ? <Navigate to="/Dashboard" /> : children;
  };

  return (
    <CredentailsContext.Provider
      // @ts-ignore
      value={{ storedCredentails, setStoredCredentails }}
    >
      <FileUploadProvider>
        <HelmetProvider>
        <Router>
          <Routes>
            {/* Public Routes */}
            <Route
              path="/"
              element={
                <RedirectLoggedIn>
                  <HomeScreen />
                </RedirectLoggedIn>
              }
            />
            <Route
              path="/pricing"
              element={
                <RedirectLoggedIn>
                  <PricingScreen />
                </RedirectLoggedIn>
              }
            />
            <Route
              path="/whatIsTokenization"
              element={
                <RedirectLoggedIn>
                  <WhatIsTokenizationScreen />
                </RedirectLoggedIn>
              }
            />
            <Route
              path="/tokenizewithus"
              element={
                <RedirectLoggedIn>
                  <TokenizeWithUs />
                </RedirectLoggedIn>
              }
            />
            <Route
              path="/emailsent"
              element={
                <RedirectLoggedIn>
                  <EmailSentScreen />
                </RedirectLoggedIn>
              }
            />
            <Route
              path="/OnboardingCategory"
              element={
                <RedirectLoggedIn>
                  <OnboardingCategory />
                </RedirectLoggedIn>
              }
            />
            <Route
              path="/OnboardingJurisdiction"
              element={
                <RedirectLoggedIn>
                  <OnboardingJurisdiction />
                </RedirectLoggedIn>
              }
            />
            <Route
              path="/SignUpScreen"
              element={
                <RedirectLoggedIn>
                  <SignUpScreen />
                </RedirectLoggedIn>
              }
            />
            <Route
              path="/VerifyEmailScreen"
              element={
                <RedirectLoggedIn>
                  <VerifyEmailScreen />
                </RedirectLoggedIn>
              }
            />
            <Route
              path="/SignUpScreen2"
              element={
                <RedirectLoggedIn>
                  <SignUpScreen2 />
                </RedirectLoggedIn>
              }
            />
            <Route
              path="/CreatePasswordScreen"
              element={
                <RedirectLoggedIn>
                  <CreatePassword />
                </RedirectLoggedIn>
              }
            />
            <Route
              path="/LoginScreen"
              element={
                <RedirectLoggedIn>
                  <LoginScreen />
                </RedirectLoggedIn>
              }
            />
            <Route
              path="/ForgotPassword"
              element={
                <RedirectLoggedIn>
                  <ForgotPassword />
                </RedirectLoggedIn>
              }
            />
            <Route
              path="/FpVerifyEmailScreen"
              element={
                <RedirectLoggedIn>
                  <VerifyEmailScreen2 />
                </RedirectLoggedIn>
              }
            />
            <Route
              path="/ResetPassword"
              element={
                <RedirectLoggedIn>
                  <ResetPassword />
                </RedirectLoggedIn>
              }
            />
            <Route
              path="/PasswordResetSuccess"
              element={
                <RedirectLoggedIn>
                  <PasswordResetSuccess />
                </RedirectLoggedIn>
              }
            />
            {/* Protected Routes */}
            <Route
              path="/Dashboard"
              element={
                // <PrivateRoute>
                <Dashboard />
                // </PrivateRoute>
              }
            />
            <Route
              path="/Project"
              element={
                <PrivateRoute>
                  <ProjectScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/Compliance"
              element={
                <PrivateRoute>
                  <Compliance />
                </PrivateRoute>
              }
            />
            <Route
              path="/DaoDahsboard"
              element={
                <PrivateRoute>
                  <DaoDahsboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/DaoContent"
              element={
                <PrivateRoute>
                  <DaoContent />
                </PrivateRoute>
              }
            />
            <Route
              path="/CreateDao"
              element={
                <PrivateRoute>
                  <CreateDao />
                </PrivateRoute>
              }
            />
            <Route
              path="/CreateDao2"
              element={
                <PrivateRoute>
                  <CreateDao2 />
                </PrivateRoute>
              }
            />
            <Route
              path="/DaoSmartContract"
              element={
                <PrivateRoute>
                  <DaoSmartContract />
                </PrivateRoute>
              }
            />
            <Route
              path="/DaoDocument"
              element={
                <PrivateRoute>
                  <DaoDocument />
                </PrivateRoute>
              }
            />
            <Route
              path="/DaoSent"
              element={
                <PrivateRoute>
                  <EmailDAOSentScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/ListProjectPromtScreen"
              element={
                <PrivateRoute>
                  <ListProjectPromtScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/AccountCreatedScreen"
              element={
                <PrivateRoute>
                  <AccountCreatedScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/ListTalent"
              element={
                <PrivateRoute>
                  <ListTalent />
                </PrivateRoute>
              }
            />
            <Route
              path="/ListTalent2"
              element={
                <PrivateRoute>
                  <ListTalent2 />
                </PrivateRoute>
              }
            />
            <Route
              path="/CompanyDetails"
              element={
                <PrivateRoute>
                  <CompanyDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/CompanyDetails2"
              element={
                <PrivateRoute>
                  <CompanyDetails2 />
                </PrivateRoute>
              }
            />
            <Route
              path="/ProjectListed"
              element={
                <PrivateRoute>
                  <ProjectListed />
                </PrivateRoute>
              }
            />
            <Route
              path="/ListProject"
              element={
                <PrivateRoute>
                  <ListProject />
                </PrivateRoute>
              }
            />
            <Route
              path="/ListProject2"
              element={
                <PrivateRoute>
                  <ListProject2 />
                </PrivateRoute>
              }
            />
            <Route
              path="/ProjectCompanyDetails"
              element={
                <PrivateRoute>
                  <ProjectCompanyDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/ProjectCompanyDetails2"
              element={
                <PrivateRoute>
                  <ProjectCompanyDetails2 />
                </PrivateRoute>
              }
            />
            <Route
              path="/NotificationScreen"
              element={
                <PrivateRoute>
                  <NotificationScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/ProfileScreen/:tab"
              element={
                <PrivateRoute>
                  <ProfileScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/Voucher"
              element={
                <PrivateRoute>
                  <Voucher />
                </PrivateRoute>
              }
            />
            <Route
              path="/VoucherDeleted"
              element={
                <PrivateRoute>
                  <VoucherDeleted />
                </PrivateRoute>
              }
            />
            <Route
              path="/IssueReported"
              element={
                <PrivateRoute>
                  <IssueReported />
                </PrivateRoute>
              }
            />
            <Route
              path="/VoucherCashback"
              element={
                <PrivateRoute>
                  <VoucherCashback />
                </PrivateRoute>
              }
            />
            <Route
              path="/VoucherHolders"
              element={
                <PrivateRoute>
                  <VoucherHolders />
                </PrivateRoute>
              }
            />
            <Route
              path="/PublishedVoucher"
              element={
                <PrivateRoute>
                  <PublishedVoucher />
                </PrivateRoute>
              }
            />
            <Route
              path="/AllCashbackRequest"
              element={
                <PrivateRoute>
                  <AllCashbackRequest />
                </PrivateRoute>
              }
            />
            <Route
              path="/VoucherActivity"
              element={
                <PrivateRoute>
                  <VoucherActivity />
                </PrivateRoute>
              }
            />
            <Route
              path="/CreateNewVoucher1"
              element={
                <PrivateRoute>
                  <CreateNewVoucher1 />
                </PrivateRoute>
              }
            />
            <Route
              path="/CreateNewVoucher2"
              element={
                <PrivateRoute>
                  <CreateNewVoucher2 />
                </PrivateRoute>
              }
            />
            <Route
              path="/CreateNewVoucher3"
              element={
                <PrivateRoute>
                  <CreateNewVoucher3 />
                </PrivateRoute>
              }
            />
            <Route
              path="/VoucherCreated"
              element={
                <PrivateRoute>
                  <VoucherCreated />
                </PrivateRoute>
              }
            />
            <Route
              path="/Spotlight"
              element={
                <PrivateRoute>
                  <Spotlight />
                </PrivateRoute>
              }
            />
            <Route
              path="/AllSpotlight"
              element={
                <PrivateRoute>
                  <AllSpotlight />
                </PrivateRoute>
              }
            />
            <Route
              path="/ListSpotlightPromtScreen"
              element={
                <PrivateRoute>
                  <ListSpotlightPromtScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/SpotlightDetails"
              element={
                <PrivateRoute>
                  <SpotlightDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/Reward"
              element={
                <PrivateRoute>
                  <Reward />
                </PrivateRoute>
              }
            />
            <Route
              path="/ListReward"
              element={
                <PrivateRoute>
                  <ListReward />
                </PrivateRoute>
              }
            />
            <Route
              path="/RewardListed"
              element={
                <PrivateRoute>
                  <RewardListed />
                </PrivateRoute>
              }
            />
            <Route
              path="/CreateProjectSpotlight"
              element={
                <PrivateRoute>
                  <CreateProjectSpotlight />
                </PrivateRoute>
              }
            />
            <Route
              path="/CreateProjectSpotlight2"
              element={
                <PrivateRoute>
                  <CreateProjectSpotlight2 />
                </PrivateRoute>
              }
            />
            <Route
              path="/CreateTalentSpotlight"
              element={
                <PrivateRoute>
                  <CreateTalentSpotlight />
                </PrivateRoute>
              }
            />
            <Route
              path="/CreateTalentSpotlight2"
              element={
                <PrivateRoute>
                  <CreateTalentSpotlight2 />
                </PrivateRoute>
              }
            />
            <Route
              path="/CreateOwnerSpotlight"
              element={
                <PrivateRoute>
                  <CreateOwnerSpotlight />
                </PrivateRoute>
              }
            />
            <Route
              path="/CreateOwnerSpotlight2"
              element={
                <PrivateRoute>
                  <CreateOwnerSpotlight2 />
                </PrivateRoute>
              }
            />
            <Route
              path="/Support"
              element={
                <PrivateRoute>
                  <Support />
                </PrivateRoute>
              }
            />
            <Route
              path="/SearchScreen"
              element={
                <PrivateRoute>
                  <SearchScreen />
                </PrivateRoute>
              }
            />

            {/* Affiliate ROute set-up */}

            <Route
              path="/Affiliate"
              element={
                <PrivateRoute>
                  <AffiliateScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/CreateAffiliate"
              element={
                <PrivateRoute>
                  <CreateAffiliate />
                </PrivateRoute>
              }
            />

            {/* Payout Routes */}
            <Route
              path="/Payout"
              element={
                <PrivateRoute>
                  <PayoutScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/TransactionHistory"
              element={
                <PrivateRoute>
                  <TransactionHistory />
                </PrivateRoute>
              }
            />
            <Route
              path="/Withdraw"
              element={
                <PrivateRoute>
                  <Withdraw />
                </PrivateRoute>
              }
            />

            <Route
              path="/ProjectDetailsScreen"
              element={
                <PrivateRoute>
                  <ProjectDetailsScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/SettingsScreen"
              element={
                <PrivateRoute>
                  <SettingsContextProvider>
                    <SettingsScreen />
                  </SettingsContextProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="/BulkEmailSuccess"
              element={
                <PrivateRoute>
                  <BulkEmailSuccess />
                </PrivateRoute>
              }
            />
            <Route
              path="/SpotLightAdded"
              element={
                <PrivateRoute>
                  <SpotLightAdded />
                </PrivateRoute>
              }
            />
          </Routes>
          </Router>
        </HelmetProvider>
      </FileUploadProvider>
    </CredentailsContext.Provider>
  );
}

export default App;
