import React, { CSSProperties, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DateInputProps {
  label?: string;
  selectedDate?: Date | null;
  onChange?: (date: Date | null) => void;
  error?: boolean;
  errorText?: string;
  contStyle?: CSSProperties;
  contClass?: string;
  labelStyle?: CSSProperties;
}

export default function DateInput({
  label = "",
  selectedDate,
  onChange,
  error,
  errorText,
  contStyle,
  contClass,
  labelStyle,
}: DateInputProps) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div>
      <div
        className={`input-container ${
          isFocused || selectedDate ? "focused" : ""
        } ${error ? "inputError" : ""} ${contClass}`}
        style={contStyle}
      >
        {label && (
          <label
            style={
              isFocused || selectedDate
                ? {
                    color: "#A5A5A5",
                    position: "absolute",
                    zIndex: 100,
                    paddingLeft: 24,
                    top: "20%",
                    fontSize: 14,
                    transition: "all 0.5s",
                  }
                : {
                    color: "#A5A5A5",
                    position: "absolute",
                    zIndex: 100,
                    paddingLeft: 24,
                    top: "30%",
                    fontSize: 16,
                    transition: "all 0.5s",
                  }
            }
          >
            {label}
          </label>
        )}
        <div className="input-wrapper">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => {
              onChange && onChange(date);
              setIsFocused(!!date);
            }}
            dateFormat="yyyy-MM-dd"
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            className="floating-input custom-date-picker"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(!!selectedDate)}
          />
        </div>
      </div>
      {error && <p className="error-text">{errorText}</p>}
    </div>
  );
}
