import React, { useState, useRef, useEffect } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useLocation, useNavigate } from "react-router-dom";
import { GetUserDetails } from "../../RequestHandlers/User";
import chevBack from "../../assets/ion_chevron-back.svg";
import { getComId, storeCompliancePath } from "../../utils/Localstorage";

function DaoDocument() {
  const navigate = useNavigate();
   const location = useLocation();
  const [showDelMenu, setShowDelMenu] = useState(null);
  const [vName, setVName] = useState("");
  const [description, setDescription] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [details, setdetails] = useState<any>({ contactEmail: "" });
  const [DAO, setDAO] = useState<any>({});

  function capitalizeFirstLetter(word: string): string {
    if (!word) return ""; // Handle empty or null strings
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  const getDao = () => {
    
     const myHeaders = new Headers();
     myHeaders.append("Content-Type", "application/json");
 
     const raw = JSON.stringify({
       id:getComId(),
      //  id:data.id,
     });
 
     fetch("https://trendx.app/api/getDAObyid.php", {
       method: "POST",
       headers: myHeaders,
       body: raw,
       redirect: "follow",
     })
       .then((response) => response.json())
       .then((result) => {
         console.log(result);
         if (result.status == "success") {
           setDAO(result.data);
         }
       })
       .catch((error) => console.error(error));
   };

  const getUserDetails = async () => {
    try {
      const res = await GetUserDetails();
      console.log(res);
      
      if (res.avatar) {
        setdetails(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const DetailRow = ({ label, value, isButton = "",  list = [], bt=false }: any) => (
    // <div className="flex justify-between border-b border-gray-700 py-4">
    <div className={`flex justify-between border-b border-gray-700 py-4 ${bt && "border-t"}`}>
      <span className="text-[#bbb]">{label}</span>
      {isButton !=="" ? (
        <div className={`flex w-[40vw] ${value !== undefined && "gap-4"} `}>
        <span className="text-white text-sm">{value}</span>
        <button className="bg-white text-black px-9 py-1 ">
          {isButton}
        </button>
        </div>
      ) : list.length > 0 ? (
        <div>
          {list.map((item: any, index: number) => (
            <div
              key={index}
              className={`flex flex-col w-[40vw] ${
                index !== list.length - 1 ? "mb-5" : ""
              }`}
            >
              <span className="text-[#555] text-xs ">{item.key}</span>
              <span className="text-white text-sm">{item.value}</span>
            </div>
          ))}
        </div>
      ) : (
        <div className={` w-[40vw]`}>

        <span className="text-white text-sm">{value}</span>
        </div>
      )}
    </div>
  );

  useEffect(() => {
    getDao();
    getUserDetails();
    storeCompliancePath("DaoDocument")
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="dash-body">
        <SideNav activeTab="Compliance" />
        <div className="content-body">
          <ProfileNav />
          <div className="section-ph">
            <div className="ph-text-sec">
            <div
          className="c-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={chevBack} alt="" />
          <p>Back</p>
        </div>
            </div>
            <div className="relative">
              {/* <div
                className="lnp-btn"
                onClick={() => {
                  setShowPopup(!showPopup);
                }}
              >
                Manage DAO
              </div> */}
            </div>
          </div>

          <div className="bg-black text-white min-h-screen p-8">
            <div className="flex items-center mb-8">
              <img
                src={DAO.upload_id}
                alt="logo"
                className="w-10 h-10 mr-2 rounded-[100%]"
              />
              <h1 className="text-xl font-bold">
               {DAO.preferred_name_1} <span className="text-yellow-500">DAO</span>
              </h1>
            </div>

              {/* <h2 className="text-lg font-semibold mb-2">Details</h2>
              <p className="text-[#bbb] mb-6">
                Personal and company details.
              </p> */}
              <div className="bg-black min-h-screen flex flex-col items-start pt-12">
      <div className="w-[600px]">
        <h2 className="text-white text-2xl font-semibold">Documents</h2>
        <p className="text-gray-400 text-sm mt-2">
          Important documents and files related to your business.
        </p>

        {/* Document Card */}
        <div className="bg-[#1c1c1c] mt-6 p-6 rounded-md w-full shadow-lg">
          <h3 className="text-white text-lg font-medium">Certificate of Incorporation</h3>

          <div className="mt-4 text-sm">
            <div className="text-gray-400">Company</div>
            <div className="text-white">Black Ribbon DAO LLC.</div>
          </div>

          <div className="mt-4 text-sm">
            <div className="text-gray-400">Date</div>
            <div className="text-white">17 Dec, 2024</div>
          </div>

          <div className="mt-4 text-sm">
            <div className="text-gray-400">Description</div>
            <p className="text-white">
              Filed and approved by the Secretary of State, the Certificate of Incorporation legally creates the corporation as a registered entity in Delaware.
            </p>
          </div>

          {/* File Download Section */}
          <div className="mt-4 text-sm">
            <div className="text-gray-400">Description</div>
            <div className="mt-2 flex items-center justify-between bg-[#292929] p-3 rounded">
              <div className="flex items-center gap-2 text-white">
                {/* <Download className="text-gray-400 w-4 h-4" /> */}
                <span>Certificate of Incorporation.pdf</span>
              </div>
              <button className="text-[#A7FC00] text-sm font-medium">Download</button>
            </div>
          </div>
        </div>
      </div>
    </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DaoDocument;
