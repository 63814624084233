import { FormikProps } from "formik";
import SecBtn from "../../../Components/SecBtn";
import CompanyAchievements from "../../../Components/settings/self-listed/company-achievements.component";
import CompanyContact from "../../../Components/settings/self-listed/company-contact.component";
import KeyStakeHolders from "../../../Components/settings/self-listed/key-stakholders.component";
import Partnerships from "../../../Components/settings/self-listed/partnerships.company";
import SupportingDocuments from "../../../Components/settings/self-listed/supporting-documents.component";
import ArrowLeft from "../../../assets/arrow-left.svg"
import { SettingsFormValues } from "./SelfListed";
interface Props<T> {
    setStep: any;
    loading?: boolean;
    formik: FormikProps<T>;
}
const Step2: React.FC<Props<SettingsFormValues>> = ({ setStep, formik, loading }) => {
    return (
        <>
            {/* <div className="settings-form-container" > */}
            <CompanyAchievements formik={formik} />
            <Partnerships formik={formik} />
            <SupportingDocuments formik={formik} />
            <KeyStakeHolders formik={formik} />
            <CompanyContact formik={formik} />
            <div className="action-btn-container" >
                <div className="prev-next-view" >
                    <SecBtn

                        onPress={() => {
                            setStep(1)
                        }}
                        icon={<img style={{
                            width: 20,
                            height: 20,
                            // left:8
                        }} src={ArrowLeft} />}
                        text={"Previous"}
                        style={{
                            background: "#FFFFFF",
                            color: "#000",
                            width: "114px",
                            padding: " 8px 14px",
                            borderRadius: "12px",
                            fontSize: "14px",
                            fontWeight: "500",
                            gap: 8,
                            outline: "none",
                            display: "flex",
                            flexDirection: "row-reverse",
                            height: 36
                        }}
                    // onPress={() => setFormData(initialFormData)}
                    />
                </div>


                <div className="save-view">
                    <SecBtn
                        text="Cancel"
                        onPress={() => {
                            formik.resetForm()
                        }}
                        style={{
                            background: "transparent",
                            color: "#FFFFFF",
                            width: "72px",
                            padding: " 8px 14px",
                            borderRadius: "12px",
                            border: "1px solid #4A4A4A",
                            fontSize: "14px",
                            fontWeight: "500",
                            outline: "none",
                            height: 42
                        }}
                    // onPress={() => setFormData(initialFormData)}
                    />
                    <SecBtn
                        type="submit"
                        style={{
                            color: " #030500",
                            width: "115px",
                            padding: "8px 0px",

                            borderRadius: " 12px",
                            fontSize: "14px",
                            fontWeight: "500",
                            backgroundColor: "#A7FC00",
                            // opacity: isDisabled ? 0.6 : 1,
                            height: 42
                        }}
                        loading={loading}
                        onPress={() => formik.handleSubmit()}
                        // disabled={isDisabled}
                        text="Save Changes"
                    />
                </div>
            </div>
            {/* </div> */}
        </>
    );
}

export default Step2;