import "../../css/settings/style.css"
const SettingsTabHeader = ({ activeOption, setActiveOption }: any) => {
    const options = [
        {
            name: "Company Information",
            value: "company-info"
        },
        {
            name: "Bulk email",
            value: "bulk-email"
        },
    ]
    return (
        <>
            <div className="settings-tab-header-view" >
                <div className="settings-tab-header-container" >
                    {options.map(({ name, value }) => (
                        <div onClick={() => {
                            setActiveOption(value)
                        }} style={{
                            backgroundColor: activeOption === value ? "#FFFFFF" : "transparent"
                        }} key={value} className="settings-tab-option" >
                            <p style={{
                                color: activeOption === value ? "#030500" : "#A5A5A5"
                            }} >{name}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default SettingsTabHeader;