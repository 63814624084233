import Input from "../../../Components/Input";
import CompanyPr from "../../../Components/settings/self-listed/company-profile.component";
import CompanyPortfolio from "../../../Components/settings/self-listed/company-portfolio.component";
import TextArea from "../../../Components/Textera";
import "../../../css/settings/style.css";
import ArrowRight from "../../../assets/b-arrow-right.svg";
import SecBtn from "../../../Components/SecBtn";
import { GetUserDetails } from "../../../RequestHandlers/User";
import { FormikProps, useFormik } from "formik";
import { useEffect, useState } from "react";
import { GetCompanyProfile } from "../../../RequestHandlers/Company";
import { SettingsFormValues } from "./SelfListed";
interface Props<T> {
  setStep: any;
  loading?: boolean;
  formik: FormikProps<T>;
}
const Step1: React.FC<Props<SettingsFormValues>> = ({
  setStep,
  formik,
  loading,
}) => {
  const values = formik.values;
  const setFieldValue = formik.setFieldValue;
  const error = formik.errors;
  const handleSubmit = formik.handleSubmit;
  const disableButton = !formik?.dirty;
  return (
    <>
      <div className="settings-form-container">
        <div className="settings-form-row">
          <div className="settings-input">
            <Input
              label="CEO Name"
              contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
              contClass="list-input-cont"
              extraClass="list-input"
              value={values.CEO}
              onChange={({ target }) => setFieldValue("CEO", target.value)}
              error={error.CEO}
              errorText={error.CEO}
            />
          </div>
          <div className="settings-input">
            <Input
              label="CEO’s  Linkedin URL "
              contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
              contClass="list-input-cont"
              extraClass="list-input"
              value={values.ceoLinkedin}
              onChange={({ target }) =>
                setFieldValue("ceoLinkedin", target.value)
              }
              error={error.ceoLinkedin}
              errorText={error.ceoLinkedin}
            />
          </div>
        </div>

        <div
          style={{
            width: "100%",
            marginBottom: 16,
          }}
        >
          <TextArea
            height={72}
            extraClass="textarea textarea1"
            label="About Company"
            value={values.description}
            onChange={({ target }) =>
              setFieldValue("description", target.value)
            }
            error={error.description}
            errorText={error.description}
          />
        </div>
        <div className="settings-form-row">
          <div className="settings-input">
            <Input
              label="Company Website URL"
              contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
              contClass="list-input-cont"
              extraClass="list-input"
              value={values.website}
              onChange={({ target }) => setFieldValue("website", target.value)}
              error={error.website}
              errorText={error.website}
            />
          </div>
          <div className="settings-input">
            <Input
              label="Office Location"
              contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
              contClass="list-input-cont"
              extraClass="list-input"
              value={values.officeLocation}
              onChange={({ target }) =>
                setFieldValue("officeLocation", target.value)
              }
              error={error.officeLocation}
              errorText={error.officeLocation}
            />
          </div>
        </div>
        <div className="settings-form-row">
          <div className="settings-input">
            <Input
              label="Company Size"
              contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
              contClass="list-input-cont"
              extraClass="list-input"
              value={values.companySize}
              onChange={({ target }) =>
                setFieldValue("companySize", target.value)
              }
              error={error.companySize}
              errorText={error.companySize}
            />
          </div>
          <div className="settings-input">
            <Input
              label="Market Presence"
              contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
              contClass="list-input-cont"
              extraClass="list-input"
              value={values.marketPresence}
              onChange={({ target }) =>
                setFieldValue("marketPresence", target.value)
              }
              error={error.marketPresence}
              errorText={error.marketPresence}
            />
          </div>
        </div>
      </div>

      <CompanyPr formik={formik} />
      <CompanyPortfolio formik={formik} />

      <div className="action-btn-container">
        <div className="prev-next-view">
          <SecBtn
            onPress={() => {
              setStep(2);
            }}
            icon={
              <img
                style={{
                  width: 14,
                  height: 14,
                  // left:8
                }}
                src={ArrowRight}
              />
            }
            text={"Next"}
            style={{
              background: "#FFFFFF",
              color: "#000",
              width: "88px",
              padding: " 8px 14px",
              borderRadius: "12px",
              fontSize: "14px",
              fontWeight: "500",
              gap: 8,
              outline: "none",
              height: 36,
            }}
            // onPress={() => setFormData(initialFormData)}
          />
        </div>
        <div className="save-view">
          <SecBtn
            onPress={() => {
              formik.resetForm();
            }}
            text="Cancel"
            style={{
              background: "transparent",
              color: "#FFFFFF",
              width: "72px",
              padding: " 8px 14px",
              borderRadius: "12px",
              border: "1px solid #4A4A4A",
              fontSize: "14px",
              fontWeight: "500",
              outline: "none",
              height: 42,
            }}
            // onPress={() => setFormData(initialFormData)}
          />
          <SecBtn
            // disabled={disableButton}
            type={"submit"}
            onPress={handleSubmit}
            style={{
              color: " #030500",
              width: "115px",
              padding: "8px 0px",
              opacity: disableButton ? 0.5 : 1,
              borderRadius: " 12px",
              fontSize: "14px",
              fontWeight: "500",
              backgroundColor: "#A7FC00",
              // opacity: isDisabled ? 0.6 : 1,
              height: 42,
            }}
            loading={loading}
            // onPress={(e) => handleSubmit(e)}
            // disabled={isDisabled}
            text="Save Changes"
          />
        </div>
      </div>
    </>
  );
};

export default Step1;
