import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async"; 
import NavBar from "../Components/NavBar";
import FadeInAnimation from "../Components/FadeIn";
import checkmark from "../assets/checkmark.svg";
import "../css/responsive.css";
import { useMediaQuery } from "react-responsive";
import HeaderBar from "../Components/HeaderBar";
import { useNavigate } from "react-router-dom";

export default function WhatIsTokenizationScreen() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 567 });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const mapData = [
    {
      title: "Tip 1",
      text: "Tokenization is the process of converting a real-world asset into digital tokens or units.",
      delay: 2.5,
    },
    {
      title: "Tip 2",
      text: "These assets could be properties, infrastructure, art, movies, commodities, talents or even a music album.",
      delay: 3,
    },
    {
      title: "Tip 3",
      text: "To tokenize an asset, digital tokens representing ownership are created on a blockchain to ensure security, transparency, and immutability.",
      delay: 3.5,
    },
    {
      title: "Tip 4",
      text: "Tokenization helps businesses by making it easier to buy into parts of their assets. This means more people can invest, which results in more funds for the business.",
      delay: 4,
    },
    {
      title: "Tip 5",
      text: "With tokenization all transactions are recorded, no one can alter or delete transactions and everyone can trust the information.",
      delay: 4.5,
    },
    {
      title: "Tip 6",
      text: "Tokenization opens doors to a global pool of investors, allowing businesses to raise funds from anyone, anywhere in the world.",
      delay: 5,
    },
  ];

  return (
    <div className="tkbody">
      <Helmet>
        <title>What is Tokenization? | TrendX</title>
        <meta
          name="description"
          content="Learn how tokenization works and how it converts real-world assets into digital tokens for security and transparency."
        />
        <meta
          name="keywords"
          content="Tokenization, Blockchain, Digital Assets, Investing, Security"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://trendx.app/whatIsTokenization" />

        
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Article",
            "headline": "What is Tokenization?",
            "description": "Learn how tokenization works and how it converts real-world assets into digital tokens for security and transparency.",
            "author": {
              "@type": "Organization",
              "name": "TrendX"
            },
            "publisher": {
              "@type": "Organization",
              "name": "TrendX",
              "logo": {
                "@type": "ImageObject",
                "url": "https://trendx.app/logo.png"
              }
            },
            "url": "https://trendx.app/whatIsTokenization",
            "datePublished": "2024-02-10",
            "dateModified": "2024-02-10"
          }
          `}
        </script>
      </Helmet>

      {/*  Navigation Bar */}
      {isMobile ? (
        <HeaderBar onclick={() => navigate(-1)} />
      ) : (
        <NavBar currentActive="What is Tokenization" />
      )}

      {/*  Page Content */}
      <FadeInAnimation delay={1.5} duration={1}>
        <h4 className="bannerText bt">What is Tokenization?</h4>
        {isMobile && (
          <p
            className="text-[14px]"
            style={{ color: "#B3B4B3", textAlign: "center", marginTop: 20 }}
          >
            Take the first step towards tokenization by contacting our
            tokenization team to discuss your project and see if it qualifies
            for our platform.
          </p>
        )}
      </FadeInAnimation>

      {/*  Tips List */}
      <div className="list-item-cont">
        {mapData.map((item, index) => (
          <FadeInAnimation key={index} delay={item.delay} duration={1}>
            <div className="list-card">
              <img src={checkmark} alt="Checkmark" />
              <h4>{item.title}</h4>
              <p>{item.text}</p>
            </div>
          </FadeInAnimation>
        ))}
      </div>
    </div>
  );
}
