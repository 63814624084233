import React, { useState } from "react";
import mClose from "../../assets/close.svg";
import {
  DeleteVoucherByID,
  DeleteVouchers,
  UpdateVoucherStatus,
} from "../../RequestHandlers/Voucher";
import Toast, { handleToast } from "../../Components/Toast";
import { useNavigate } from "react-router-dom";

interface PProps {
  onClose?: any;
  cancel?: any;
  onDelete?: any;
  code: string;
  id: string;
}
function UnpublishVoucher({ onClose, cancel, onDelete, code, id }: PProps) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const deleteVoucher = async () => {
    try {
      setLoading(true);
      const res = await UpdateVoucherStatus({
        id: id,
        status: "unpublished",
      });
      if (res.active) {
        navigate("/VoucherDeleted", {
          state: { code: code, type: "unPublished" },
        });
      } else {
        handleToast(res.message, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="del-promt">
      <Toast />
      <div className="del-promt-nav">
        <p>Unpublish voucher</p>
        <button style={{ all: "unset", cursor: "pointer" }} onClick={onClose}>
          <img src={mClose} alt="" />
        </button>
      </div>
      <p className="del-des">
        Are you sure you want to unpublish voucher {code}?
      </p>
      <div className="dd-btns del-btns">
        <button onClick={cancel}>Cancel</button>
        <button
          onClick={() => {
            deleteVoucher();
          }}
          disabled={loading}
          style={
            loading
              ? {
                  width: 124,
                  alignItems: "center",
                  height: 42,
                  padding: 0,
                  display: "flex",
                  justifyContent: "center",
                }
              : {}
          }
        >
          {loading ? (
            <div className="dot-loader">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          ) : (
            "Unpublish"
          )}
        </button>
      </div>
    </div>
  );
}

export default UnpublishVoucher;
