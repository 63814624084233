import "../../../css/settings/style.css";
import Input from "../../Input";
import trashIcon from "../../../assets/trash.svg";
import plusIcon from "../../../assets/plus.svg";
import { FormikProps } from "formik";
import {
  Portfolio,
  SettingsFormValues,
} from "../../../Screens/Settings/self-listed/SelfListed";
import { useState } from "react";

interface Props<T> {
  formik: FormikProps<T>;
}

const CompanyPortfolio: React.FC<Props<SettingsFormValues>> = ({ formik }) => {
  // Initially, portfolio data will come from formik.values.portifolio
  const portfolio = formik.values.portifolio;

  // Function to add an empty portfolio item
  const addPortfolio = () => {
    formik.setFieldValue("portifolio", [
      ...portfolio,
      { id: Date.now(), name: "", link: "" },
    ]);
  };

  // Function to remove a portfolio item
  const removePortfolio = (index: number) => {
    const updatedPortfolio = portfolio.filter((_, i) => i !== index);
    formik.setFieldValue("portifolio", updatedPortfolio);
  };

  return (
    <>
      <div className="settings-form-container">
        <div className="title-container">
          <p>Company portfolio (past work)</p>
        </div>

        {portfolio.map((item, index) => (
          <div key={item.id} style={{ width: "100%", marginBottom: "16px" }}>
            <div style={{ width: "100%", marginBottom: "16px" }}>
              <Input
                label="Title"
                contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                contClass="list-input-cont"
                extraClass="list-input"
                value={item.name}
                onChange={(e) => {
                  const updatedPortfolio = [...portfolio];
                  updatedPortfolio[index].name = e.target.value;
                  formik.setFieldValue("portifolio", updatedPortfolio); // Update Formik state
                }}
              />
            </div>
            <div style={{ width: "100%", marginBottom: "16px" }}>
              <Input
                label="Enter Document Link"
                contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                contClass="list-input-cont"
                extraClass="list-input"
                value={item.link}
                onChange={(e) => {
                  const updatedPortfolio = [...portfolio];
                  updatedPortfolio[index].link = e.target.value;
                  formik.setFieldValue("portifolio", updatedPortfolio); // Update Formik state
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 14,
                marginTop: 26,
                padding: "0px 36px",
              }}
            >
              <img
                style={{
                  width: 16,
                  height: 16,
                }}
                src={trashIcon}
                alt="delete"
                onClick={() => removePortfolio(index)}
              />
              <p onClick={() => removePortfolio(index)} className="delete-text">
                Delete
              </p>
            </div>
          </div>
        ))}

        <div className="add-company-portfolio-container">
          <img onClick={addPortfolio} src={plusIcon} alt="add" />
          <p onClick={addPortfolio}>Add company portfolio</p>
        </div>
      </div>
    </>
  );
};

export default CompanyPortfolio;
