import React, { useState, useEffect, useRef } from "react";
import SideNav from "../../../Components/SideNav";
import ProfileNav from "../../../Components/ProfileNav";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressRing from "../../../Components/ProgressRing";
import chevBack from "../../../assets/ion_chevron-back.svg";
import ImageVideoUploader from "../../../Components/ImageVideoUploader";
import plus from "../../../assets/plus.svg";
import Input from "../../../Components/Input";
import { useFileUpload } from "../../../context/uploadFile";
import ActivityIndicator from "../../../Components/ActivityIndicator";
import uploadedImg from "../../../assets/uploadedImg.svg";
import gallary from "../../../assets/gallery.svg";
import trash from "../../../assets/trash.svg";
import roundedCheck from "../../../assets/rc.svg";
import uploadImg from "../../../assets/upload.svg";
import { GetIndustries } from "../../../RequestHandlers/Auth";
import chevDown from "../../../assets/chev-down.svg";
import NumberMonthDropdown from "../../../Components/NumberMonthDropdown";
import colon from "../../../assets/colon.svg";
import Toast, { handleToast } from "../../../Components/Toast";
import {
  GetProjects,
  GetProjectsToSpotlight,
} from "../../../RequestHandlers/Collection";
import {
  AddSpotlightGallry,
  Login,
} from "../../../RequestHandlers/AllStbrequest";
import { RequestHandler } from "../../../RequestHandlers/StbRequest";
import { SpotlightProject } from "../../../RequestHandlers/Spotlight";

interface Row {
  months: string;
  roi: string;
}
function CreateProjectSpotlight() {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location.state.type || "";
  const imgFileInputRef = useRef<HTMLInputElement[]>([]);
  const videoFileInputRef = useRef<HTMLInputElement[]>([]);
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>(null);
  const fileInputRef = useRef(null);
  const [errors, setErrors] = useState<any>({});
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [fr, setFr] = useState<any>([]);
  const { uploadFile, isUploading, uploadError } = useFileUpload();
  const [isFocused, setIsFocused] = useState(false);
  const [aboutTalent, setAboutTalent] = useState("");
  const [tName, setTName] = useState("");
  const [activeIndustry, setActiveIndustry] = useState<any>(null);
  const [dropActive, setDropActive] = useState(false);
  const [industries, setIndustries] = useState<any>(null);
  const [project, setProjects] = useState([]);
  const [title, setTitle] = useState("");
  const [isUploading1, setIsUploading1] = useState<boolean[]>([]);
  const [progress, setProgress] = useState<number[]>([]);
  const [isCompleted, setIsCompleted] = useState<boolean[]>([]);
  const [isVUploading1, setIsVUploading1] = useState<boolean[]>([]);
  const [vProgress, setVProgress] = useState<number[]>([]);
  const [isVCompleted, setIsVCompleted] = useState<boolean[]>([]);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [spotlightId, setSpotlightId] = useState("");
  const [image, setImages] = useState([
    { fileName: "", link: "", fileSize: "", completed: false },
  ]);
  const [videos, setVideos] = useState([
    { fileName: "", link: "", fileSize: "", completed: false },
  ]);
  const [rows, setRows] = useState<Row[]>([
    { months: "", roi: "" },
    { months: "", roi: "" },
    { months: "", roi: "" },
    { months: "", roi: "" },
  ]);
  const numberofmonths = [
    { name: "3 Month", value: 3 },
    { name: "6 Months", value: 6 },
    { name: "9 Months", value: 9 },
    { name: "12 Months", value: 12 },
    { name: "15 Months", value: 15 },
  ];
  const handleAddMoreImage = () => {
    setImages([
      ...image,
      { fileName: "", link: "", fileSize: "", completed: false },
    ]);
  };
  const handleAddMoreVideo = () => {
    setVideos([
      ...videos,
      { fileName: "", link: "", fileSize: "", completed: false },
    ]);
  };
  const updateStateAtIndex = (
    setState: React.Dispatch<React.SetStateAction<any[]>>,
    index: number,
    value: any
  ) => {
    setState((prev) => {
      const updated = [...prev];
      updated[index] = value;
      return updated;
    });
  };

  // Initialize refs
  useEffect(() => {
    imgFileInputRef.current = image.map(
      (_, i) =>
        imgFileInputRef.current[i] || React.createRef<HTMLInputElement>()
    );
    videoFileInputRef.current = image.map(
      (_, i) =>
        videoFileInputRef.current[i] || React.createRef<HTMLInputElement>()
    );
  }, []);

  const handleFieldChange = (field: string, value: string) => {
    setErrors((prevErrors: any) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[field]) {
        delete updatedErrors[field];
      }
      return updatedErrors;
    });
  };
  const handleUploadClick = () => {
    // @ts-ignore
    fileInputRef.current.click();
  };
  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const file = e.target.files[0];
        console.log(file);

        const data = await uploadFile(file);
        setUploadedFileUrl(data.secure_url);
        if (data.secure_url) {
          console.log("Uploaded file:", data.asset_id);
          setSelectedFile(data);
          handleFieldChange("coverImage", e.target.value);
        } else {
          setSelectedFile(null);
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };
  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto";
    const scrollHeight = textarea.scrollHeight;
    textarea.style.height = `${Math.min(scrollHeight, 130)}px`;
  };
  const getIndustries = async () => {
    try {
      const res = await GetIndustries();
      setIndustries(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getIndustries();
    window.scrollTo(0, 0);
  }, []);
  const handleImageSelect = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileSizeInKB = (file.size / 1024).toFixed(2);
      setImages((prev) =>
        prev.map((item, idx) =>
          idx === index
            ? {
                ...item,
                fileName: file.name,
                fileSize: `${fileSizeInKB} KB`,
              }
            : item
        )
      );
      updateStateAtIndex(setIsUploading1, index, true);
      let timer1 = 0;
      updateStateAtIndex(setProgress, index, 0);
      const intervalId = setInterval(() => {
        if (timer1 === 90) {
          clearInterval(intervalId);
        } else {
          timer1 += 10;
          updateStateAtIndex(setProgress, index, timer1);
        }
      }, 200);
      try {
        const data = await uploadFile(file);
        console.log("Uploaded file:", data);
        if (data.secure_url) {
          updateStateAtIndex(setProgress, index, 100);
          updateStateAtIndex(setIsCompleted, index, true);
          setImages((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    link: data.secure_url,
                    completed: true,
                  }
                : item
            )
          );
          handleFieldChange(`image${index}`, data.secure_url);
        } else {
          console.error("File upload failed: No secure_url received");
          setImages((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    fileName: "",
                    link: "",
                    fileSize: "",
                    completed: false,
                  }
                : item
            )
          );
        }
      } catch (error) {
        console.error("Upload failed:", error);
      } finally {
        updateStateAtIndex(setIsUploading1, index, false);
      }
    }
  };
  const handleVideoSelect = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      console.log(file);
      const fileSizeInKB = (file.size / 1024).toFixed(2);
      setVideos((prev) =>
        prev.map((item, idx) =>
          idx === index
            ? {
                ...item,
                fileName: file.name,
                fileSize: `${fileSizeInKB} KB`,
              }
            : item
        )
      );
      updateStateAtIndex(setIsVUploading1, index, true);
      let timer1 = 0;
      updateStateAtIndex(setVProgress, index, 0);
      const intervalId = setInterval(() => {
        if (timer1 === 90) {
          clearInterval(intervalId);
        } else {
          timer1 += 10;
          updateStateAtIndex(setVProgress, index, timer1);
        }
      }, 600);
      try {
        const data = await uploadFile(file);
        setUploadedFileUrl(data.secure_url);
        console.log("Uploaded file:", data);
        if (data.secure_url) {
          updateStateAtIndex(setVProgress, index, 100);
          updateStateAtIndex(setIsVCompleted, index, true);
          setVideos((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    link: data.secure_url,
                    completed: true,
                  }
                : item
            )
          );
          handleFieldChange(`video${index}`, data.secure_url);
        } else {
          console.error("File upload failed: No secure_url received");
          setVideos((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    fileName: "",
                    link: "",
                    fileSize: "",
                    completed: false,
                  }
                : item
            )
          );
        }
      } catch (error) {
      } finally {
        updateStateAtIndex(setIsVUploading1, index, false);
      }
    }
  };
  const handleImageDelete = (index: number, fileName: string) => {
    updateStateAtIndex(setIsCompleted, index, false);
    setImages((prev: any) => {
      const updatedImages = prev.filter((_: any, idx: any) => idx !== index);
      updateStateAtIndex(setIsCompleted, index, false);
      if (updatedImages.length === 0) {
        return [
          {
            fileName: "",
            link: "",
            fileSize: "",
            completed: false,
          },
        ];
      }
      return updatedImages;
    });
  };
  const handleVideoDelete = (index: number, fileName: any) => {
    updateStateAtIndex(setIsVCompleted, index, false);
    setVideos((prev: any) => {
      const updatedImages = prev.filter((_: any, idx: any) => idx !== index);
      updateStateAtIndex(setIsVCompleted, index, false);
      if (updatedImages.length === 0) {
        return [
          {
            fileName: "",
            link: "",
            fileSize: "",
            completed: false,
          },
        ];
      }
      return updatedImages;
    });
  };

  const spotlight = async (id: any, body: object) => {
    setLoading(true);
    try {
      const res = await SpotlightProject(id, body);
      setSpotlightId(res.id);
      if (res.id) {
        navigate("/SpotLightAdded");
        // uploadMedia(res.id);
      } else {
        handleToast(res.message, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const uploadMedia = async (id: any) => {
    const totalItems = image.length + videos.length;
    let uploadedCount = 0;
    let hasError = false;
    try {
      for (const img of image) {
        if (img.link && img.link !== "") {
          const res = await AddSpotlightGallry(
            id,
            { link: img.link, type: "image" },
            token
          );
          console.log("img", res);
          if (res.error) {
            handleToast(res.message, "error");
            hasError = true;
          } else {
            uploadedCount++;
          }
        }
      }
      for (const vid of videos) {
        if (vid.link && vid.link !== "") {
          const res = await AddSpotlightGallry(
            id,
            { link: vid.link, type: "video" },
            token
          );
          console.log("vid", res);
          if (res.error) {
            handleToast(res.message, "error");
            hasError = true;
          } else {
            uploadedCount++;
          }
        }
      }
      if (uploadedCount === totalItems) {
        navigate("/SpotLightAdded");
      } else if (hasError && uploadedCount > 0) {
        handleToast("Some media failed to upload. Please retry.", "error");
      } else {
        handleToast("Upload failed. Please try again.", "error");
      }
    } catch (error) {
      console.log("Error uploading media:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: Record<string, string> = {};
    if (title.trim() === "") {
      newErrors.title = "Field is required";
    }
    if (uploadedFileUrl === null) {
      newErrors.coverImage = "Field is required";
    }
    if (aboutTalent.trim() === "") {
      newErrors.aboutTalent = "Field is required";
    }
    if (activeIndustry === null) {
      newErrors.activeIndustry = "Field is required";
    }
    const hasUploadedFile =
      image.some((item) => item.link) || videos.some((item) => item.link);
    if (!hasUploadedFile) {
      newErrors.uploadedFiles =
        "At least one image or video needs to be uploaded";
    }
    handleToast("Upload at least 1 image or video", "error");
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    const videoLinks = videos
      .map((video: any) => ({
        link: video.link,
        type: "video",
      }))
      .filter((video) => video.link !== "");

    const imageLinks = image
      .map((image: any) => ({
        link: image.link,
        type: "image",
      }))
      .filter((image) => image.link !== "");
    const allLinks = [...videoLinks, ...imageLinks];
    const body = {
      title: title,
      subtTitle: activeIndustry.companyName,
      cover: uploadedFileUrl,
      coverType: "image",
      description: aboutTalent,
      type: type,
      galaries: allLinks,
    };
    console.log("Form submitted successfully:", body);
    spotlight(activeIndustry.id, body);
  };

  useEffect(() => {
    handleToast("trev", "success");
    fetch("https://usestableblocks.com/funding_round/")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setFr(data);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  const getProject = async () => {
    try {
      const res = await GetProjectsToSpotlight(1, 20, "active");
      if (res.items) {
        setProjects(res.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loginStb = async () => {
    try {
      const res = await Login({
        email: "scot@mailinator.com",
        password: "Password123#",
      });
      setToken(res.token);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getProject();
    loginStb();
  }, []);
  return (
    <>
      <Toast />
      <div className="dash-body">
        <SideNav activeTab="Spotlight" />
        <div className="content-body">
          <ProfileNav />
          <div
            className="c-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={chevBack} alt="" />
            <p>Back</p>
          </div>
          <div className="sec-header">
            <p>Create Spotlight Request</p>
          </div>
          <div className="list-content-holder">
            <div className="content-cont">
              <div className="dropdown-holder " style={{ width: "100%" }}>
                <div
                  className={`reg-file-cont drop-down ${
                    activeIndustry !== null ? "focused" : ""
                  } ${errors.activeIndustry ? "img-cont-error" : ""}`}
                  onClick={() => {
                    setDropActive(!dropActive);
                  }}
                >
                  <label className="floating-label">
                    {type === "project" ? "Project" : "Talent"} to spotlight
                  </label>
                  <div className="active-ind">{activeIndustry?.name}</div>
                  <div className="chev">
                    <img src={chevDown} alt="" />
                  </div>
                </div>
                {errors.activeIndustry && (
                  <p className="error-text">{errors.activeIndustry}</p>
                )}
                {dropActive && (
                  <div className="in-drop-down">
                    {project.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={`ind ${
                            item === activeIndustry ? "ind-active" : ""
                          }`}
                          onClick={() => {
                            setActiveIndustry(item);
                            setDropActive(false);
                            handleFieldChange("activeIndustry", item.name);
                          }}
                        >
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div
                className="input-holder"
                style={{ marginTop: 16, width: "100%" }}
              >
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Title"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    handleFieldChange("title", e.target.value);
                  }}
                  error={errors.title}
                  errorText={errors.title}
                />
              </div>

              <div
                className={`img-upload-cont ${
                  selectedFile ? "img-upload-cont1" : ""
                } ${errors.coverImage ? "img-cont-error" : ""}`}
                onClick={() => {
                  handleUploadClick();
                }}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                />
                <div className="upp-nav">
                  <p>Cover Image (related to {type})</p>
                  <>
                    {selectedFile || isUploading ? (
                      <>
                        {isUploading ? (
                          <ActivityIndicator size="24px" color="#a7fc00" />
                        ) : (
                          <img src={roundedCheck} />
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </div>
                {/* {selectedFile || isUploading ? (
                ) : (
                  <></>
                )} */}
                <img src={selectedFile ? uploadedImg : uploadImg} alt="" />
                <p>
                  {" "}
                  {selectedFile
                    ? "Image Uploaded Successfully"
                    : "Upload Business Related Image "}
                </p>
                <span
                  onClick={() => {
                    if (uploadError) {
                    }
                  }}
                >
                  {selectedFile
                    ? `${selectedFile?.original_filename}.${selectedFile?.format}`
                    : uploadError
                    ? "Try again"
                    : "(375*150 recommended)"}
                </span>
                {selectedFile && (
                  <div className="bbb-nav">
                    <div className="nn-wrap">
                      <img src={gallary} alt="" />
                      <p>
                        {selectedFile?.original_filename}.{selectedFile?.format}
                      </p>
                    </div>
                    <img
                      src={trash}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedFile(null);
                      }}
                    />
                  </div>
                )}
              </div>
              <div
                className={`abt-talent-cont ${
                  errors.aboutTalent ? "img-cont-error" : ""
                }`}
              >
                <div className={`abt-label ${isFocused ? "abt-label1" : ""}`}>
                  Descriptions
                </div>
                <textarea
                  ref={textareaRef}
                  className="abt-talent-text"
                  value={aboutTalent}
                  onChange={(e) => {
                    setAboutTalent(e.target.value);
                    adjustHeight(e.target);
                    handleFieldChange("aboutTalent", e.target.value);
                  }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={(e) => setIsFocused(aboutTalent != "" ? true : false)}
                />
              </div>
              {errors.aboutTalent && (
                <p className="error-text">{errors.aboutTalent}</p>
              )}
            </div>

            <div className="content-cont">
              <p className="ov">Gallery</p>
              <div className="gallary-wrap">
                <div className="gal-hold">
                  {image.map((item, index) => {
                    return (
                      <ImageVideoUploader
                        topText="Upload Image"
                        isUploading={isUploading1[index] || false}
                        isCompleted={item.completed}
                        progress={progress[index]}
                        fileInputRef={(el: any) =>
                          (imgFileInputRef.current[index] = el)
                        }
                        accept="image/*"
                        handleFileSelect={(e: any) => {
                          handleImageSelect(index, e);
                        }}
                        del={(e: any) => {
                          e.stopPropagation();
                          handleImageDelete(index, item.fileName);
                        }}
                        type="image"
                        fileName={item.fileName}
                        fileSize={item.fileSize}
                        onContainerClick={() => {
                          imgFileInputRef.current[index].click();
                        }}
                      />
                    );
                  })}
                  <div className="inc-btn-cont">
                    <div
                      className="add-btn"
                      onClick={() => {
                        handleAddMoreImage();
                      }}
                    >
                      <img src={plus} alt="" /> Add Image
                    </div>
                  </div>
                </div>
                <div className="gal-hold">
                  {videos.map((item, index) => {
                    return (
                      <ImageVideoUploader
                        topText="Upload Video"
                        isUploading={isVUploading1[index] || false}
                        isCompleted={item.completed}
                        progress={vProgress[index]}
                        fileInputRef={(el: any) =>
                          (videoFileInputRef.current[index] = el)
                        }
                        type="video"
                        accept="video/*"
                        handleFileSelect={(e: any) => {
                          handleVideoSelect(index, e);
                        }}
                        del={(e: any) => {
                          e.stopPropagation();
                          handleVideoDelete(index, item.fileName);
                        }}
                        fileName={item.fileName}
                        fileSize={item.fileSize}
                        onContainerClick={() => {
                          videoFileInputRef.current[index].click();
                        }}
                      />
                    );
                  })}
                  <div className="inc-btn-cont">
                    <div
                      className="add-btn"
                      onClick={() => {
                        handleAddMoreVideo();
                      }}
                    >
                      <img src={plus} alt="" /> Add Video
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="std-cont">
              <div className="std-btn">Save to draft</div>
              <div
                className={`c-btn ${loading ? "c-disabled" : ""}`}
                onClick={handleFormSubmit}
              >
                {loading ? (
                  <div className="dot-loader">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                  </div>
                ) : (
                  "Send request"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateProjectSpotlight;
