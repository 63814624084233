import Cookies from "js-cookie";
import { RequestHandler } from "./Request";
const request = new RequestHandler()
export function GetCompanyProfile(): Promise<any> {
    return new Promise((resolve, reject) => {
        let token = Cookies.get("webCook");
        if (token) {
            token = token.replace(/^"|"$/g, "");
            request.get("tbs/company/get", token, [
                (data: any) => {
                    resolve(data);
                },
            ]);
        } else {
            console.log("null");
        }
    });
}


// export function UpdateCompanyProfile(body: object): Promise<any> {
//     return new Promise((resolve, reject) => {
//         let token = Cookies.get("webCook");
//         if (token) {
//             token = token.replace(/^"|"$/g, "");
//             request.post(`tbs/company/update`, body, token, [
//                 (data: any) => {
//                     resolve(data);
//                 },
//             ]);
//         }

//     });
// }

export function UpdateCompanyProfile(body: object): Promise<any> {
    return new Promise((resolve, reject) => {
        let token = Cookies.get("webCook");
        if (token) {
            token = token.replace(/^"|"$/g, "");
            request.post(`tbs/company/update`, body, token, [
                (data: any) => {
                    resolve(data);
                },
            ]);
        } else {
            console.log("null");
        }
    });
}
